import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { initialValues } from "./OrderManagementController.web";
import moment from "moment";
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  meta: object;
}

export interface TransactionsListResponseType {
  data: IRazorpayTransactions[];
  meta: Meta;
  errors: any[];
}

export interface IRazorpayTransactions {
  order_id: number;
  full_name: string;
  total_price: number;
  transaction_id: string;
  razorpay_order_id: string;
  payment_status: string;
  created_at: string;
  updated_at: string;
  discount_type: string;
  coupon_code: string;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface Meta {
  page: string;
  total_razorpay_transaction_count: number;
}

export interface InvalidResponseType {
  errors: Array<string>;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  transactionsList: IRazorpayTransactions[];
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  perPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RazorpayTransactionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRazorpayTransactionstListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ...initialValues,
      transactionsList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    await this.getTransactionsList(1, this.state.perPage);
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: TransactionsListResponseType
  ) => {
    if (apiRequestCallId === this.getRazorpayTransactionstListApiCallId) {
      this.handleTransactionsListResponse(responseJson);
    }
  };

  handleTransactionsListResponse = async (
    response: TransactionsListResponseType
  ) => {
    const { meta } = response;
    if (response && response?.errors) {
      if (response?.errors[0]?.token === configJSON.tokenExpiredMessage) {
        this.navigateToLoginScreen();
      }
    } else {
      this.setState({
        transactionsList: response.data,
        pageCount: Math.ceil(
          meta.total_razorpay_transaction_count / this.state.perPage
        ),
        isLoading: false,
      });
    }
  };

  getTransactionsList = async (pageNo: number, per_page: number) => {
    this.setState({ isLoading: true });
    this.getRazorpayTransactionstListApiCallId = apiService({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      token: this.state.userToken,
      endPoint: `${configJSON.getRazorpayTransactionsApiEndpoint}?page=${pageNo}&per_page=${per_page}`,
    });
  };

  onPageChange = (event: any, value: any) => {
    this.setState(
      {
        currentPageCount: Number(value),
      },
      () => this.getTransactionsList(this.state.currentPageCount, this.state.perPage)
    );
  };

  navigateToLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };
changeDateFormatToIST(order_date_and_time: string) {
    const parsedDate = moment(order_date_and_time, "DD MMM, YYYY | HH.mm");
    let newDate = parsedDate.format("YYYY-MM-DD HH:mm");
    const utcTime = moment.utc(newDate, "YYYY-MM-DD HH:mm");
    const istTime = utcTime.utcOffset(5 * 60 + 30);
    return istTime.format("DD MMM, YYYY | HH:mm");
  }
  // Customizable Area End
}

// Customizable Area Start
export function apiService(data: APIPayloadType) {
  let { contentType, method, endPoint, body, type, token } = data;
  const header = {
    "Content-Type": contentType,
    token,
  };
  let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type === "" ? JSON.stringify(body) : body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}
// Customizable Area End
