import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { validationsLoop, apiCall } from "../../user-profile-basic/src/FooterController.web";
import { Color } from "@material-ui/lab/Alert";
import { defaultValues } from "../../user-profile-basic/src/UserProfileController.web";
import { getStorageData } from "../../../framework/src/Utilities";

const phoneRegex = /^(\d\s?){10}$/,
      emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      fullNameRegex = /^[a-zA-Z\s]{3,}$/;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  contactUsWeb: {
    full_phone_number: string;
    address: string;
    email: string;
  };
  contactPhoneNumber: string;
  phoneNumberError: string;
  contactEmail: string;
  emailError: string;
  fullName: string;
  fullNameError: string;
  description: string;
  descriptionError: string;
  isFormChanged: boolean;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getContactusApiCallId: string = "";
  contactUsPostApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      ...defaultValues,
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      contactUsWeb: {
        full_phone_number: "",
        address: "",
        email: ""
      },
      contactPhoneNumber: "",
      phoneNumberError: "",
      contactEmail: "",
      emailError: "",
      fullName: "",
      fullNameError: "",
      description: "",
      descriptionError: "",
      isFormChanged: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start

    const fullName = await getStorageData("profileUsername");
    const contactEmail = await getStorageData("loginEmail");
    const contactPhoneNumber = await getStorageData("profilePhoneNumber");

    this.setState({
      fullName,
      contactEmail,
      contactPhoneNumber: contactPhoneNumber?.slice(2),
    })
    this.getContactUs()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }
        if (apiRequestCallId === this.contactUsPostApiCallId) {
          this.handleSubmitContactUsResponse(responseJson);
        }
        this.handleContactUsWebResponse(apiRequestCallId, responseJson);
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContactUs() {
    const header: Record<string, string> = {
      "Content-Type": configJSON.contactUsApiContentType,
    };

    const requestMessageContactUs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactusApiCallId = requestMessageContactUs.messageId;
    requestMessageContactUs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsWebApiEndPoint
    );

    requestMessageContactUs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageContactUs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessageContactUs.id, requestMessageContactUs);
  }
  handleContactUsWebResponse = (apiRequestCallId: string, responseJson: {
    data: {
      full_phone_number: string;
      address: string;
      email: string;
    }
  }) => {
    if (apiRequestCallId === this.getContactusApiCallId) {
      this.setState({ contactUsWeb: responseJson.data })
    }
  }

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  handleInputChange({ target }: { target: { value: string; name: string } }) {
    let { name, value } = target;
    let values = String(value);
    if (values.startsWith(" ")) {
      return;
    }
    
    this.setState({ isFormChanged: true });
    let error = "";

    switch (name) {
      case "contactPhoneNumber":
        if (/^\d*$/.test(value) && value.length <= 10) {
          const errMsg = value.length === 0 ? configJSON.mobileNumberErrMsg : "";
          const stateValues: unknown = {
            [name]: value,
            [`${name}Error`]: errMsg,
          };
          this.setState(stateValues as Pick<S, keyof S>);
        }
        return;
    
      case "contactEmail":
        this.setState({ contactEmail: value });
        break;
    
      case "fullName":
          if (value.length > 60) {
            error = "Fullname cannot be more than 60 characters";
            this.setState({ fullNameError: error });
            return;
          }
        
          if (!fullNameRegex.test(value)) {
            error = configJSON.fullNameErrorMsg;
            this.setState({ [name]: value, fullNameError: error });
            return;
          }
          this.setState({ [name]: value, fullNameError: "" });
          break;

      case "description":
        if (value.length <= 500) {
          this.setState({ [name]: value, descriptionError: "" });
        } else {
          let error = "Query cannot be more than 500 characters";
          this.setState({ descriptionError: error });
        }
        break;
    
      default:
        break;
    }
  }

  handleSubmitContact() {
    this.setState({ isLoading: true });
    const {
      fullName,
      contactEmail,
      contactPhoneNumber,
      description,
    } = this.state;
    const formValues = {
      fullName,
      contactEmail,
      contactPhoneNumber,
      description,
    };
    if (!this.checkFormValidations(formValues)) {
      const contactDetails = {
        data : {
          name: fullName,
          email: contactEmail,
          phone_number: "91" + contactPhoneNumber,
          description: description,
        }
      }

      this.contactUsPostApiCallId = apiCall({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.contactUsPostApiEndpoint,
        contentType: configJSON.contactUsApiContentType,
        body: contactDetails
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  checkFormValidations(updatedValues: Record<string, unknown>): boolean {
    const validationFields = [
      {
        field: "fullName",
        errorKey: "fullNameError",
        errorMessage: "Full Name is Required",
        regex: fullNameRegex
      },
      {
        field: "contactEmail",
        errorKey: "emailError",
        errorMessage: "Please enter a valid email address",
        regex: emailRegex
      },
      {
        field: "contactPhoneNumber",
        errorKey: "phoneNumberError",
        errorMessage: "Phone number is Required",
        regex: phoneRegex
      },
      {
        field: "description",
        errorKey: "descriptionError",
        errorMessage: "Description is Required"
      },
    ];

    return validationsLoop(validationFields, updatedValues, value =>
      this.setState(value)
    );
  }

  handleSubmitContactUsResponse(response: Record<string, any>) {
    if (response.data) {
      this.setState({        
        phoneNumberError: "",
        emailError: "",
        fullNameError: "",
        description: "",
        descriptionError: "",
        alertType: "success",
        isAlert: true,
        alertMsg: "Customer service form submitted successfully!",
    });
    } else if (response.contact) {
      this.setState({
        alertType: "error",
        isAlert: true,
        alertMsg: response.contact[0],
      });
    }
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  onSocialClick = (link: string) => {
    window.open(link)
  }
  // Customizable Area End
}
