// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../couponcodegenerator/src/VouchercodegeneratorController";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export const configJSON = require("./config");
export interface Props {
  navigation: unknown;
  id: string;
  classes?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

interface Response {
  data: unknown;
  meta: { message: string };
  message?: string;
}

export interface WishlistResponse {
  data: WishlistData[];
  meta: {
    total_course	: number
  }
}

export interface WishlistData {
  id: string;
  type: string;
  attributes: DatumAttributes;
}

export interface DatumAttributes {
  id: number;
  courses: Courses;
  bundle_course: null;
}

export interface Courses {
  data: Data;
}

export interface Data {
  id: string;
  type: string;
  attributes: DataAttributes;
}

export interface DataAttributes {
  course_name: string;
  short_description: string;
  full_description: string;
  account_id: number;
  first_year_price: number;
  second_year_price: number;
  discounted_price: number | null;
  preview_video_url: string;
  created_at: string;
  updated_at: Date;
  status: boolean;
  trending: boolean;
  what_learn: string[];
  course_sub_category: CourseSubCategory;
  course_child_category: CourseChildCategory;
  created_on: string;
  student_count: number;
  course_category: CourseCategory;
  instructor_name: string;
  instructor_details: InstructorDetails;
  language_name: string;
  total_content: number;
  average_rating: AverageRating;
  specfifc_content_duration: null;
  course_thumbnail: string;
  all_course_class_duration: number;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  course_class_count: number;
}

export interface AverageRating {
  id: number;
  course_name: string;
  duration: null;
  year: null;
  created_at: Date;
  updated_at: Date;
  short_description: string;
  current_price: null;
  price: null;
  video_url: null;
  full_description: string;
  multiple_videos: null;
  course_category_id: number;
  valid_for_discount: null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: number;
  discounted_price: number | null;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number;
  language_options_language_id: number;
  Rating: { [key: string]: number };
  Average_rating: number;
  total_rating_count: number;
  total_review_count: number;
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface CourseChildCategory {
  id: number;
  course_category_id: number;
  course_sub_category_id: number;
  name: string;
  total_courses: null;
  total_students: null;
  status: boolean;
  color: string;
  created_at: Date;
  updated_at: Date;
}

export interface CourseSubCategory {
  id: number;
  name: string;
  course_category_id: number;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  total_courses: null;
  total_subjects: null;
}

export interface InstructorDetails {
  name: string;
  details: string;
  qualifications: string;
  leacture_in: string;
  profile_icon: string;
}

interface S {
  // Customizable Area Start
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isLoading: boolean;
  wishlistList: any[];
  listView: "list" | "card";
  page: number;
  perPage: number;
  loadMoreDisabled: boolean;
  CourseValidityDialog: boolean;
  courseValidityDetails: Record<string, any>;
  courseIndex: number;
  courseListname: string;
  selectedValidity: number;
  search: string;
  filteredItems: any;
  wishlistCount:number;
  currentPage:number;
  isSearchItem: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class WishlistController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWishlistApiCallId: string = "";
  removeFromWishlistApiCallId: string = "";
  addToChartApiCallId: string = "";
  removeAllFromWishlistApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.state = {
      page: 1,
      perPage: elementsPerPage,
      alertMsg: "",
      listView: "card",
      alertType: "success",
      isAlert: false,
      isLoading: false,
      userToken: "",
      wishlistList: [],
      loadMoreDisabled: false,
      CourseValidityDialog: false,
      courseValidityDetails: {},
      courseListname: "newLaunchCourses",
      courseIndex: 0,
      selectedValidity: 1,
      search: "",
      filteredItems: [],
      wishlistCount:0,
      currentPage:4,
      isSearchItem: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleUserToken();
    const wishlistCount = await getStorageData('wishlistCount');
    this.setState({wishlistCount:wishlistCount})
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getWishlistApiCallId:
            this.handleGetWishlistResponse(responseJson);
            break;
          case this.removeFromWishlistApiCallId:
            this.handleRemoveWishlistResponse(responseJson);
            break;
          case this.addToChartApiCallId:
            this.handleAddCartResponse(responseJson);
            break;
          case this.removeAllFromWishlistApiCallId: 
           this.handleRemoveAllWishlistResponse(responseJson)
          break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }
 // Customizable Area Start

  handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value ===""){
      this.setState({search: event.target.value,filteredItems:[], isSearchItem: false }
        ,()=>{
        this.getWishlist()
      }
    )
    }else{
      this.setState({ search: event.target.value });
    }
  }

  handleWhishlistSearch = () => {
    const { wishlistList, search } = this.state;
    this.setState({ isSearchItem: true })
    const filteredData = wishlistList.filter((item) => {
      const { attributes } = item;
      const value = attributes.courses.data.attributes;
      
      return value.course_name.toLowerCase().includes(search.toLowerCase().trim());
    });
    this.setState({ filteredItems: filteredData });
  };
 
 // Customizable Area End

  // Customizable Area Start
  async handleUserToken() {
    const token = await getStorageData("loginToken");
    if (token) {
      this.setState({ userToken: token });
      this.getWishlist();
    } else {
      this.navigationLoginScreen();
    }
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleRemoveWishlistResponse(responseJson: { message: string } | undefined) {
    if (responseJson && responseJson.message) {
      updateWishlistCount(false);
      this.setState({
        alertMsg: responseJson.message,
        isAlert: true,
        alertType: "info",
        isLoading: false,
        filteredItems:[],
      });
    } else {
      this.setState({ isLoading: false,filteredItems:[] });
    }
  }
  handleRemoveAllWishlistResponse = async(responseJson: { message: string } | undefined) => {
    if (responseJson && responseJson.message) {
      await removeStorageData("wishlistCount");
      updateWishlistCount(false);
      this.setState({
        alertMsg: responseJson.message,
        isAlert: true,
        alertType: "success",
        isLoading: false,
        filteredItems:[],
        wishlistList: []
      });
    } else {
      this.setState({ isLoading: false,filteredItems:[] });
    }
  }

  handleAddCartResponse(responseJson: { message: string } | undefined) {
    if (responseJson && responseJson.message) {
      this.updateCartCount();
      this.setState({
        alertMsg: responseJson.message,
        isAlert: true,
        alertType: "info",
        CourseValidityDialog: false,
        isLoading: false
      });
    }
  }

  updateCartCount = async () => {
    try {
      let cartCount = await getStorageData("cartCount");

      if (cartCount) {
        cartCount = parseInt(cartCount) + 1;
      } else {
        cartCount = 1;
      }

      await setStorageData("cartCount", cartCount);
      const event = new CustomEvent("cartCountChange", {
        detail: { count: cartCount },
      });
      window.dispatchEvent(event);
    } catch (error) {
      console.error("Error updating cart count:", error);
    }
  };

  handleAddCart(isAdd: boolean, courseId: string, type: string, listName: string,no_of_year: number = 1) {
    this.setState({ isLoading: true });
  
    let clone = [...this.state.wishlistList];
  
    const index = clone.findIndex(
      (e) =>
        e?.attributes?.courses?.data?.id === courseId ||
        e?.attributes?.bundle_course?.data?.id === courseId
    );
  
    let text = "course";
    if (clone[index]?.attributes?.courses?.data) {
      clone[index].attributes.courses.data.attributes.is_cart = true;
      text = "course";
    } else if (clone[index]?.attributes?.bundle_course?.data) {
      clone[index].attributes.bundle_course.data.attributes.is_cart = true;
      text = "bundle_course";
    }
  
    const body = {
      course_id: courseId,
      no_of_year,
      text,
    };
  
    this.setState({
      wishlistList: clone,
    });

    this.addToChartApiCallId = apiCall({
      endPoint: configJSON.addToChartApiEndpoint,
      method: configJSON.exampleAPiMethod,
      token: this.state.userToken,
      body: body,
      contentType: configJSON.validationApiContentType,
    });
  }

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  removeFromWishlist(courseId: string, index: number) {
    this.setState({ isLoading: true });

    const formdata = new FormData();
    formdata.append("course_id", courseId);

    const clone = [...this.state.wishlistList];
    clone.splice(index, 1);

    this.setState({
      wishlistList: clone,
    });

    this.removeFromWishlistApiCallId = apiCall({
      endPoint: configJSON.removeWishlistApiEndpoint,
      method: configJSON.deleteAPiMethod,
      token: this.state.userToken,
      body: formdata,
      type: "formdata",
      contentType: undefined,
    });
  }

  removeAllFromWishlist = async () => {
    const { userToken } = this.state;
      const formdata = new FormData();
      this.removeAllFromWishlistApiCallId = apiCall({
        endPoint: configJSON.removeAllWishlistApiEndpoint,
        method: configJSON.deleteAPiMethod,
        token: userToken,
        body: formdata,
        type: "formdata",
        contentType: undefined,
      }); 
  }

  getWishlist() {
    this.setState({ isLoading: true });
    this.getWishlistApiCallId = apiCall({
      endPoint:
        configJSON.getAllWishlistApiEndpoint +
        `?search=${this.state.search}&page=${this.state.page}&per_page=${this.state.perPage}`,
      method: configJSON.validationApiMethodType,
      token: this.state.userToken,
      contentType: configJSON.validationApiContentType,
    });
  }

  oncloseAlert() {
    this.setState({
      isAlert: false,
    });
  }

  loadMore() {
    this.setState({ currentPage: this.state.currentPage + 4
     }, () => { 
      this.setState({loadMoreDisabled: this.state.wishlistList.length <= this.state.currentPage })
     })
  }

  changeDisplayListing(type: "list" | "card") {
    this.setState({ listView: type });
  }

  handleGetWishlistResponse = async(responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        wishlistList: this.state.page != responseJson.meta.page ? [...responseJson.data,...this.state.wishlistList ] : responseJson.data,
        loadMoreDisabled: responseJson.data.length <= this.state.currentPage,
        isLoading: false,
      });
      await setStorageData("wishlistCount", responseJson.meta.total_course)
      const event = new CustomEvent('wishlistCountChange', { detail: { count: Math.max(responseJson.meta.total_course, 0) } });
      window.dispatchEvent(event);
      if(responseJson.data.length === 0) {
        await removeStorageData("wishlistCount")
        updateWishlistCount(false);
      }
    } else {
      this.setState({ isLoading: false, loadMoreDisabled: true });
    }
  }

navigationShoppingCart = () => {
    const shoppingCartScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationShoppingCartMessage)
    );
    shoppingCartScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(shoppingCartScreenMsg);
  };
  handleOpenCourseValidityPopup = (isAdd: boolean,course: any,list: "newLaunchCourses" | "trendingCourses" | "bundleCourses", index: number) => {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your cart.",
        alertType: "info",
        isAlert: true
      });
      return;
    }
    if (isAdd) {
      this.setState({ alertMsg: "Redirect to cart", isAlert: true, alertType: "info" });
      this.navigationShoppingCart()
      return;
    }
    this.setState({courseListname: list,courseIndex: index, courseValidityDetails: course}, () => {
      this.setState({ CourseValidityDialog: true})
    })
  }
  onClose = () => {
    this.setState({selectedValidity: 1, CourseValidityDialog: false})
  }

  handleSelectValidity = (validity: 1 | 2) => {
    this.setState({ selectedValidity: validity });
  };

  getRatingFlag(value: number){
    if(value > 0){
      return true
    }
    return false
  }

  // Customizable Area End
}

export const updateWishlistCount = async (sum: boolean) => {
  try {
    let wishlistCount = await getStorageData('wishlistCount'), numberExists = !wishlistCount && typeof wishlistCount !== "number";
    let count = 0;

    if (!numberExists) {
      count = sum ? parseInt(wishlistCount) + 1 : parseInt(wishlistCount) - 1;
    } else {
      count = sum ? 1 : 0;
    }

    await setStorageData('wishlistCount', `${Math.max(count, 0)}`);
    const event = new CustomEvent('wishlistCountChange', { detail: { count: Math.max(count, 0) } });
    window.dispatchEvent(event);
  } catch (error) {
    console.error('Error updating wishlist count:', error);
  }
}

// Customizable Area End