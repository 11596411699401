// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
export const configJSON = require("./config");

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token: string;
  type?: string;
}

export interface OverviewResponse {
  total_orders: number;
  total_revenue: number;
  total_account: number;
  total_active_user: number;
  type?: SearchType;
}

export interface UserInfo {
  full_name: string;
}

export interface ChartResponse {
  range: (number | string)[];
  type: string;
  data: ChartData[];
}

export interface ChartData {
  date: string;
  current_record: number;
  last_record: number;
}

export enum SearchType {
  weekly = "weekly",
  monthly = "monthly",
  today = "today",
  custom = "custom"
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: any;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  userInfo?: UserInfo;
  totalOverview?: OverviewResponse;
  overview?: OverviewResponse;
  totalOrders?: ChartResponse;
  totalRevenues?: ChartResponse;
  totalCustomers?: ChartResponse;
  totalActiveUsers?: ChartResponse;
  searchType: SearchType;
  previousSearchType: SearchType;
  startDate: string;
  endDate: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTotalOverviewApiCallId: string = "";
  getOverviewApiCallId: string = "";
  getTotalOrderChartApiCallId: string = "";
  getTotalRevenuesChartApiCallId: string = "";
  getTotalCustomerChartApiCallId: string = "";
  getTotalActiveChartApiCallId: string = "";
  getUserInfoApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      isLoading: false,
      userToken: "",
      searchType: SearchType.weekly,
      previousSearchType: SearchType.weekly,
      userInfo: undefined,
      overview: undefined,
      totalOverview: undefined,
      totalActiveUsers: undefined,
      totalCustomers: undefined,
      totalOrders: undefined,
      totalRevenues: undefined,
      startDate: "",
      endDate: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getInitialData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getOverviewApiCallId:
            this.handleGetOverviewResponse(responseJson);
            break;
          case this.getUserInfoApiCallId:
            this.handleGetUserInfoResponse(responseJson);
            break;
          case this.getTotalOverviewApiCallId:
            this.handleGetTotalOverviewResponse(responseJson);
            break;
          case this.getTotalOrderChartApiCallId:
            this.handleGetTotalOrderChartResponse(responseJson);
            break;
          case this.getTotalRevenuesChartApiCallId:
            this.handleGetTotalRevenuesChartResponse(responseJson);
            break;
          case this.getTotalCustomerChartApiCallId:
            this.handleGetTotalCustomerChartResponse(responseJson);
            break;
          case this.getTotalActiveChartApiCallId:
            this.handleGetTotalActiveChartResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getInitialData() {
    this.handleGetTotalOverview();
  }
  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleGetUserInfo() {
    this.getUserInfoApiCallId = this.apiCall({
      endPoint: configJSON.getUserInfo,
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleGetUserInfoResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState({ userInfo: responseJson.data.attributes as UserInfo });
    }
  }

  handleGetTotalOverview() {
    this.setState({ isLoading: true });
    this.getTotalOverviewApiCallId = this.apiCall({
      endPoint: configJSON.getTotalOverview,
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleDateChange({
    target
  }: {
    target: {
      value: string;
      name: string;
    };
  }) {
    const { value, name } = target;
    const data: unknown = {
      [name]: value,
      ...(name === "startDate" ? { endDate: "" } : {})
    };
    this.setState(data as Pick<S, keyof S>);
  }
  setDateOnBlur() {
    if (this.state.startDate && this.state.endDate && !this.validateDates()) {
      this.handleGetTotalOverview();
    }
  }
  validateDates = () => {
    const { startDate, endDate } = this.state;

    // Convert safely to Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
      return true;
    }
    return false;
  };

  getDateSearchString() {
    const { startDate, endDate, searchType } = this.state;
    const dateSearch = `&start_date=${startDate}&end_date=${endDate}`;
    return searchType === SearchType.custom ? dateSearch : "";
  }

  handleGetOverview() {
    this.setState({ isLoading: true });
    this.getOverviewApiCallId = this.apiCall({
      endPoint:
        configJSON.getOverview +
        this.state.searchType +
        this.getDateSearchString(),
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleGetTotalOrderChart() {
    this.setState({ isLoading: true });
    this.getTotalOrderChartApiCallId = this.apiCall({
      endPoint:
        configJSON.getTotalOrderChart +
        this.state.searchType +
        this.getDateSearchString(),
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleGetTotalRevenuesChart() {
    this.setState({ isLoading: true });
    this.getTotalRevenuesChartApiCallId = this.apiCall({
      endPoint:
        configJSON.getTotalRevenuesChart +
        this.state.searchType +
        this.getDateSearchString(),
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleGetTotalCustomerChart() {
    this.setState({ isLoading: true });
    this.getTotalCustomerChartApiCallId = this.apiCall({
      endPoint:
        configJSON.getTotalCustomerChart +
        this.state.searchType +
        this.getDateSearchString(),
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleGetTotalActiveChart() {
    this.setState({ isLoading: true });
    this.getTotalActiveChartApiCallId = this.apiCall({
      endPoint:
        configJSON.getTotalActiveChart +
        this.state.searchType +
        this.getDateSearchString(),
      method: configJSON.dashboarApiMethodType,
      token: this.state.userToken
    });
  }

  handleGetTotalOverviewResponse(responseJson: OverviewResponse) {
    this.setState({ isLoading: false, totalOverview: responseJson }, () => {
      this.handleGetOverview();
      if (!this.state.userInfo) {
        this.handleGetUserInfo();
      }
    });
  }

  handleGetOverviewResponse(responseJson: OverviewResponse) {
    this.setState({ isLoading: false, overview: responseJson }, () => {
      this.handleGetTotalOrderChart();
    });
  }

  handleGetTotalOrderChartResponse(responseJson: ChartResponse) {
    this.setState({ isLoading: false, totalOrders: responseJson }, () =>
      this.handleGetTotalRevenuesChart()
    );
  }

  handleGetTotalRevenuesChartResponse(responseJson: ChartResponse) {
    if (responseJson.range != undefined) {
      this.setState({ isLoading: false, totalRevenues: responseJson }, () =>
        this.handleGetTotalCustomerChart()
      );
    } else {
      this.handleGetTotalCustomerChart();
    }
  }

  handleGetTotalCustomerChartResponse(responseJson: ChartResponse) {
    this.setState({ isLoading: false, totalCustomers: responseJson }, () =>
      this.handleGetTotalActiveChart()
    );
  }

  handleGetTotalActiveChartResponse(responseJson: ChartResponse) {
    this.setState({ isLoading: false, totalActiveUsers: responseJson });
  }

  handleTypeChange({
    target
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) {
    const { value } = target;
    this.setState(
      {
        previousSearchType: this.state.searchType,
        searchType: value as SearchType,
        startDate: "",
        endDate: ""
      },
      () => value !== SearchType.custom && this.handleGetTotalOverview()
    );
  }

  apiCall(data: APIPayload) {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleOption(option: string) {
    switch (option) {
      case "today":
        return "Today";
      case "weekly":
        return "This Week";
      case "monthly":
        return "This Month";
      default:
        return "Custom Date";
    }
  }
  setListItemColor(option: string) {
    return this.state.searchType === option ? "#000000" : "#94A3B8";
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
