import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Snackbar,
  FormControl
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Alert from "@material-ui/lab/Alert";
import { FaChevronDown } from "react-icons/fa";
import { SketchPicker } from "react-color";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import {
  TwoColBox,
  InputBox,
  StyledSelect,
  ProfilePicInput
} from "../../../components/src/GenericInputs";
import {
  handleEditFileChange,
  handleDeleteFile
} from "./AdminCategoryController.web";
const drawerWidth = 250;
const closeDrawer = 0;
// Customizable Area End

import AdminChildCatgoryController, {
  Props,
  ChildCategoryDataType,
  S
} from "./AdminChildCategoryController.web";

export default class AdminChildCatgory extends AdminChildCatgoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.toggleColorPickerState = this.toggleColorPickerState.bind(this);
    this.setStateValues = this.setStateValues.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  toggleColorPickerState = (state: boolean) => {
    this.toggleColorPicker(state);
  };

  setStateValues(value: unknown) {
    this.setState(value as Pick<S, keyof S>);
  }

  renderSketchPicker = () => {
    return (
      <ClickAwayListener
        data-test-id="colorPickerCloserTestId"
        onClickAway={() => this.toggleColorPickerState(false)}
      >
        <div style={{ position: "relative" }}>
          <Button
            fullWidth
            variant="outlined"
            data-test-id="colorPickerButtonTestId"
            onClick={() =>
              this.toggleColorPickerState(!this.state.isColorPickerOpen)
            }
            color="default"
            style={{
              background: this.state.selectedColor || "#FFFFFF",
              color: getContrastColor(this.state.selectedColor || "#FFFFFF"),
              textTransform: "none"
            }}
          >
            Show Color Picker
          </Button>
          {this.state.isColorPickerOpen && (
            <div
              style={{
                position: "absolute",
                top: 40,
                right: 0,
                left: 0,
                zIndex: 1
              }}
            >
              <SketchPicker
                color={this.state.selectedColor}
                onChange={this.handleColorChange}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  };

  addChildCatNotificationAlert = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        data-test-id="alertTestId"
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.oncloseAlert}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };

  renderSubCategorySelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Sub Category</Typography>
        <FormControl variant="outlined" fullWidth className="box__input">
          <StyledSelect
            id="demo-customized-select"
            disableUnderline
            data-test-id="subCategorySelectTestId"
            IconComponent={FaChevronDown}
            onChange={e => this.handleSubCatSelectChange(e.target.value)}
            value={this.state.courseSubCategoryId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.courseSubCategoryData &&
              this.state.courseSubCategoryData.map(
                (subCategory: ChildCategoryDataType) => {
                  return (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.attributes.name}
                    </option>
                  );
                }
              )}
          </StyledSelect>
          <span style={webStyles.errorText}>
            {this.state.courseSubCategoryError}
          </span>
        </FormControl>
      </Box>
    );
  };

  renderCategorySelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Main Category</Typography>
        <FormControl variant="outlined" fullWidth className="box__input">
          <StyledSelect
            id="demo-customized-select-native"
            disableUnderline
            IconComponent={FaChevronDown}
            data-test-id="categorySelectTestId"
            onChange={e => this.handleCategorySelectChange(e.target.value)}
            value={this.state.courseCategoryId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.courseCategoryData &&
              this.state.courseCategoryData.map(
                (category: ChildCategoryDataType) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.attributes.name}
                    </option>
                  );
                }
              )}
          </StyledSelect>
          <span style={webStyles.errorText}>
            {this.state.courseCategoryError}
          </span>
        </FormControl>
      </Box>
    );
  };

  renderIcon(hasThumbnail: boolean) {
    return hasThumbnail ? (
      <ProfilePicInput
        onChange={e =>
          handleEditFileChange(e, "iconSubCat", this.setStateValues)
        }
        onRemove={() => handleDeleteFile("iconSubCat", this.setStateValues)}
        data-test-id="subCatIconId"
        name="subCatIconImgUpload"
        previewThumbnail={this.state.iconSubCatPreviewThumbnail}
      />
    ) : (
      this.renderSelectIcon()
    );
  }

  renderSelectIcon() {
    return (
      <Box className="file_upload_box">
        <Typography className="app_icon_txt">
          Upload square image icon
        </Typography>
        <input
          data-test-id="subCatIconId"
          multiple
          type="file"
          accept="image/*"
          name="subCatIconImgUpload"
          id="subCatIconInput"
          style={{ display: "none" }}
          onChange={e =>
            handleEditFileChange(e, "iconSubCat", this.setStateValues)
          }
        />
        <label htmlFor="subCatIconInput" className="lable_txt">
          Browse
        </label>
      </Box>
    );
  }

  renderInputChildCatView = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            {this.renderCategorySelect()}
            {this.renderSubCategorySelect()}
          </TwoColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Child Category</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  variant="outlined"
                  fullWidth
                  data-test-id="childCatInputTestId"
                  onChange={this.handleChildCategoryName}
                  value={this.state.childCategoryName}
                />
                <span style={webStyles.errorText}>
                  {this.state.childCategoryErr}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">App Icon</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.renderIcon(!!this.state.iconSubCatPreviewThumbnail)}
                <span style={webStyles.errorText}>
                  {this.state.iconSubCatError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Web Icon</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.state.iconWebPreviewThumbnail ? (
                  <ProfilePicInput
                    onChange={e =>
                      handleEditFileChange(e, "iconWeb", this.setStateValues)
                    }
                    onRemove={() =>
                      handleDeleteFile("iconWeb", this.setStateValues)
                    }
                    data-test-id="webIconId"
                    name="webIconImgUpload"
                    previewThumbnail={this.state.iconWebPreviewThumbnail}
                  />
                ) : (
                  <Box className="file_upload_box">
                    <Typography className="app_icon_txt">
                      Upload square image icon
                    </Typography>
                    <input
                      data-test-id="webIconId"
                      multiple
                      type="file"
                      accept="image/*"
                      name="webIconImgUpload"
                      id="webIconInput"
                      style={{ display: "none" }}
                      onChange={e =>
                        handleEditFileChange(e, "iconWeb", this.setStateValues)
                      }
                    />
                    <label htmlFor="webIconInput" className="lable_txt">
                      Browse
                    </label>
                  </Box>
                )}
                <span style={webStyles.errorText}>
                  {this.state.iconWebError}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box align-center">
              <Typography className="box__label">Status</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <CheckBoxButton className="switch">
                  <input
                    type="checkbox"
                    onChange={this.handleClickCheckBox}
                    checked={this.state.courseChildCategoryStatus}
                  />
                  <span className="slider round" />
                </CheckBoxButton>
              </FormControl>
            </Box>
          </TwoColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">
                Select Custom Color
              </Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.renderSketchPicker()}
                <span style={webStyles.errorText}>
                  {this.state.selectedColorError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <ButtonBox>
            {this.state.isUpdate ? (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.handleChildCategoryNavigation}
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.handleFeildReset}
              >
                Reset
              </Button>
            )}
            {this.state.isUpdate ? (
              <Button
                onClick={this.handleEditChildCategory}
                data-test-id="updateButtonTestId"
                variant="contained"
                className="create_btn"
                disabled={this.state.loader}
              >
                Update
              </Button>
            ) : (
              <Button
                data-test-id="createButtonTestId"
                onClick={this.handlePostChildCategory}
                variant="contained"
                className="create_btn"
                disabled={this.state.loader}
              >
                Create
              </Button>
            )}
          </ButtonBox>
        </Box>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.loader}
        data-test-id="mainLayoutEvent"
        backData={this.handleChildCategoryNavigation}
        title={
          this.state.isUpdate ? "Edit Child Category" : "Add Child Category"
        }
        isEdit
        isAdd
      >
        {this.addChildCatNotificationAlert()}
        <main className="table_content" style={{ background: "white" }}>
          {this.renderInputChildCatView()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CheckBoxButton = styled("label")({
  "& .slider": {
    borderRadius: "20px",
    transition: ".4s",
    position: "absolute",
    top: 0,
    bottom: "-1px",
    cursor: "pointer",
    right: 0,
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    left: 0
  },
  "& input:checked + .slider:before": { left: "-10px" },
  "&.switch": {
    width: "36px",
    position: "relative",
    marginTop: "5px",
    "& input": {
      height: 0,
      width: 0,
      opacity: 0
    },
    height: "19px",
    display: "inline-block"
  },
  "& input:checked + .slider": { backgroundColor: "#708328" },
  "& .slider:before": {
    position: "absolute",
    WebkitTransition: ".4s",
    width: "18px",
    content: '""',
    backgroundColor: "white",
    left: "1px",
    transition: ".4s",
    borderRadius: "50%",
    height: "18px",
    bottom: "1px"
  }
});

const ButtonBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "25px 0",
  alignItems: "center",
  "& .create_btn": {
    backgroundColor: "#3f6474",
    borderRadius: "0",
    padding: "10px 58px",
    textTransform: "capitalize",
    color: "#fff",
    fontSize: "16px",
    boxShadow: "none",
    margin: "0px 10px",
    width: "195px",
    fontFamily: "Inter",
    height: "52px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal"
  },
  "& .reset_btn": {
    backgroundColor: "#fff",
    borderRadius: "0",
    textTransform: "capitalize",
    border: "1px solid #3F6474",
    fontSize: "16px",
    padding: "10px 60px",
    width: "195px",
    color: "#3f6474",
    margin: "0px 10px",
    height: "52px",
    fontFamily: "Inter",
    fontStyle: "normal",
    boxShadow: "none",
    lineHeight: "24px",
    fontWeight: 700
  },
  "@media (max-width:460px)": {
    "& .create_btn": {
      height: "40px",
      padding: "5px 48px"
    },
    "& .reset_btn": {
      height: "40px",
      padding: "5px 50px"
    }
  },
  "@media (max-width:400px)": {
    flexDirection: "column",
    "& .create_btn": { width: "100%" },
    "& .reset_btn": { marginBottom: "10px", width: "100%" }
  }
});

function getContrastColor(hexColor: string) {
  // Convert hex color to RGB
  let rgb = hexColor.replace("#", "");
  let r = parseInt(rgb.substring(0, 2), 16);
  let g = parseInt(rgb.substring(2, 4), 16);
  let b = parseInt(rgb.substring(4, 6), 16);

  // Calculate the brightness of the color
  let brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black for bright colors and white for dark colors
  return brightness >= 128 ? "#000000" : "#ffffff";
}
// Customizable Area End
