import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button,
    Snackbar,
    Modal,
    TextField,
    NativeSelect,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Tooltip,
    IconButton,
    MenuItem,
    Select,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import InfoIcon from '@material-ui/icons/Info';
import { FaChevronDown } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { RxCross2 } from "react-icons/rx";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
import { Wrapper } from "./AddAdminCourses.web";
import CoursesSidebar from "./components/CoursesSidebar.web";
import moment from "moment";
import Chip from '@material-ui/core/Chip';

const drawerWidth = 250;
const closeDrawer = 0;
// Customizable Area End

import AdminCourseClassesController, {
    CourseClassesDataType,
    Props,
    configJSON,
} from "./AdminCourseClassesController.web";
import { webStyles } from "./AdminCourses.web";
import GenericModal from "../../../components/src/GenericModal.web";
import AddCourseClasses from "./AddCourseClasses.web";

export default class AdminCourseClasses extends AdminCourseClassesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    menuItems = [
        { 
          text: "Course Edit",
          path: configJSON.courseEditPath,
          active: false
        },
        { 
          text: "Course Chapter",
          path: configJSON.courseChapterPath,
          active: false 
        },
        { 
          text: "Course Classes",
          path: configJSON.courseClassesPath,
          active: true 
        },
        { 
          text: "Reviews & Ratings",
          path: configJSON.courseRatingsPath, 
          active: false 
        },
      ];
      
    renderTableBodyCourseClasses = (item: CourseClassesDataType, index: any) => {
        return (
            <>
                <tr style={!!item.attributes.vdocipher_video_status || this.state.failedStatus ? { } :{
                     backgroundColor: "#d3d3d3 !important",
                     opacity: "0.6",
                     pointerEvents: "none",
                }}>
                    <td className="tr_desc" style={{ textAlign: "left", width: 50 }}>{item.id}</td>
                    <td style={{ textAlign: "left", width: 250 }}>{item.attributes.course_chapter.name?.length > 26 ? `${item.attributes.course_chapter.name.slice(0, 26)}...` : item.attributes.course_chapter.name || "----"}</td>
                    <td style={{ textAlign: "left" }}>{item.attributes.title?.length > 26 ? `${item.attributes.title.slice(0, 26)}...` : item.attributes.title || "----"}</td>
                    <td style={{ textAlign: "left" }}>{item.attributes.sequence_number}</td>
                    <td style={{ width: 50 }}>
                        {!item.attributes.vdocipher_video_status && this.state.failedStatus ? (
                            <>
                             <Chip style={{ backgroundColor: "#FEE2E2", color: "#DC2626" }} label="Upload Failed"/>
                            </>):(
                            <CourseClassesCheckStatusBtn className="switch">
                            <input type="checkbox" data-test-id={'statusCheckTestId'} onChange={() => this.handleCourseclassStatus(item.id)} checked={item.attributes.status} />
                            <span className="slider round"></span>
                        </CourseClassesCheckStatusBtn>)}
                    </td>
                    <td style={{ width: 50 }}>
                        <Box className="desc_edit_icon_box">
                            { item.attributes.vdocipher_video_status || this.state.failedStatus ? (
                                <>
                                { !item.attributes.vdocipher_video_status ? (
                                        <Button data-test-id={'handleShowCourseClasses'} style={{ minWidth: "auto" }} onClick={() => this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort)}>
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49967 1.56619V2.83411C11.6305 2.83411 14.1663 5.36994 14.1663 8.50077C14.1663 9.23744 14.0247 9.94577 13.7626 10.5904C13.5713 11.0649 12.9622 11.1924 12.6009 10.8312C12.4097 10.6399 12.3318 10.3495 12.438 10.0945C12.6434 9.60577 12.7497 9.06036 12.7497 8.50077C12.7497 6.15619 10.8443 4.25077 8.49967 4.25077V5.51869C8.49967 5.83744 8.11717 5.99327 7.89051 5.77369L5.91426 3.79744C5.77259 3.65577 5.77259 3.43619 5.91426 3.29452L7.89759 1.31827C8.11717 1.09161 8.49967 1.24744 8.49967 1.56619ZM4.24967 8.50077C4.24967 10.8454 6.15509 12.7508 8.49967 12.7508V11.4829C8.49967 11.1641 8.88217 11.0083 9.10176 11.2279L11.078 13.2041C11.2197 13.3458 11.2197 13.5654 11.078 13.707L9.10176 15.6833C8.88217 15.9099 8.49967 15.7541 8.49967 15.4354V14.1674C5.36884 14.1674 2.83301 11.6316 2.83301 8.50077C2.83301 7.76411 2.97467 7.05577 3.23676 6.41119C3.42801 5.93661 4.03717 5.80911 4.39842 6.17036C4.58967 6.36161 4.66759 6.65202 4.56134 6.90702C4.35592 7.39577 4.24967 7.94119 4.24967 8.50077Z" fill="#0F172A" />
                                            </svg>
                                        </Button>
                                ): (
                                            <PiPen
                                                data-test-id={'editIconTestId'}
                                                className="desc_edit_icon"
                                                color={"black"}
                                                size={20}
                                                onClick={() => this.handleShowCourseClasses(item.id)}
                                            />
                                )}
                            <FaRegTrashAlt
                                data-test-id={'deleteIconeTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={17}
                                onClick={() => this.handleOpenDeleteCourseClassesModal(Number(item.id))}
                            />
                           </>
                            ) : (
                                <this.CircularProgressWithLabel value={this.state.progress[item.id]} />
                            )}
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={courseClassesDrawerStyle.drawerTableDivider} />
            </>
        )
    }

    renderTableCourseClasses = () => {
        const isCurrentSort = (name: string) =>
            this.state.currentSortState.field === name &&
            this.state.currentSortState.order === "asc";
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>  <Box className="th_box" style={{justifyContent: "flex-start"}}>
                                <Typography className="title_th">S.No</Typography>
                                <IoIosArrowDown
                                    className={`filter_icon ${isCurrentSort("id") ? "active" : ""}`}
                                    onClick={() => this.handleSort("id")}
                                    data-test-id="sort_id"
                                    style={{
                                        transform: isCurrentSort("id") ? "none" : "rotate(180deg)"
                                    }}
                                />
                            </Box></th>
                            {[
                                "Course Chapters",
                                "Title",
                                "Sequence Number",
                            ].map((header, index) => {
                                let renderIcon = false;
                                if (["Course Chapters", "Sequence Number"].includes(header)) {
                                    renderIcon = true;
                                }
                                return renderTableRecord(
                                    () => isCurrentSort(header),
                                    header,
                                    renderIcon,
                                    index,
                                    () => this.handleSort(header),
                                    "sort_name",
                                    "left"
                                );
                            })
                            }
                          
                            <th>
                                <Box className="th_box" style={{justifyContent: "center"}}>
                                    <Typography className="title_th">Status</Typography>
                                    <IoIosArrowDown
                                        className={`filter_icon ${isCurrentSort("status") ? "active" : ""}`}
                                        onClick={() => this.handleSort("status")}
                                        data-test-id="sort_status"
                                        style={{
                                            transform: isCurrentSort("status") ? "none" : "rotate(180deg)"
                                        }}
                                    />
                                </Box>
                            </th>
                            <th>
                                <Box className="th_box" style={{justifyContent: "center"}}>
                                    <Typography className="title_th">Action</Typography>
                                </Box>
                            </th>
                        </tr>
                    </thead>
                    <span style={courseClassesDrawerStyle.drawerDividerLine}></span>
                    <TableBody>
                        {this.state.adminCourseClassesData.data.length > 0 && this.state.adminCourseClassesData.data.map((item: CourseClassesDataType, index: any) => this.renderTableBodyCourseClasses(item, index))}
                    </TableBody>
                </TableStyle>
                {this.state.adminCourseClassesData.data.length > 0 ? 
                <CourseClassesPaginationBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.pageCount}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.currentPageCount}
                        siblingCount={0}
                        onChange={this.onCourseClassesPageChange}
                    />
                </CourseClassesPaginationBox> :
                 <Box style={{height:'100vh', justifyContent: 'center', alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
                    <Typography style={{ color: 'black' }}>{configJSON.recordNotFound}</Typography>
                </Box>}
            </Box>
        )
    }

    notificationAlertMessageCourseClasses = () => {
        return (
            <Snackbar
                autoHideDuration={3000}
                open={this.state.isAlert}
                onClose={this.onCloseAlert}
                anchorOrigin={{ horizontal: 'center', vertical: "top" }}
            >
                <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
            </Snackbar>
        )
    }

    renderDeleteInstituteModal = () => {
        return (
            <>
                <CourseClassesDeleteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="InstituteDeleteModalId"
                    open={this.state.openDeleteCourseClassesModal}
                    onClose={this.handleCloseDeleteCourseClassesModal}
                >
                    <CourseClassesModalView>
                        <CourseClassesDeleteText component={"p"}>
                            Do you want to delete this course class?
                        </CourseClassesDeleteText>
                        <CourseClassesModalButton>
                            <CourseClassesDeleteButton data-test-id="deleteBtnTestId"
                                onClick={() => this.handleDeleteCourseClasses(this.state.deleteCourseClassesId)}
                            >Delete</CourseClassesDeleteButton>
                            <CourseClassesCancelButton data-test-id="cancelBtnTestId"
                                onClick={() => this.handleCloseDeleteCourseClassesModal()}
                            >Cancel</CourseClassesCancelButton>
                        </CourseClassesModalButton>
                    </CourseClassesModalView>
                </CourseClassesDeleteModal>
            </>
        )
    };

    renderSequenceNumberSelect = () => {
        return (
          <CourseClassesFieldControlBox>
            <Typography className="input_txt">Sequence Number</Typography>
            <div
              style={{
                display: "flex",
                width: "calc(100% - 160px)",
                flexWrap: "wrap",
              }}
            >
              <SelectBox
                id="sequenceSelect"
                data-test-id="sequenceSelect"
                disableUnderline
                IconComponent={FaChevronDown}
                onChange={(e) => this.handleSequenceSelectChange(e.target.value)}
                value={this.state.SequenceNumber}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                      horizontal: "left",
                      vertical: "top",
                  },
                  style: {
                      width: "450px",
                      height: "350px",
                      top: "8px",
                  },
                  keepMounted: true,
                  getContentAnchorEl: null,
                }}
              >
                {this.state.sequenceNumberArray.map((_, index) => {
                  const number = index + 1;
                  return (
                    <MenuItem
                        value={number}
                        key={number}
                      style={{ 
                          display: "flex", 
                          flexFlow: "wrap", 
                          width: "490px", 
                    }}
                    >
                      <Typography style={{ 
                        whiteSpace: "pre-line" 
                        }}>
                        {number}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </SelectBox>
                    <span style={webStyles.errorText}>
                        {this.state.sequenceNumberError}
                    </span>
            </div>
          </CourseClassesFieldControlBox>
        );
      };

    renderAddCourseClassesModal = () => {
        const { selectedOption, videoUrl, videoFileName } = this.state;
        const videoFileText = !videoFileName
            ? "Upload video"
            : videoFileName;
        return (
            <>
                <AddCourseClassesModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="AddInstituteModalId"
                    open={this.state.openAddCourseClassesModal}
                    onClose={this.handleCloseCourseClassModal}
                >
                    <AddCourseClassesModalView>
                        {this.notificationAlertMessageCourseClasses()}
                        <AddCourseClassesModalBox>
                            <AddCourseClassesHeadingText >{this.state.isUpdate ? configJSON.editCourseClasses : configJSON.addCourseClasses}</AddCourseClassesHeadingText>
                            <RxCross2 onClick={() => this.handleCloseCourseClassModal()} style={{ height: "25px", width: "25px", marginRight:'20px' }} />
                        </AddCourseClassesModalBox>
                        <AddCourseClassesModalInnerView>
                        <CourseClassesFieldBox>
                            {this.renderChapterNameSelect()}
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Title</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseChapterInputBox
                                        variant="outlined" fullWidth
                                        data-test-id="titleInputTestId"
                                        onChange={this.handleTitle}
                                        value={this.state.title}
                                    />
                                    <span style={webStyles.errorText}>
                                        {this.state.titleError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">{configJSON.details}</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseClassDetailsInputBox
                                        variant="outlined" fullWidth
                                        minRows={3}
                                        maxRows={3}
                                        multiline
                                        data-test-id="detailsInputTestId"
                                        onChange={this.handleDetail}
                                        value={this.state.detail}
                                    />
                                    <span style={webStyles.errorText}>
                                        {this.state.detailError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            {this.renderSequenceNumberSelect()}
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            {this.renderRadioButton()}
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt"></Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {selectedOption === 'url' && (
                                        <CourseChapterInputBox
                                            type="text"
                                            variant="outlined"
                                            value={videoUrl}
                                            onChange={this.handleUrlChange}
                                            fullWidth
                                        />
                                    )}
                                    {selectedOption === "upload_video" && (
                                        <>
                                            <Box className="file_upload_box">
                                                <Typography className="app_icon_txt">
                                                    {configJSON.uplaodVideoText}
                                                </Typography>
                                                <input
                                                    data-test-id="uploadLearningMaterialId"
                                                    type="file"
                                                    accept="video/*"
                                                    name="learningMaterialFileUpload"
                                                    id="learningMaterialInput"
                                                    style={{ display: "none" }}
                                                    disabled={this.state.fileIsUploading}
                                                    onChange={this.handleFileChange}
                                                    multiple={this.state.isUpdate ? false : true}
                                                />
                                                <video
                                                    ref={this.state.videoRef}
                                                    style={{ display: "none" }}
                                                />
                                                <canvas
                                                    ref={this.state.canvasRef}
                                                    style={{ display: "none" }}
                                                />
                                                <label
                                                    htmlFor="learningMaterialInput"
                                                    className="lable_txt"
                                                >
                                                    {configJSON.browserText}
                                                </label>
                                            </Box>
                                            {this.state.fileIsUploading ? (
                                                <CircularProgress size={32} style={{ margin: "20px auto" }} color="inherit" />
                                            ) : (
                                            this.state.videoFileThumbnail  && ( 
                                                    this.state.videoFileThumbnail.map(((item:string, index: number) => (
                                                        <Box style={{ margin: "10px 0", display: "flex", gap: 10, alignItems: "center", width: "100%" }}>
                                                        <div style={{ height: "100%" }}>
                                                            <img
                                                                src={item}
                                                                alt="thumbnail preview"
                                                                width={60}
                                                                height={33.75}
                                                                style={{ borderRadius: 5, aspectRatio: "16/9", objectFit: "contain" }}
                                                            />
                                                        </div>
                                                        <div style={{ height: "100%" }}>
                                                            <strong style={{ margin: 0, fontSize: "0.75rem" }}>{this.state.videoFileName[index]}</strong>
                                                            <p style={{ color: "#969696", margin: "3px 0", fontSize: "0.75rem" }}>{this.state.videoFileSize}</p>
                                                        </div>
                                                        <div style={{ marginLeft: "auto" }}>
                                                            <Button type="button" variant="text" color="secondary" data-test-id="fileRemoveTestId" onClick={() => this.handleRemoveFile(index)}>REMOVE</Button>
                                                        </div>
                                                    </Box>
                                                )
                                            ))))}
                                        </>
                                    )}
                                    <span style={webStyles.errorText}>
                                        {this.state.videoUrlFileError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Duration (Minutes)</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseChapterInputBox
                                        variant="outlined" fullWidth
                                        data-test-id="durationsInputTestId"
                                        onChange={this.handleDuration}
                                        value={this.state.duration}
                                    />
                                    <span style={webStyles.errorText}>
                                        {this.state.durationError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Status</Typography>
                                <CourseChapterheckBodButton className="switch">
                                    <input type="checkbox"
                                        data-test-id="checkboxId"
                                        onChange={this.handleCourseClassStatusCheck} checked={this.state.courseClassStatus}
                                    />
                                    <span className="slider round"></span>
                                </CourseChapterheckBodButton>
                            </CourseClassesFieldControlBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Featured</Typography>
                                <CourseChapterheckBodButton className="switch">
                                    <input type="checkbox"
                                        data-test-id="checkboxId"
                                        onChange={this.handleFeaturedCheck} checked={this.state.featured}
                                    />
                                    <span className="slider round"></span>
                                </CourseChapterheckBodButton>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseChapterButtonBox>
                            {this.state.isUpdate ?
                                <Button variant="contained" className="reset_btn"
                                    onClick={() => this.handleCloseCourseClassModal()}
                                >
                                    Cancel
                                </Button> :
                                <Button variant="contained" className="reset_btn"
                                    onClick={this.handleFeildReset}
                                >
                                    Reset
                                </Button>}
                            {this.state.isUpdate ?
                                <Button
                                    data-test-id="updateButtonTestId"
                                    onClick={this.handleEditCourseClass}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    {this.state.loader ? <CircularProgress size={20} color="inherit" /> : "Update"}
                                </Button> :
                                <Button
                                    data-test-id="addClassesButtonTestId"
                                    onClick={this.handlePostCourseClasses}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    {this.state.loader ? <CircularProgress size={20} color="inherit" />:  "Add Classes"}
                                </Button>}
                        </CourseChapterButtonBox>
                        </AddCourseClassesModalInnerView>
                    </AddCourseClassesModalView>
                </AddCourseClassesModal>
            </>
        )
    }

    renderChapterNameSelect = () => {
        return (
            <CourseClassesFieldControlBox>
                <Typography className="input_txt">Chapter Name</Typography>
                <div
                    style={{
                        display: "flex",
                        width: "calc(100% - 160px)",
                        flexWrap: "wrap",
                    }}
                >
                    <SelectBox
                        id="demo-customized-select-native"
                        disableUnderline
                        IconComponent={FaChevronDown}
                        data-test-id="courseChapterSelectTestId"
                        onChange={(event) => this.handleCourseChapterSelectChange(event.target.value)}
                        value={this.state.courseChapterId}
                        MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                            },
                            style: {
                                width: "450px",
                                height: "350px",
                                top: "8px",
                            },
                            keepMounted: true,
                            getContentAnchorEl: null,
                          }}
                          onScroll={this.handleScroll} 
                    >
                        <option aria-label="None" value="" />
                        {this.state.courseChapterListData.map((course: {
                            attributes: { name: string }; id: string,
                        }) => {
                            return (
                                <MenuItem
                                value={course.attributes.name}
                                key={course.id}
                              style={{ 
                                  display: "flex", 
                                  flexFlow: "wrap", 
                                  width: "490px", 
                            }}
                            >
                              <Typography style={{ 
                                whiteSpace: "pre-line" 
                                }}>
                                {course.attributes.name}
                              </Typography>
                            </MenuItem>
                            );
                        })}
                    </SelectBox>
                    <span style={webStyles.errorText}>
                        {this.state.courseChapterIdError}
                    </span>
                </div>
            </CourseClassesFieldControlBox>
        )
    }

    renderRadioButton = () => {
        const { selectedOption } = this.state;
        return (
            <CourseClassesFieldControlBox>
                <Typography className="input_txt">Type</Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        data-test-id="videoOption"
                        aria-label="videoOption"
                        name="videoOption"
                        value={selectedOption}
                        onChange={this.handleChange}
                    >
                        <FormControlLabel value="url" control={<Radio />} label="URL" />
                        <FormControlLabel value="upload_video" control={<Radio />} label="Upload Video" />
                        {this.state.isUpdate && (
                            <Tooltip title={configJSON.editMultiVideoUploadMsg} arrow>
                                <IconButton>
                                    <InfoIcon style={{ fontSize: "1rem" }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </RadioGroup>
                    <span style={webStyles.errorText}>
                        {this.state.selectedOptionError}
                    </span>
                </FormControl>
            </CourseClassesFieldControlBox>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainLayout
                data-test-id="mainLayoutEvent"
                handleSearch={(e: any) => this.handleCourseClassesSearch(e)}
                isLoading={this.state.loader}
                title={this.state.editCourseName}
                actionButtons={[
                {
                    icon: <AddCircleOutlineIcon />,
                    iconType: "jsxElement",
                    onClick: () => this.handleOpenAddClassesDialog(),
                    text: "Create Course Classes",
                    testId: "addCourseClassButtonId"
                }
                ]}
                isStudent={false}
            >
                {this.notificationAlertMessageCourseClasses()}
                {this.renderDeleteInstituteModal()}
                {this.renderAddCourseClassesModal()}
                <Wrapper>
                    <CoursesSidebar data-test-id="coursesSidebar" menuItems={this.menuItems} onItemClick={this.handleSidebarItemClick} />
                    <main className="table_content">
                        {this.renderTableCourseClasses()}
                    </main>
                </Wrapper>
                <button data-test-id="setFailedStates" onClick={this.setFailedStates} style={{ display: "none" }} />
                <GenericModal open={this.state.openAddClassesDialog} dataTest="addClasses" data-test-id="addClasses" onClose={this.handleCloseAddClassesDialog} dialogTitle={this.state.isUpdate ? "Edit Course Classes" : "Add Course Classes"} customStyles={{modal: { width: "651px"}}}>
                    <AddCourseClasses chaptersList={this.state.courseChapterListData} onScrollHandle={this.handleScroll} data-test-id="addCourseClasses"  onClose={this.handleCloseDialogAfterAddClasses} assignedSequenceNumbers={this.state.selectedSequenceNumberArray} isUpdate={this.state.isUpdate} editCourseClassesDetails={this.state.editCourseClassesDetails} />
                </GenericModal>
            </MainLayout>  
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const courseClassesDrawerStyle = {
    drawerTableDivider: { height: "8px" },
    hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
    showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
    input: {
        height: "20px",
        width: "20px",
        display: "flex",
        border: "3px solid black",
        background: "none",
        alignItems: "center",
        justifyContent: "center",
    },
    drawerDividerLine: {
        height: "20px",
        display: "inline-block",
    },
};

const CourseClassesPaginationBox = styled(Box)({
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#206575",
        color: "#fff",
    },
    "& .viewall_txt": {
        color: "#206575",
        fontSize: "14px",
        cursor: "pointer",
    }
});

const CourseClassesCheckStatusBtn = styled("label")({
    "& .slider": {
        position: "absolute",
        right: 0,
        cursor: "pointer",
        left: 0,
        top: 0,
        bottom: "-1px",
        WebkitTransition: ".4s",
        backgroundColor: "#ccc",
        transition: ".4s",
        borderRadius: "20px"
    },
    "&.switch": {
        display: "inline-block",
        position: "relative",
        width: "36px",
        marginTop: "5px",
        height: "19px",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        content: '""',
        position: "absolute",
        height: "18px",
        left: "1px",
        width: "18px",
        bottom: "1px",
        WebkitTransition: ".4s",
        backgroundColor: "white",
        borderRadius: "50%",
        transition: ".4s"
    },
    "& input:checked + .slider:before": { left: "-10px" }
});

const CourseClassesModalView = styled(Box)({
    height: "150px",
    width: "400px",
    borderRadius: '5px',
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
});

const CourseClassesDeleteText = styled(Box)({
    width: "100%",
    height: "37px",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: 'center'
});

const CourseClassesCancelButton = styled("button")({
    width: "145px",
    height: "40px",
    color: "#206575",
    fontSize: "16px",
    border: "1px solid #206575",
    backgroundColor: "white"
});

const CourseClassesDeleteButton = styled("button")({
    width: "145px",
    border: "0",
    height: "40px",
    color: "white",
    backgroundColor: "#FD7101",
    fontSize: "16px",
    fontWeight: 500
});

const CourseClassesModalButton = styled(Box)({
    display: "flex",
    gap: "20px"
});

const CourseClassesDeleteModal = styled(Modal)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
});

const AddCourseClassesModal = styled(Modal)({
    height: "100%",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex"
});

const AddCourseClassesModalView = styled(Box)({
    height: "580px",
    borderRadius: '18px',
    width: "700px",
    backgroundColor: '#ffffff',
    outline: "none",
    flexDirection: "column",
    margin: "20px",
    display: "flex",
    boxShadow: "0px 0px 3px px transparent",
});

const AddCourseClassesModalInnerView = styled(Box)({
    overflowY: "auto",
    display: "flex",
    padding: "0 30px",
    flexDirection: "column",
  });

export const CourseClassesFieldControlBox = styled(Box)({
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "16px 0",
    display: "flex",
    "& .input_txt": {
        width: "160px",
        fontStyle: "normal",
        fontFamily: "Inter",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 400,
        color: "#192252",
        lineHeight: "24px",
        padding: "0px"
    },
    "& .file_upload_box": {
        borderColor: "#D4D4D4",
        color: "#fff",
        height: "44px",
        justifyContent: "space-between",
        borderRadius: "4px",
        border: "1px solid black",
        display: "flex",
        width: "490px",
        alignItems: "center",
        "& .lable_txt": {
            alignItems: "center",
            display: "flex",
            backgroundColor: "#3F6474",
            justifyContent: "center",
            fontSize: "12px",
            marginRight: "2px",
            borderRadius: "4px",
            height: "90%",
            padding: "0px 30px",
        },
        "& .app_icon_txt": {
            wordSpacing: "-2px",
            marginLeft: "10px",
            fontSize: "13px",
            color: "#A9A9A9",
        },
    },
    "@media (max-width:875px)": {
        alignItems: "start",
        flexDirection: "column",
        "& .input_txt": {
            marginBottom: "8px",
            width: "100%",
        }
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#3f6474"
    },
    "& .MuiFormGroup-root": {
        flexDirection: "row"
    }
});

export const CourseChapterInputBox = styled(TextField)({
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
    "& .MuiOutlinedInput-input": { padding: "0px 14px" },
    "& .MuiInputBase-input": { height: "44px" },
});

export const CourseClassesFieldBox = styled(Box)({
    "@media (max-width:1330px)": {
        alignItems: "start",
        flexDirection: "column",
    }
});

const CourseChapterheckBodButton = styled("label")({
    "& .slider": {
        bottom: "-1px",
        position: "absolute",
        top: 0,
        borderRadius: "20px",
        backgroundColor: "#ccc",
        transition: ".4s",
        cursor: "pointer",
        right: 0,
        left: 0,
        WebkitTransition: ".4s",
    },
    "& input:checked + .slider:before": { left: "-10px" },
    "&.switch": {
        position: "relative",
        width: "36px",
        marginTop: "5px",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
        display: "inline-block",
        height: "19px",
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        height: "18px",
        WebkitTransition: ".4s",
        content: '""',
        position: "absolute",
        bottom: "1px",
        left: "1px",
        backgroundColor: "white",
        borderRadius: "50%",
        transition: ".4s",
        width: "18px",
    },
});

const CourseChapterButtonBox = styled(Box)({
    display: "flex",
    width: "100%",
    margin: "25px 0",
    justifyContent: "center",
    alignItems: "center",
    "& .create_btn": {
        color: "#fff",
        fontSize: "14px",
        backgroundColor: "#3f6474",
        borderRadius: "0",
        boxShadow: "none",
        margin: "0px 10px",
        fontWeight: 700,
        fontFamily: "Inter",
        textTransform: "capitalize",
        fontStyle: "normal",
        lineHeight: "22px",
        height: "40px",
        width: "179px",
    },
    "& .reset_btn": {
        border: "1px solid #3F6474",
        width: "108px",
        padding: "10px 60px",
        fontSize: "14px",
        color: "#3f6474",
        backgroundColor: "#fff",
        borderRadius: "0",
        textTransform: "capitalize",
        fontFamily: "Inter",
        boxShadow: "none",
        height: "40px",
        margin: "0px 10px",
        lineHeight: "22px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "@media (max-width:460px)": {
        "& .create_btn": {
            padding: '5px 48px',
            height: '40px',
        },
        "& .reset_btn": {
            padding: '5px 50px',
            height: '40px',
        },
    },
    "@media (max-width:400px)": {
        "& .create_btn": { width: '100%' },
        "& .reset_btn": { marginBottom: '10px', width: '100%' },
        flexDirection: 'column',
    },
});

const AddCourseClassesModalBox = styled(Box)({
    paddingTop: 10,
    justifyContent: "space-between",
    paddingBottom: 10,
    width: "100%",
    display: "flex",
});

const AddCourseClassesHeadingText = styled(Typography)({
    fontSize: "18px",
    fontFamily: "Inter",
    letterSpacing: "-0.12px",
    color: "#000",
    fontStyle: "normal",
    lineHeight: "26px",
    fontWeight: 700,
    padding: "10px 30px 0px 30px",
});

export const SelectBox = styled(Select)({
    "&.MuiInputBase-root": {
        border: "1px solid lightgray",
        width: "490px",
        borderRadius: "4px",
    },
    "& .MuiNativeSelect-icon": {
        top: "calc(50% - 7px) !important",
        right: "6px !important",
    },
    "& [class*=MuiSelect-icon]": {
        top: "calc(50% - 7px)",
        right: "8px"
    },
    "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" },
    "&  .MuiSelect-icon": {
    right: "10px",
  },
});

const CourseClassDetailsInputBox = styled(TextField)({
    "& .MuiOutlinedInput-input": { padding: "0px" },
    width: "490px",
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
    "& .MuiInputBase-input": { height: "86px" },
});
// Customizable Area End