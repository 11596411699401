import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
export const configJSON = require("./config");
import { apiCall } from "../../user-profile-basic/src/FooterController.web";
import { CourseClassesDataType } from "./AdminCourseClassesController.web";

interface CourseClass {
  title: string;
  sequence: string;
  duration: string;
  vdocipher_video_id: string;
  video_upload: File | null;
  video_name?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  chaptersList: {
    attributes: { name: string }; id: string,
  }[];
  assignedSequenceNumbers: string[];
  onScrollHandle: (event: React.UIEvent<HTMLDivElement>) => void;
  onClose: (message?: string) => void;
  isUpdate?: boolean;
  editCourseClassesDetails?: CourseClassesDataType
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  chapter: string;
  classes: CourseClass[];
  videoType: "URL" | "Upload";
  fileName: Record<string, string>;
  errors: Record<string, string>;
  isAlert: boolean;
  alertType: "error" | "warning";
  alertMsg: string;
  isLoading: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class AddCourseClassesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addCourseClassesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];

    let defaultVideoType: "URL" | "Upload" = "URL"
    const updateConditionAttributes = this.props.isUpdate && this.props.editCourseClassesDetails && this.props.editCourseClassesDetails.attributes
    if (updateConditionAttributes) {
      defaultVideoType = updateConditionAttributes.class_type === "url" ? "URL" : "Upload"
    }

    this.state = {
      // Customizable Area Start
      chapter: updateConditionAttributes ? updateConditionAttributes.course_chapter.id.toString() : "",
      classes: [{ 
        title: updateConditionAttributes ? updateConditionAttributes.title : "", 
        sequence: updateConditionAttributes ? updateConditionAttributes.sequence_number.toString() : "", 
        duration: updateConditionAttributes ? this.getDurationFromNumber(updateConditionAttributes.duration) : "", 
        vdocipher_video_id: updateConditionAttributes && defaultVideoType === "URL" ? updateConditionAttributes.vdocipher_video_id : "", 
        video_upload: null,
        video_name: updateConditionAttributes && defaultVideoType === "Upload" ? updateConditionAttributes.video_upload_name : ""
      }],
      videoType: defaultVideoType,
      fileName: {},
      errors: {},
      isAlert: false,
      alertType: "error",
      alertMsg: "",
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else {
        //handle errors
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.addCourseClassesApiCallId) {
      this.handleAddCourseClassesListResponse(responseJson);
    }
  };

  handleAddCourseClassesListResponse = (response: any) => {
    this.setState({ isLoading: false })
    if (response && response.data && (response.data.length > 0 || response.data.id)) {
      if (response.meta && response.meta.warning !== "No duplication") {
        this.props.onClose(response.meta.warning)
      } else {
        this.props.onClose()
      }
    } else if (response && response.error) {
      this.setState({
        isAlert: true,
        alertMsg: response.error,
        alertType: "error",
      })
    }
  };

  handleDurationChange = (index: number, field: keyof CourseClass, value: string) => {
    value = value.replace(/[^0-9:]/g, "");

    const parts = value.split(":");
    if (parts.length > 2) return;

    if (/^\d{1,2}$/.test(value) || /^\d{1,2}:$/.test(value) || /^\d{1,2}:[0-5]?\d?$/.test(value) || value === "") {
      this.handleInputChange(index, field, value);
  }

};

  getDurationFromNumber = (duration: string) => {
    const numberDuration = Number(duration)
    if (numberDuration) {
      let hours = Math.floor(numberDuration / 60);
      let mins = numberDuration % 60;
      return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
    }
    return duration
  }


  // Handle input change
  handleInputChange = (index: number, field: keyof CourseClass, value: string) => {
    if (field === "title" && value.length > 30) {
      return;
    }
    if (field === "sequence" && !(/^\d+$/.test(value) || value === "")) {
      return;
    }
    this.setState((prevState) => {
      const updatedClasses = [...prevState.classes]; // Copy state array
      updatedClasses[index] = { ...updatedClasses[index], [field]: value }; // Update specific field

      // Clear the error for the specific field when the user starts typing
      const updatedErrors = { ...prevState.errors };

      if (field === "title") {
        delete updatedErrors[`title${index}`];
      }
      if (field === "duration") {
        delete updatedErrors[`duration${index}`];
      }
      if (field === "vdocipher_video_id") {
        delete updatedErrors[`videoId${index}`];
      }
      if (field === "sequence") {
        delete updatedErrors[`sequence${index}`];
      }

      return {
        classes: updatedClasses,
        errors: updatedErrors
      };
    });
  };

  // Handle file change (video upload)
  handleFileChange = (index: number, video_upload: File) => {
    this.setState((prevState) => {
      const updatedClasses = [...prevState.classes]; // Copy state array
      updatedClasses[index] = { ...updatedClasses[index], video_upload, video_name: "" }; // Update the file field for the class at the given index

      // Clear the error for the video_upload field when a file is selected
      const updatedErrors = { ...prevState.errors };
      delete updatedErrors[`videoFile${index}`]; // Clear error for URL field

      const updatedfileName = { ...prevState.fileName };
      updatedfileName[`videoFile${index}`] = video_upload.name;

      return {
        classes: updatedClasses,
        errors: updatedErrors,
        fileName: updatedfileName,
      };
    });
  };


  // Handle chapter selection
  handleChapterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };
      // Clear chapter error when the user selects a chapter
      delete updatedErrors["chapter"];

      return {
        chapter: value,
        errors: updatedErrors
      };
    });
  };

  // Add new class
  addClass = () => {
    this.setState((prevState) => ({
      classes: [...prevState.classes, { title: "", sequence: "", duration: "", vdocipher_video_id: "", video_upload: null }],
    }));
  };

  // Remove a class
  removeClass = (index: number) => {
    this.setState((prevState) => {
      const updatedClasses = prevState.classes.filter((_, i) => i !== index);
      return { classes: updatedClasses };
    });
  };

  handleRadioChange = (videoType: "URL" | "Upload") => {
    this.setState({ videoType });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false })
  }

  checkVideoId = (cls: CourseClass) => {
    if (this.state.videoType === "URL" && !cls.vdocipher_video_id.trim()) {
      return "URL can not be empty";
    }
      return "";
  }

  checkVideoFile = (cls: CourseClass) => {
    if (this.state.videoType === "Upload" && !cls.video_upload && !cls.video_name) {
      return "Please upload a video";
    }
      return "";
  }

  // Validate form
  validateForm = () => {
    let errors: Record<string, string> = {};
    const maxSequenceAllowed = this.state.classes.length + this.props.assignedSequenceNumbers.length;

    if (!this.state.chapter) {
      errors["chapter"] = "Chapter is required";
    }

    this.state.classes.forEach((cls, index) => {
      const fieldValidations: [keyof typeof cls, string][] = [
        ["title", "Title can not be empty"],
        ["sequence", "Sequence can not be empty"],
        ["duration", "Duration can not be empty"],
      ];
  
      fieldValidations.forEach(([field, message]) => {
        if (!cls[field]?.toString().trim()) {
          errors[`${field}${index}`] = message;
        }
      });

      const videoIdError = this.checkVideoId(cls)
      if (!!videoIdError) {
        errors[`videoId${index}`] = videoIdError;
      }
      const videoFileError = this.checkVideoFile(cls)
      if (!!videoFileError) {
        errors[`videoFile${index}`] = videoFileError;
      }
    });

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  // Submit form
  handleSubmit = async () => {
    // Validate the form
    if (!this.validateForm()) {
      return; // If validation fails, don't proceed
    }

    this.setState({ isLoading: true })

    const token = await getStorageData('loginToken');
    const formData = new FormData();
    
    this.state.classes.forEach((cls, index) => {
      let courseClassText = `course_classes`
      if (!this.props.isUpdate) {
        courseClassText = `course_classes[${index}]`
      }

      let newDuration = Number(cls.duration.split(":")[1] || 0);
      newDuration = newDuration + Number(cls.duration.split(":")[0]) * 60

      formData.append(`${courseClassText}[course_chapter_id]`, this.state.chapter);
      formData.append(`${courseClassText}[title]`, cls.title);
      formData.append(`${courseClassText}[sequence_number]`, String(cls.sequence));
      formData.append(`${courseClassText}[duration]`, String(newDuration));

      if (this.state.videoType === 'URL') {
        formData.append(`${courseClassText}[class_type]`, "url");
        formData.append(`${courseClassText}[vdocipher_video_id]`, cls.vdocipher_video_id);
      } else if (this.state.videoType === 'Upload' && !cls.video_name) {
        formData.append(`${courseClassText}[class_type]`, "upload_video");
        formData.append("update_video", "true");
        formData.append(`${courseClassText}[video_upload]`, cls.video_upload as Blob);
      }
    });

    this.addCourseClassesApiCallId = apiCall({
      method: this.props.isUpdate ? configJSON.httpPutType : configJSON.httpPostType,
      endPoint: this.props.isUpdate ? `${configJSON.getAllCourseClassesAPIEndPoint}/${this.props.editCourseClassesDetails?.id}` : configJSON.getAllCourseClassesAPIEndPoint,
      body: formData,
      type: "formdata",
      token: token
    });
  };
  // Customizable Area End
}
