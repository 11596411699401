import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  CardContent,
  Chip,
  styled,
  Divider,
} from '@material-ui/core';
import { FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CourseItem } from '../../blocks/catalogue/src/MyCourseController.web';
import { setStorageData } from "../../framework/src/Utilities";

const styles = {
  root: {
    height: 324,
    boxShadow: 'unset',
    borderRadius: 12,
  },
  media: {
    height: 140,
    borderRadius: 12,
  },
  courseName: {
    margin: 0, 
    minHeight: "64px",
    fontWeight: 600, 
    fontFamily:'Inter', 
    fontSize:'22px',
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden",
    wordBreak: "break-word"
  } as const
};

interface CourseCardProps extends WithStyles<typeof styles> {
  item: CourseItem;
  index: number;
}

export class CourseCard extends React.Component<CourseCardProps> {
  getRemainingDays(initialDate: Date, year: number): number {
    const today = new Date();

    const oneYearLater = new Date(initialDate);
    oneYearLater.setFullYear(initialDate.getFullYear() + year);

    const timeDifference = oneYearLater.getTime() - today.getTime();

    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return remainingDays > 0 ? remainingDays : 0;
  }
  getCourseProgress(total_watched_chapter: number, total_course_chapter:number){
    if(total_course_chapter > 0){
    return (total_watched_chapter / total_course_chapter) * 100
    }
    return total_course_chapter
  }

  render() {
    const { classes, item } = this.props;

    return (
      <Card className={classes.root} style={item.is_active_course ? {} : {pointerEvents: "none",
        filter: 'grayscale(100%)',
        opacity: 0.5, 
      }}>
       
          <CardActionArea
            style={{
              position: 'relative',
              borderRadius: 12,
            }}
          >
             <Link
          to={`/MyCoursesDetail/${item.order_id}/${item.course_id}`}
          data-test-id="linkTestId"
          style={{ margin: 0, textDecoration: 'none', color: 'inherit' }}
        >
            <CardMedia
              className={classes.media}
              style={{
                width: '99%',
                height: 'auto',
                maxHeight: '173px',
                minHeight: '173px',
                aspectRatio: '16/9',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
              image={item.course_data.course_thumbnail}
              title="Thumbnail image"
            />
            <FaPlayCircle
              fontSize="3rem"
              color="white"
              style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                top: '86.5px',
                left: '50%',
                boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)'
              }}
            />
            <CustomChipImage
              label={`${this.getRemainingDays(
                new Date(item.place_order_date),
                item.no_of_year
              )} Days`}
            />
            </Link>
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <Link
          to={`/MyCoursesDetail/${item.order_id}/${item.course_id}`}
          data-test-id="linkTestId"
          style={{ margin: 0, textDecoration: 'none', color: 'inherit' }}
        >
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                data-test-id="courseNameTestId"
                className={classes.courseName}
              >
                {item.course_data.course_name}
              </Typography>
              <CustomProgress
                variant="determinate"
                value={this.getCourseProgress(item.total_watched_chapter, item.total_course_chapter)}
              />
              </Link>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  height:'26px'
                }}
              >
              <div style={{flex:1, display:'flex', flexDirection:'row'}}>
                <Typography
                  variant="body2"
                  component="h5"
                  style={{ color: '#FF5900', fontWeight: 'bold' }}
                >
                  {item.total_watched_chapter} of {item.total_course_chapter}{' '}
                  Chapters
                </Typography>
               <div style={{height:'20px', width:'1px', backgroundColor:'#756F8B', marginLeft:'10px',marginRight:'10px', alignSelf:'center'}}></div>
               </div>
                <Typography
                  variant="body2"
                  style={{ display: 'inline-flex', gap: 17, }}
                >
                  {!item.course_watched && !item.is_reviewed_by_user ? (
                     <Link
                     to={`/MyCoursesDetail/${item.order_id}/${item.course_id}`}
                     data-test-id="linkTestId"
                     style={{ margin: 0, textDecoration: 'none', color: 'inherit' }}
                   >
                    <CustomChip label="Start Course" />
                    </Link>
                   ) : (
                    <>
                    {!item.is_reviewed_by_user &&
                    <Link
                    to={`/MyCoursesDetail/${item.order_id}/${item.course_id}`}
                    data-test-id="linkTestId"
                    style={{ margin: 0, textDecoration: 'none', color: 'inherit' }}
                  >
                    <span style={{ color: '#3B7A89', fontFamily:'Inter', fontSize:'15px', fontWeight:500 }} onClick={async()=>{await setStorageData('write_review','3')}}>Write a review</span>
                    </Link>
                    }
                    </>
                   )} 
                  <CustomChip label="Purchased" />
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
      </Card>
    );
  }
}

const CustomProgress = styled(LinearProgress)(() => ({
  height: '7px',
  borderRadius: 20,
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#EEEEEE'
  },
  '& > div': {
    backgroundColor: '#FE8155'
  },
  '&.MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#FE8155'
  },
}));

const CustomChip = styled(Chip)(() => ({
  fontSize: 12,
  background: '#FF5900',
  color: 'white',
  height: 20,
  fontWeight: 'bold',
  fontFamily: 'Inter',
  borderRadius: 4,
  '& .MuiChip-label': {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

const CustomChipImage = styled(Chip)(() => ({
  background: '#36AF0C',
  fontWeight: 'bold',
  color: 'white',
  height: 20,
  position: 'absolute',
  fontFamily: 'Inter',
  right: 10,
  top: 140,
  '& .MuiChip-label': {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

export default withStyles(styles)(CourseCard);
