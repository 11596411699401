import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

export interface AdminCourseChaptersResponseType {
  errors: [{ token: "" }];
  error: string;
  data: Array<CourseChaptersDataType>;
  meta: {
    course: string;
    total_course_chapter: number;
    page: string;
    message: string;
  };
}

export interface CourseChaptersDataType {
  id: string;
  type: string;
  attributes: {
    name: string;
    details: string;
    status: boolean;
    course: {
      id: number;
      course_name: string;
      duration: string | null;
      year: string | null;
      created_at: string;
      updated_at: string;
      short_description: string;
      current_price: number | null;
      price: number | null;
      video_url: string | null;
      full_description: string;
      multiple_videos: string | null;
      course_category_id: number;
      valid_for_discount: string | null;
      course_sub_category_id: number;
      course_child_category_id: number;
      account_id: number;
      first_year_price: number;
      second_year_price: number;
      discounted_price: number;
      preview_video_url: string;
      what_learn: string[];
      status: boolean;
      trending: boolean;
      institute_id: number;
      language_options_language_id: number;
      openAddInstituteModal: boolean;
    };
    no_of_classes: number;
    course_learning_material: string;
    course_chapter_thumbnail: string;
    sequence_number: number;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isDrawer: boolean;
  setfullWidth: boolean;
  pageCount: number;
  currentPageCount: number;
  loader: boolean;
  editCourseName: string;
  perPage: number;
  userToken: string;
  isAlert: boolean;
  alertType: Color;
  alertMsg: string;
  isHeaderChecked: boolean;
  isChecked: boolean[];
  searchedCourseChapterText: string;
  adminCourseChaptersData: AdminCourseChaptersResponseType;
  openDeleteCourseChapterModal: boolean;
  deleteCourseChapterId: string | number;
  openAddCourseChapterModal: boolean;
  chapterName: string;
  chapterNameError: string;
  openDeleteInstituteModal: boolean;
  shortDetails: string;
  shortDetailsError: string;
  isUpdate: boolean;
  thumbnailName: string;
  thumbnailImage: any;
  thumbnailPreview: string;
  thumbnailError: string;
  learningMaterialImage: any;
  learningMaterialName: string;
  learningMaterialError: string;
  courseChapterStatus: any;
  courseChapterId: string;
  courseListData: [],
  courseId: unknown;
  courseIdError: string;
  SequenceNumber: string | unknown;
  currentSortState: { order: string; field: string };
  columnSort: string;
  selectedContentSequenceNumberArray: string [];
  contentSequenceNumberArray: string [];
  contentSequenceNumberError: string;
  editSequenceNumber: string | unknown;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminCourseChaptersListApiCallId: string = "";
  deleteCourseChaptersApiCallId: string = "";
  showCourseChaptersApiCallId: string = "";
  postAddCourseChapterApiCallId: string = "";
  editCourseChapterApiCallId: string = "";
  getCourseListApiCallId: string = "";
  statusUpdateApicallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isDrawer: true,
      setfullWidth: true,
      pageCount: 0,
      currentPageCount: 1,
      loader: false,
      editCourseName: "",
      perPage: elementsPerPage,
      userToken: "",
      isAlert: false,
      alertType: "success",
      alertMsg: "",
      adminCourseChaptersData: {
        data: [],
        meta: {
          page: "",
          message: "",
          total_course_chapter: 0,
          course: ""
        },
        errors: [{ token: "" }],
        error: ""
      },
      isHeaderChecked: false,
      isChecked: [],
      searchedCourseChapterText: "",
      openDeleteCourseChapterModal: false,
      deleteCourseChapterId: "",
      openAddCourseChapterModal: false,
      chapterName: "",
      chapterNameError: "",
      openDeleteInstituteModal: false,
      shortDetails: "",
      shortDetailsError: "",
      isUpdate: false,
      thumbnailName: "",
      thumbnailImage: "",
      learningMaterialImage: "",
      learningMaterialName: "",
      learningMaterialError: "",
      thumbnailPreview: "",
      thumbnailError: "",
      courseChapterStatus: true,
      courseChapterId: "",
      courseListData: [],
      courseId: "",
      courseIdError: "",
      SequenceNumber: "1",
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: "",
      selectedContentSequenceNumberArray:[],
      contentSequenceNumberArray: [...Array(99)],
      contentSequenceNumberError: '',
      editSequenceNumber: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const courseId = await getStorageData('EditId');
    const editCourseName = await getStorageData('EditCourseName');
    this.setState({ courseId: courseId, editCourseName });
    await this.getUserToken()
    await this.getCourseChaptersDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseChapterText, this.state.columnSort)
    await this.handleGetCourselist()
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AdminCourseChaptersResponseType) => {
    if (apiRequestCallId === this.getAdminCourseChaptersListApiCallId) {
      this.handleGetCourseChaptersListResponse(responseJson);
    }
    if (apiRequestCallId === this.deleteCourseChaptersApiCallId) {
      this.handleDeletegetCourseChapterListResponse(responseJson);
    }
    if (apiRequestCallId === this.postAddCourseChapterApiCallId) {
      this.handleAdminCourseChapterListResponse(responseJson , 'Course Chapter Created Successfully');
    }
    if (apiRequestCallId === this.showCourseChaptersApiCallId) {
      this.handleShowAdminCourseChapterResponse(responseJson);
    }
    if (apiRequestCallId === this.editCourseChapterApiCallId) {
      this.handleAdminCourseChapterListResponse(responseJson, 'Course Chapter Edited Successfully');
    }
    if (apiRequestCallId === this.getCourseListApiCallId) {
      this.handleCourseListResponse(responseJson);
    }
    if (apiRequestCallId === this.statusUpdateApicallID) {
      this.handleCourseChapterStatusResponse(responseJson)
    }
  };

  handleCourseChapterStatusResponse = async (responseJson: object) => {
    await this.getCourseChaptersDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseChapterText, this.state.columnSort)
    this.setState({ isAlert: true, alertMsg: "Course Chapter status is updated successfully." })
  };

  handleCourseListResponse = (response: any) => {
    this.setState({
      courseListData: response.data,
      loader: false
    });
  };


  handleAdminCourseChapterListResponse = (responseJson: AdminCourseChaptersResponseType, message: string) => {
    let alertMsg, alertType: any;
    if (responseJson.errors) {
      alertMsg = "Course Chapter Thumbnail should be less than 150KB";
      alertType = "error";
    } else {
      alertMsg = message;
      alertType = "success";
      this.handleFeildReset();
      this.getCourseChaptersDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseChapterText, this.state.columnSort)
      this.handleCloseCourseChapterModal();
      this.setState({
        contentSequenceNumberError: "",
        selectedContentSequenceNumberArray: [...this.state.selectedContentSequenceNumberArray, this.state.SequenceNumber as string]
      });
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleShowAdminCourseChapterResponse = (response: any) => {
    const { attributes } = response.data;
    this.setState({
      isUpdate: true,
      loader: false,
      chapterName: attributes.name,
      shortDetails: attributes.details,
      thumbnailName: attributes.course_chapter_thumbnail,
      courseChapterStatus: attributes.status,
      learningMaterialName: attributes.learning_material_name,
      courseChapterId: response.data.id,
      courseId: attributes.course.id,
      thumbnailPreview: attributes.course_chapter_thumbnail,
      SequenceNumber: attributes.sequence_number,
      editSequenceNumber: attributes.sequence_number
    }, () => {
      this.handleOpenAddCourseChapterModal();
    });
  };


  handleDeletegetCourseChapterListResponse = (response: AdminCourseChaptersResponseType) => {
    if (response.error) {
      this.setState({
        openDeleteCourseChapterModal: false,
        deleteCourseChapterId: "",
        loader: false,
        isAlert: true,
        alertMsg: response.error,
        alertType: "error"
      });
    } else {
      this.setState({
        openDeleteCourseChapterModal: false,
        deleteCourseChapterId: "",
        loader: false,
        isAlert: true,
        alertMsg: "Course Chapter Deleted Successfully",
        alertType: "success"
      }, () => this.getCourseChaptersDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseChapterText, this.state.columnSort));
    }
  };

  handleGetCourseChaptersListResponse = (response: AdminCourseChaptersResponseType) => {
    const { meta } = response;
    if (meta?.course === "Record not found.") {
      this.setState({
        isAlert: true,
        alertMsg: "Record not found.",
        alertType: "info",
        loader: false,
        pageCount: 0,
        adminCourseChaptersData: {
          data: [],
          meta: {
            page: "",
            message: "",
            total_course_chapter: 0,
            course: ""
          },
          errors: [{ token: "" }],
          error: ""
        }
      });
    } else if (response && response?.errors) {
      if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
        this.navigationLoginScreen()
      }
    } else {      
      this.addSequenceNumber(response);
      const pageCount = Math.ceil(meta.total_course_chapter / this.state.perPage);
      this.setState({
        adminCourseChaptersData: response,
        loader: false,
        pageCount: pageCount
      });
    }
  };

  addSequenceNumber(response: AdminCourseChaptersResponseType){
    let newSequenceNumberArray: string[] = [];
    response.data.forEach(element => {
      newSequenceNumberArray.push(element.attributes.sequence_number.toString())
    });
    this.setState({
      selectedContentSequenceNumberArray: newSequenceNumberArray
    });
  }

  getUserToken = async () => {
    const token = await getStorageData('loginToken');
    this.setState({ userToken: token });
  };

  adminCourseChaptersApiCall = (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      "token": this.state.userToken
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  toggleDrawer = () => {
    this.setState(prevState => ({
      isDrawer: !prevState.isDrawer,
      setfullWidth: !prevState.setfullWidth,
    }));
  };

  onCourseChaptersPageChange = (event: any, value: number) => {
    const newPageCount = Number(value);
    this.setState({ currentPageCount: newPageCount }, () => {
      this.getCourseChaptersDataFunction(newPageCount, this.state.perPage, this.state.searchedCourseChapterText, this.state.columnSort);
    });
  };

  handleCourseChaptersHeaderCheckboxChange = () => {
    this.setState(prevState => {
      const isHeaderChecked = !prevState.isHeaderChecked;
      const isChecked = Array(prevState.adminCourseChaptersData.data.length).fill(isHeaderChecked);

      return { isHeaderChecked, isChecked };
    });
  };

  handleCourseChaptersRowCheckboxChange = (index: number) => {
    this.setState(prevState => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      const isHeaderChecked = isChecked.every(value => value);

      return { isChecked, isHeaderChecked };
    });
  };

  handleCourseChapterSearch = (e: { target: { value: string } }) => {
    const searchedCourseChapterText = e.target.value;
    this.setState({ searchedCourseChapterText, currentPageCount: 1 }, () => {
      this.getCourseChaptersDataFunction(this.state.currentPageCount, this.state.perPage, searchedCourseChapterText, this.state.columnSort);
    });
  };

  handleCloseDeleteCourseChapterModal = () => {
    this.setState({
      openDeleteCourseChapterModal: false,
      deleteCourseChapterId: ""
    });
  };

  handleOpenDeleteCourseChapterModal = (id: number) => {
    this.setState({
      openDeleteCourseChapterModal: true,
      deleteCourseChapterId: id
    });
  };

  handleCourseChapterName = (event: { target: { value: string } }) => {
    let input = event.target.value;
    input = input.replace(/^[^a-zA-Z0-9]+/, '');
    if (input.length <= 100) {
      this.setState({
        chapterName: input,
        chapterNameError: ""
      });
    }
  };

  handleShortDetails = (event: { target: { value: string } }) => {
    if (event.target.value.length <= 200) {
      this.setState({
        shortDetails: event.target.value,
        shortDetailsError: ""
      });
    }
  };

  handleThumbnailImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState({
          thumbnailError:
            "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
          thumbnailImage: null,
          thumbnailName: "",
          thumbnailPreview: ""
        });
      } else if (fileSize > 2 * 1024 * 1024) {
        this.setState({
          thumbnailError:
            "File size exceeds 2MB. Please choose a smaller file.",
          thumbnailImage: null,
          thumbnailName: "",
          thumbnailPreview: "",
        });
      } else {
        const imageUrl = URL.createObjectURL(file);
        this.setState({
          thumbnailImage: file,
          thumbnailName: fileName,
          thumbnailPreview: imageUrl,
          thumbnailError: ""
        });
      }
    } else {
      this.setState({
        thumbnailImage: null,
        thumbnailName: "",
        thumbnailPreview: "",
        thumbnailError:
          "No file is selected",
      });
    }
  };

  handleLearningMaterial = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["application/pdf"];
      if (!allowedFormats.includes(file.type)) {
        this.setState({
          learningMaterialError:
            "Invalid file format. Please upload a PDF file.",
          learningMaterialImage: null,
          learningMaterialName: "",
        });
      } else if (fileSize > 5 * 1024 * 1024) {
        this.setState({
          learningMaterialError:
            "File size exceeds 5MB. Please choose a smaller file.",
          learningMaterialImage: null,
          learningMaterialName: "",
        });
      } else {
        this.setState({
          learningMaterialImage: file,
          learningMaterialName: fileName,
          learningMaterialError: ""
        });
      }
    } else {
      this.setState({
        learningMaterialImage: null,
        learningMaterialName: "",
        learningMaterialError:
          "No file is selected",
      });
    }
  };

  handleDeleteThumbnail = () => {
    this.setState({
      thumbnailImage: null,
      thumbnailError: "",
      thumbnailName: "",
      thumbnailPreview: ""
    });
  };

  handleCourseChapterStatusCheck = (event: { target: { checked: boolean } }) => {
    this.setState({
      courseChapterStatus: event.target.checked
    });
  };

  handleCloseCourseChapterModal = () => {
    this.setState({
      openAddCourseChapterModal: false,
    }, () => this.handleFeildReset());
  };

  handleOpenAddCourseChapterModal = () => {
    this.setState({
      openAddCourseChapterModal: true,
    });
  };

  handleFeildReset = () => {
    this.setState({
      courseIdError: "",
      chapterName: "",
      chapterNameError: "",
      shortDetails: "",
      shortDetailsError: "",
      thumbnailImage: "",
      thumbnailName: "",
      courseChapterStatus: true,
      isUpdate: false,
      learningMaterialImage: "",
      learningMaterialName: "",
      thumbnailError: "",
      learningMaterialError: "",
      thumbnailPreview: "",
      SequenceNumber: "1",
      contentSequenceNumberError: ''
    });
  };

  handleCourseSelectChange = (id: unknown) => {
    this.setState({
      courseId: id,
      courseIdError: ""
    });
  };

  handleSequenceSelectChange = (id: string | unknown) => {
    this.setState({
      SequenceNumber: id,
      contentSequenceNumberError: ''
    });
  };

  handleGetCourselist = async () => {
    this.setState({ loader: true });
    const endPoint = configJSON.getCourseListAPIEndPoint;
    this.getCourseListApiCallId = this.adminCourseChaptersApiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpGetType,
      endPoint
    });
  };

  handleShowCourseChapters = async (id: string | number) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.getCourseChaptersAPiEndPoint}/${id}`;
    this.showCourseChaptersApiCallId = this.adminCourseChaptersApiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpGetType,
      endPoint
    });
  };

  parseConverter = (data: string | unknown) => {
    let value: string | unknown = data;
    let parseValue = JSON.stringify(value);
    return JSON.parse(parseValue);
  };

  checkFormValidations(updatedValues: Record<string, unknown>): boolean {
    const validationFields = [
      {
        field: "courseId",
        errorKey: "courseIdError",
        errorMessage: "Course is Required",
      },
      {
        field: "chapterName",
        errorKey: "chapterNameError",
        errorMessage: "Chapter Name is Required",
      },
      {
        field: "shortDetails",
        errorKey: "shortDetailsError",
        errorMessage: "Short Details is Required"
      },
      {
        field: "thumbnailName",
        errorKey: "thumbnailError",
        errorMessage: "Thumbnail Image is Required"
      }
    ];

    if(!this.checkSequenceNumberValidate()){
          validationFields.push({
              field: "SequenceNumber",
              errorKey: "contentSequenceNumberError",
              errorMessage: "Sequence number already exist"
          });
      }

    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage }) => {
      const currentField = updatedValues[field] as string;
      if (
        !currentField ||
        currentField && currentField.length === 0
      ) {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: errorMessage
        }));
        hasError = true;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: ""
        }));
      }
    });

    return hasError;
  }

  checkSequenceNumberValidate() {
    if(this.state.editSequenceNumber == this.state.SequenceNumber){
      return true;
    }
    let arr1 = this.state.selectedContentSequenceNumberArray.find((element) => { return this.state.SequenceNumber == element });
    if (arr1 === undefined) {
        return true;
    }
    return false;
}

  handleEditCourseChapter = async () => {
    this.setState({ loader: true });
    const { courseId, courseChapterId, chapterName, shortDetails, thumbnailImage, courseChapterStatus, learningMaterialImage, learningMaterialName, thumbnailName, SequenceNumber } = this.state;
    const formValues = {
      courseId, chapterName, shortDetails, thumbnailName, learningMaterialName
    };
    if (!this.checkFormValidations(formValues)) {
      const formdata = new FormData();
      formdata.append("course_chapters[course_id]", this.parseConverter(courseId));
      formdata.append("course_chapters[name]", chapterName.trim());
      formdata.append("course_chapters[details]", shortDetails.trim());
      formdata.append("course_chapters[status]", courseChapterStatus);
      formdata.append("course_chapters[sequence_number]", SequenceNumber as string);
      if (thumbnailImage) {
        formdata.append("course_chapters[course_chapter_thumbnail]", thumbnailImage, thumbnailName);
      }
      if (learningMaterialImage) {
        formdata.append("course_chapters[course_learning_material]", learningMaterialImage, learningMaterialName);
      }
      this.editCourseChapterApiCallId = this.adminCourseChaptersApiCall({
        method: configJSON.httpPutType,
        endPoint: `${configJSON.getCourseChaptersAPiEndPoint}/${courseChapterId}`,
        body: formdata,
        type: "formdata"
      });
    } else {
      this.setState({ loader: false });
    }
  };

  handlePostCourseChapter = async () => {
    this.setState({ loader: true });
    const { courseId, chapterName, shortDetails, thumbnailImage, thumbnailName, courseChapterStatus, learningMaterialImage, learningMaterialName, SequenceNumber } = this.state;
    const formValues = { courseId, chapterName, shortDetails, thumbnailName, learningMaterialName };
    if (!this.checkFormValidations(formValues)) {
      const formdata = new FormData();
      formdata.append("course_chapters[course_id]", this.parseConverter(courseId));
      formdata.append("course_chapters[name]", chapterName.trim());
      formdata.append("course_chapters[details]", shortDetails.trim());
      formdata.append("course_chapters[status]", courseChapterStatus);
      formdata.append("course_chapters[course_chapter_thumbnail]", thumbnailImage, thumbnailName);
      formdata.append("course_chapters[sequence_number]", SequenceNumber as string);
      if (learningMaterialImage) {
        formdata.append("course_chapters[course_learning_material]", learningMaterialImage, learningMaterialName);
      }
      this.postAddCourseChapterApiCallId = this.adminCourseChaptersApiCall({
        method: configJSON.httpPostType,
        endPoint: configJSON.getCourseChaptersAPiEndPoint,
        body: formdata,
        type: "formdata"
      });
    } else {
      this.setState({ loader: false });
    }
  };

  handleDeleteCourseChapter = async (id: string | number) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.getCourseChaptersAPiEndPoint}/${id}`;
    this.deleteCourseChaptersApiCallId = this.adminCourseChaptersApiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpDeleteType,
      endPoint
    });
  };

  getCourseChaptersDataFunction = async (pageNo: number, per_page: number, searchedCourseChapterString: string, sorting: string) => {
    this.setState({ loader: true });
    const courseId = await getStorageData('EditId');
    this.setState({ courseId: courseId });
    const endPoint = `${configJSON.getCourseChaptersAPiEndPoint}/search?page=${pageNo}&per_page=${per_page}&search_params=${searchedCourseChapterString}${sorting || ""}&course_id=${this.state.courseId}`;
    this.getAdminCourseChaptersListApiCallId = this.adminCourseChaptersApiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpGetType,
      endPoint
    });
  };

  handleSort(field: string) {
    const sortParams: Record<string, string> = {
      ["S.No"]: "id_sort",
      ["Chapter Name"]: "chapter_name_sort",
      Status: "status_sort",
    };
    let fieldSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      fieldSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, fieldSort);
    this.getCourseChaptersDataFunction(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.searchedCourseChapterText,
      fieldSort
    );
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
      },
    });
  };

  handleCourseChapterStatus = async (chapterId: string) => {
    const updatedNotifications = this.state.adminCourseChaptersData.data.map(notification =>
      notification.id === chapterId ? {
        ...notification,
        attributes: {
          ...notification.attributes,
          status: !notification.attributes.status
        }
      } : notification
    );
    this.setState({
      adminCourseChaptersData: {
        data: updatedNotifications,
        errors: [{ token: "" }],
        error: "",
        meta: {
          page: "",
          message: "",
          course: "",
          total_course_chapter: 0
        }
      }
    });

    const clickedData: CourseChaptersDataType | undefined = updatedNotifications.find(notification => notification.id === chapterId);
    const Value: boolean | undefined = clickedData?.attributes.status;

    if (typeof Value === 'boolean' || Value === undefined) {
      const stringValue = Value ? 'true' : 'false';
      const formdata = new FormData();
      formdata.append("course_chapters[status]", stringValue);

      this.statusUpdateApicallID = this.adminCourseChaptersApiCall({
        method: configJSON.httpPutType,
        endPoint: `${configJSON.getCourseChaptersAPiEndPoint}/${chapterId}`,
        body: formdata,
        type: "formdata"
      });
    }
  }

  handleSidebarItemClick = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageLogin);
  };
  // Customizable Area End
}
