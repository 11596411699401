import React from "react";

// Customizable Area Start
import { Box ,Typography} from "@material-ui/core"
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { blueCircle } from "./assets";
const theme = createTheme({
    palette: {
        primary: {
            main: "#366A76",
            contrastText: "#fff",
        },
    },
});


import TermsAndConditionsController, {
  Props,configJSON
} from "./TermsAndConditionsController";
import HeaderWeb from "../../../components/src/Header.web";
import FooterWeb from "../../../components/src/Footer.web";
import HelpCenterHeaderWeb from "../../../components/src/HelpCenterHeader.web";

// Customizable Area End

class TermsAndConditionsWeb extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { title } = this.state;
    return (
      // Customizable Area Start
      <>
      <HeaderWeb id={""} navigation={this.props.navigation} />
      <ThemeProvider theme={theme}>
        <div id="scrollContainer">
        <HelpCenterHeaderWeb title={title} subTitle={configJSON.termsAndConditonSubTitle} />
        <Box style={{ margin: "50px auto" , position: 'relative', maxWidth: "1440px" }}>
            <Box width={'446px'}  height={"446px"} style={webStyles.blueImage}>
                <img src={blueCircle}  />
            </Box>
            <Box width={'446px'}  height={"446px"} 
            style={{
                position: "absolute",
                bottom: -25,
                right: 35,
                zIndex: -2
            }}
            >
                <img src={blueCircle}  />
            </Box>
            <Box style={webStyles.contentContainer}>
            <Typography
              data-test-id="Description"
              className="Description"
              dangerouslySetInnerHTML={{
                __html: `${this.state.swTermsandCondition || ''}`
              }}
            />
            </Box>
        </Box>
        </div>
      </ThemeProvider>
      <FooterWeb navigation={this.props.navigation} id={""} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
    contentContainer: {
        borderRadius:'23px',
        padding: '34px',
        boxShadow: '0px 1px 8px 0px #00000025',
        background: "#FFFFFF",
        minHeight: 450
        },
        blueImage :{
            position: 'absolute' as const,
            left: -25,
            top: 10,
            opacity: 0.8,
            zIndex: -2
          }
};
export default TermsAndConditionsWeb;
// Customizable Area End
