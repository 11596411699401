import React from "react";

// Customizable Area Start
import {
  Box,
  Avatar,
  FormControl,
  styled,
  Typography,
  Button,
  Modal
} from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import MainLayout from "../../../components/src/MainLayout.web";
import AddIcon from "@material-ui/icons/Add";
import {
  DateInput,
  InputBox,
  OneColBox,
  ProfilePicInput,
  StyledSelect,
  TwoColBox,
} from "../../../components/src/GenericInputs";
import { webStyles } from "../../couponcodegenerator/src/AddCouponcodegenerator.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      "& .Mui-selected": {
        backgroundColor: "#176876",
        color: "white",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      display: "none", // Hide ellipsis
    },
  })
)(Pagination);

const StyleGenderInput = styled(FormControl)(() => ({
  '& .MuiInputBase-input': {
    fontFamily: "'Inter'",
    fontSize: '0.9rem',
    color: "rgb(25, 34, 82)",
    padding: '10px 14px'
  },
}))
export const CheckBtn = styled("label")({
  "&.switchs": {
    "& input": {
      opacity: 0,
      width: 0,
      height: 0,
    },
    marginTop: "5px",
    width: "36px",
    position: "relative",
    height: "19px",
    display: "inline-block",
  },
  "& .slider": {
    top: 0,
    left: 0,
    right: 0,
    position: "absolute",
    cursor: "pointer",
    bottom: "-1px",
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    transition: ".4s",
    borderRadius: "20px",
  },
  "& .slider:before": {
    left: "1px",
    bottom: "1px",
    backgroundColor: "white",
    height: "18px",
    borderRadius: "50%",
    WebkitTransition: ".4s",
    position: "absolute",
    width: "18px",
    transition: ".4s",
    content: '""',
  },
  "& input:checked + .slider:before": {
    left: "-10px",
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328",
  },
});
export const TableStyle = styled("table")({
  overflow: "scroll",
  borderTop: "2px solid black",
  color: "black",
  minWidth: "670px",
  position: "relative",
  marginTop: "10px",
  width: "100%",
  borderSpacing: "0",

  "& thead": {
    width: "100%",
    padding: "10px 5px",
    height: "50px",
    borderCollapse: "initial",
  },
  "& th": {
    fontWeight: "600",
    borderBottom: "2px solid black",

    "& .th_box": {
      display: "flex",
      alignItems: "center",
      padding: "0px 10px",

      "& .title_th": {
        color: "#000",
        lineHeight: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.625rem",
      },
      "& .filter_icon": {
        marginLeft: "5px",
        height: "15px",
        cursor: "pointer",
        width: "15px",
        color: "#000",
        borderRadius: 99,
        padding: 1,
        "&:hover": {
          background: "rgba(0, 0, 0, 0.13)",
        },
      },
      '& .active': {
        width: 20,
        height: 20,
        background: "rgba(0, 0, 0, 0.13)"
      }
    },
  },
  "& td": {
    "& .desc_checkbox": {
      width: "100%",
      justifyContent: "center",
      display: "flex",
    },
    "& .desc_icon_box": {
      textAlign: "left",
      "& .desc_sort_icon": {
        backgroundColor: "lightgray",
        width: "20px",
        padding: "3px",
        height: "20px",
      },
    },

    "& .desc_edit_icon_box": {
      display: "flex",
      justifyContent: "center",
      width: "100%",

      alignItems: "center",
      "& .desc_edit_icon": {
        margin: "0 10px",
        cursor: "pointer",
      },
    },
    "& .desc_img": {
      height: "32px",
      width: "32px",
    },
  },
  "&.table_divder": {
    height: "10px",
  },
});
export const TableBody = styled("tbody")({
  "& tr": {
    backgroundColor: "#f5f5f7",
    borderRadius: "20px",
    height: "50px",
    padding: "45px",
    margin: "20px",
  },
  "& td": {
    fontWeight: "400",
    fontSize: "0.625rem",
    textAlign: "center",
    padding: "0px 10px",
  },
  "& td button": {
    border: "none",
  },
});
export const IdFullnamePhoneEmailCols = ({
  row,
  breakWord,
}: {
  row: any;
  breakWord?: boolean;
}) => (
  <>
    <td style={{ textAlign: "left" }}>{row.id}</td>
    <td style={{ textAlign: "left" }}>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        <Avatar
          style={{ width: 28, height: 28 }}
          alt={row.attributes.full_name}
          src={row.attributes.profile_icon}
        />
        <div
          style={{
            display: "flex",
            maxWidth: "135px",
            overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-word' as const,
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical" as const,
              textTransform: "capitalize" as const,
              hyphens: 'auto' as const,
            ...(breakWord ? { wordBreak: "break-word" } : {}),
          }}
        >
          {row.attributes.full_name}
        </div>
      </div>
    </td>
    <td style={{ textAlign: "left" }}>{row.attributes.phone_number}</td>
    <td style={{ textAlign: "left" }}> {row.attributes.email}</td>
  </>
);
export const CheckBtnCol = ({
  row,
  handleToggleStatus,
}: {
  row: any;
  handleToggleStatus: (e: any) => void;
}) => {
  return (
    <td>
      <CheckBtn className="switchs" data-test-id="statusTestId">
        <input
          type="checkbox"
          checked={row.attributes?.activated}
          data-test-id="statusTestId"
          onClick={handleToggleStatus}
        />
        <span className="slider round"></span>
      </CheckBtn>
    </td>
  );
};
export const userProfileWebStyles = {
  imageStyle: {
    width: 100,
  },
  headerText: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    maxWidth: "150px",
  },
  formControl: {
    minWidth: 120,
    maxWidth: "100%",
  },
  tableDivider: {
    height: "8px",
  },
  qualificationBlock: {
    marginTop: 40,
  },
  tableMain: {
    borderTop: "2px solid black",
    borderSpacing: 0,
    margin: " 0 20px",
  },
  paginationViewAll: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    marginRight: "20px",
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableRowIconName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  tableRowHeaderMain: {
    display: "flex",

    columnGap: "8px",
  },
  tableDataText: {
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  dataText: {
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  dataColumn: {
    display: "flex",
    columnGap: "10px",
    background: "#d9d9d9",
    marginBottom: "5px",
  },
  rowName: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  inputLabelText: {
    color: "#192252",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    marginTop: "10px",
  },
  paginationViewText: { color: "#176876" },

  inputTwoCol: {
    display: "flex",
    width: "100%",
    columnGap: "10px",
    marginBottom: "15px",
  },
  inputTwoColMain: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: " 4rem",
    borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
    marginBottom: "15px",
  },
  inputFieldMain: {
    marginBottom: "20px",
    borderBottom: "2px solid rgba(189, 188, 188, 0.314)",
  },
  editInputFieldMain: {
    borderBottom: "2px solid rgba(189, 188, 188, 0.314)",
    marginBottom: "20px",
  },
  errorText: {
    color: "red",
    fontSize: "0.625rem",
  },
  labelInputMain: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    marginBottom: "20px",
  },
  addResetBtnMain: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
    columnGap: "10px",
  },
  updateResetBtnMain: {
    display: "flex",
    marginBottom: "30px",
    justifyContent: "center",
  },
  editlabelInputMain: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    marginBottom: "20px",
  },
  editInputTwoColMain: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: " 4rem",
    borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
  },
  deleteCloseMain: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  inputFieldDivide: {
    display: "flex",
    columnGap: "20px",
    flex: 1,
  },
  stateLabelInput: {
    display: "flex",
    columnGap: "20px",
  },
  stateLabelInputMain: {
    flex: "1",
    paddingRight: "10px",
  },
  districtLabelInputMain: {
    flex: "1",
    paddingLeft: "10px",
  },
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperStyle: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    width: "400px",
    borderRadius: "20px",
  },
  editStudentMainLayout: { width: "97%", margin: "0 20px" },
  editStudentFormMain: { display: "flex" },
  cancelBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    color: "black",
    fontWeight: 600,
    background: "white",
    border: "1px solid grey",
    marginTop: "20px",
  },
  deleteBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    border: " none",
    color: " white",
    fontWeight: 600,
    backgroundColor: " #FD7100",
    marginTop: "20px",
  },
  bulkDeleteBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "10px 0",
    border: " none",
    color: " white",
    fontWeight: 600,
    backgroundColor: " #FD7100",
    alignSelf:"center",
    marginRight:'10px'
  },
  filterDataIconClick: {
    cursor: "pointer",
  },
};
export const StudentDeleteModal = styled(Modal)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
});

export const StudentModalView = styled(Box)({
  height: "150px",
  width: "400px",
  borderRadius: '5px',
  outline: "none",
  backgroundColor: '#ffffff',
  display: "flex",
  margin: "20px",
  boxShadow: "0px 0px 3px px transparent",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const StudentDeleteText = styled(Box)({
  width: "100%",
  height: "37px",
  fontWeight: 600,
  fontSize: "16px",
  textAlign: 'center'
});

export const StudentCancelButton = styled("button")({
  width: "145px",
  height: "40px",
  color: "#206575",
  fontSize: "16px",
  border: "1px solid #206575",
  backgroundColor: "white",
  marginLeft: '10px'
});

export const StudentModalButton = styled(Box)({
  display: "flex",
});

export const StudentDeleteButton = styled("button")({
  width: "145px",
  border: "0",
  height: "40px",
  color: "white",
  backgroundColor: "#FD7100",
  fontSize: "16px",
  fontWeight: 500
});
// Customizable Area End

import EducationalUserProfileController, {
  Props,
} from "./EducationalUserProfileController";

export default class EducationalUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }
  toggleStatus(row: any, index: any) {
    this.handleToggleStatus(
      row.id,
      !row.attributes?.activated,
      index,
      "studentData"
    );
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleSearchText(e)}
        backData={() => {
          this.handleStudentDataReset();
          this.setState({ isAddStudent: false, isStudentEdit: false });
        }}
        isLoading={this.state.isLoading}
        title={this.determineTitle()}
        isAdd={this.state.isAddStudent}
        isEdit={this.state.isStudentEdit}
        actionButtons={[
          {
            icon: <AddIcon />,
            iconType: "jsxElement",
            onClick: () => this.setState({ isAddStudent: true }),
            text: "Add Student",
            testId: "addStudentTestId",
          },
        ]}
        isStudent={true}
        handleFromDate={(e: any) => this.handleFormDate(e)}
        formDate={this.state.studentFromDate}
        toDate={this.state.studentToDate}
        handleToDate={(e: any) => this.handleToDate(e)}
      >
        <NotificationAlertMessage
          dataTestId="notificationModalTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {this.state.isAddStudent && (
          <main className="table_content">{this.addStudent()}</main>
        )}
        {this.state.isStudentEdit && (
          <main className="table_content">
            {this.editStudent(this.state.studentDataEdit)}
          </main>
        )}
        {!this.state.isAddStudent && !this.state.isStudentEdit && (
          <main className="table_data">{this.studentList()}</main>
        )}
      </MainLayout>
    );
    // Customizable Area End
  }
  // Customizable Area Start

  genderDateBirthRow(type: "edit" | "add", data?: any) {
    const isEdit = type === "edit";
    return (
      <TwoColBox>
        <Box className="child__box">
          <Typography className="box__label">Gender*</Typography>
          <StyleGenderInput variant="outlined" className="box__input" fullWidth>
            <StyledSelect
              fullWidth={true}
              onChange={(e) => {
                isEdit
                  ? this.handleEditGenderChange(e, "gender")
                  : this.handleGenderChange(e);
              }}
              disableUnderline
              value={isEdit ? data.gender || "" : this.state.gender || ""}
              data-test-id="selectGender"
              IconComponent={IoIosArrowDown}
            >
              <option value="" disabled>{"Select Gender"}</option>
              <option value="Male">{"Male"}</option>
              <option value="Female">{"Female"}</option>
              <option value="Other">{"Other"}</option>
            </StyledSelect>
            <span style={userProfileWebStyles.errorText}>
              {this.state.genderErr}
            </span>
          </StyleGenderInput>
        </Box>

        <Box className="child__box">
          <Typography className="box__label">Date of Birth*</Typography>
          <FormControl variant="outlined" className="box__input" fullWidth>
            <DateInput
              onChange={(e) => {
                isEdit
                  ? this.handleEditDateChange(e, "date_of_birth")
                  : this.handleDateChange(e.target.value);
              }}
              inputProps={{
                inputProps: {
                  max: moment().subtract(8, "years").format("YYYY-MM-DD")
                },
              }}
              value={isEdit ? data.date_of_birth || "" : this.state.date || ""}
              style={
                !isEdit ? { color: this.state.date ? "" : "transparent" } : {}
              }
              data-test-id="dateChange"
            />

            <span style={userProfileWebStyles.errorText}>
              {this.state.dobErr}
            </span>
          </FormControl>
        </Box>
      </TwoColBox>
    );
  }

  imagePreview(isEdit: boolean, data?: any) {
    return (
      <>
        {!this.state.previewThumbnail ? (
          <label
            htmlFor="fileInput"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              border: "2px dashed #ccc",
              cursor: "pointer",
              width: "98%",
              borderRadius: "5px",
              margin: "0 auto",
              color: "#192252",
              background: "white",
            }}
          >
            {this.imagePlaceholder(isEdit, data)}
            <input
              type="file"
              id="fileInput"
              data-test-id="fileChange"
              style={{ display: "none" }}
              onChange={(e) =>
                isEdit
                  ? this.handleEditFileChange(e, "profile_icon")
                  : this.handleFileChange(e)
              }
            />
          </label>
        ) : (
          this.imageInput(isEdit)
        )}
      </>
    );
  }
  imagePlaceholder(isEdit: boolean, data?: any) {
    return isEdit ? (
      <>
        {data.profile_iconName ? (
          <Typography style={{ fontSize: "0.875rem" }}>
            {data.profile_iconName}
          </Typography>
        ) : (
          <Typography style={{ fontSize: "0.875rem" }}>
            {data.profile_icon || " + Upload Profile Picture"}
          </Typography>
        )}
      </>
    ) : (
      <>
        {this.state.selectedFile ? (
          <Typography style={{ fontSize: "0.875rem" }}>
            {this.state.selectedFile.name}
          </Typography>
        ) : (
          <Typography style={{ fontSize: "0.875rem" }}>
            + UPLOAD PROFILE PICTURE
          </Typography>
        )}
      </>
    );
  }
  imageInput(isEdit: boolean) {
    return (
      <ProfilePicInput
        onChange={(e) =>
          isEdit
            ? this.handleEditFileChange(e, "profile_icon")
            : this.handleFileChange(e)
        }
        onRemove={() => this.handleFileRemove()}
        data-test-id="fileChange"
        name="instructorImgName"
        previewThumbnail={this.state.previewThumbnail}
      />
    );
  }

  addStudent() {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Add Image*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                {this.imagePreview(false)}
                <span style={userProfileWebStyles.errorText}>
                  {this.state.fileErr}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <OneColBox>
            <Typography className="box__label">Full Name*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="fullNameChanges"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={this.state.fullName}
                onChange={(e) => {
                  this.handleFullNameChange(e);
                }}
              />
              <span style={userProfileWebStyles.errorText}>
                {this.state.fullNameErr}
              </span>
            </FormControl>
          </OneColBox>

          {this.genderDateBirthRow("add")}

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Email*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  data-test-id="emailChange"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  value={this.state.Email}
                  onChange={(e) => this.handleEmailchange(e)}
                />

                <span style={userProfileWebStyles.errorText}>
                  {this.state.emailErr}
                </span>
              </FormControl>
            </Box>

            <Box className="child__box">
              <Typography className="box__label">Mobile*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  data-test-id="mobileNoChange"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  value={this.state.phone}
                  onChange={(e) => this.handleInputChange(e)}
                />
                <span style={userProfileWebStyles.errorText}>
                  {this.state.phoneErr}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <OneColBox>
            <Typography className="box__label">School/College*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="schoolNameChange"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={this.state.schoolName}
                onChange={(e) => this.handleSchoolNameChange(e)}
              />
              <span style={userProfileWebStyles.errorText}>
                {this.state.schoolNameErr}
              </span>
            </FormControl>
          </OneColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">State*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <StyledSelect
                  data-test-id="selectState"
                  value={this.state.state}
                  fullWidth={true}
                  onChange={(e: any) => {
                    this.handleStateChange(e);
                  }}
                  disableUnderline
                  IconComponent={IoIosArrowDown}
                  name="lable"
                >
                  <option aria-label="None" value=""></option>
                  {this.state.stateList.length > 0 &&
                    this.state.stateList?.map((val: any, key: any) => {
                      return (
                        <option
                          data-label={val.lable}
                          value={val.value}
                          key={key}
                        >
                          {val.lable}
                        </option>
                      );
                    })}
                </StyledSelect>
                <span style={{ color: "red", fontSize: "0.625rem" }}>
                  {this.state.stateErr}
                </span>
              </FormControl>
            </Box>

            <Box className="child__box">
              <Typography className="box__label">District*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <StyledSelect
                  IconComponent={IoIosArrowDown}
                  fullWidth
                  disableUnderline
                  data-test-id="selectDistrict"
                  value={this.state.District}
                  onChange={(e) => {
                    this.handleDistrictChange(e);
                  }}
                >
                  <option aria-label="None" value=""></option>
                  {this.state.districtList.length > 0 &&
                    this.state.districtList.map((val) => {
                      return (
                        <option
                          data-label={val.attributes.name}
                          value={val.id}
                          key={val.id}
                        >
                          {val.attributes.name}
                        </option>
                      );
                    })}
                </StyledSelect>
                <span style={{ fontSize: "0.625rem", color: "red" }}>
                  {this.state.districtErr}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <div style={userProfileWebStyles.addResetBtnMain}>
            <Button
              data-test-id="handleReset"
              style={webStyles.resetBtn}
              variant="outlined"
              onClick={() => this.handleStudentDataReset()}
            >
              Reset
            </Button>
            <Button
              data-test-id="createStudent"
              style={webStyles.createBtn}
              onClick={() => this.handleAddStudent()}
            >
              Create
            </Button>
          </div>
        </Box>
      </div>
    );
  }
  deleteModal(id: any, totalCourses:any) {
    return (
      <div
        style={{
          position: "absolute",
          right: "0",
          top: "100%",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          minWidth: "120px",
          padding: "20px",
        }}
      >
        <h2>Are your sure to delete this Student?</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "10px",
          }}
        >
          <Button
            data-test-id="deleteAdminModal"
            variant="outlined"
            style={userProfileWebStyles.deleteBtn}
            onClick={() => this.deleteStudentData(id, totalCourses)}
          >
            Delete
          </Button>
          <Button
            data-test-id="cancelDeleteModal"
            style={userProfileWebStyles.cancelBtn}
            onClick={() => this.setState({ isDeleteModalOpen: false })}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  editStudent(data: any) {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Add Image*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                {this.imagePreview(true, data)}
                <span style={userProfileWebStyles.errorText}>
                  {this.state.fileErr}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <OneColBox>
            <Typography className="box__label">Full Name*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="fullNameChanges"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={data.full_name || ""}
                onChange={(e) => {
                  this.handleEditFullNameChange(e, "full_name");
                }}
              />
              <span style={userProfileWebStyles.errorText}>
                {this.state.fullNameErr}
              </span>
            </FormControl>
          </OneColBox>

          {this.genderDateBirthRow("edit", data)}

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Email*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  data-test-id="emailChange"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  value={data.email || ""}
                  onChange={(e) => this.handleEditEmailChange(e, "email")}
                />
                <span style={userProfileWebStyles.errorText}>
                  {this.state.emailErr}
                </span>
              </FormControl>
            </Box>

            <Box className="child__box">
              <Typography className="box__label">Mobile*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  data-test-id="mobileNoChange"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  value={data.phone_number || ""}
                  onChange={(e) =>
                    this.handleEditInputChange(e, "phone_number")
                  }
                />
                <span style={userProfileWebStyles.errorText}>
                  {this.state.phoneErr}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <OneColBox>
            <Typography className="box__label">School/College*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="schoolNameChange"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={data.school_name || ""}
                onChange={(e) =>
                  this.handleEditSchoolNameChange(e, "school_name")
                }
              />
              <span style={userProfileWebStyles.errorText}>
                {this.state.schoolNameErr}
              </span>
            </FormControl>
          </OneColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">State*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <StyledSelect
                  data-test-id="selectState"
                  value={this.state.state}
                  fullWidth={true}
                  name="lable"
                  disableUnderline
                  onChange={(e: any) => {
                    this.handleEditStateChange(e, "state");
                  }}
                  IconComponent={IoIosArrowDown}
                >
                  <option aria-label="None" value=""></option>
                  {this.state.stateList?.length > 0 &&
                    this.state.stateList?.map((val: any, key: any) => {
                      return (
                        <option
                          value={val.value}
                          key={key}
                          data-label={val.lable}
                        >
                          {val.lable}
                        </option>
                      );
                    })}
                </StyledSelect>
                <span
                  style={{
                    marginLeft: "1px",
                    color: "red",
                    fontSize: "0.625rem",
                  }}
                >
                  {this.state.stateErr}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">District*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <StyledSelect
                  value={this.state.District}
                  onChange={(e) => {
                    this.handleEditDistrictChange(e, "District");
                  }}
                  disableUnderline
                  IconComponent={IoIosArrowDown}
                  fullWidth
                  data-test-id="selectDistrict"
                >
                  <option aria-label="None" value=""></option>
                  {this.state.districtList &&
                    this.state.districtList.length > 0 &&
                    this.state.districtList.map((val) => {
                      return (
                        <option
                          value={val.id}
                          data-label={val.attributes.name}
                          key={val.id}
                        >
                          {val.attributes.name}
                        </option>
                      );
                    })}
                </StyledSelect>
                <span
                  style={{
                    marginLeft: "1px",
                    fontSize: "0.5rem",
                    color: "red",
                  }}
                >
                  {this.state.districtErr}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <div style={userProfileWebStyles.addResetBtnMain}>
            <Button
              data-test-id="handleReset"
              style={webStyles.resetBtn}
              variant="outlined"
              onClick={() => this.handleStudentDataReset()}
            >
              Reset
            </Button>
            <Button
              data-test-id="createStudent"
              style={webStyles.createBtn}
              onClick={() => this.handleEditStudent()}
            >
              Update
            </Button>
          </div>
        </Box>
      </div>
    );
  }

  tableHeader() {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <thead>
        <tr>
          {this.state.studentColHeader.length > 0 &&
            this.state.studentColHeader.map((item, index) => (
              <th key={index}>
                <Box className="th_box">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: index >= 0 && index < 5 ? "start" : "center",
                      columnGap: "5px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      className="title_th"
                      style={{ maxWidth: "150px" }}
                    >
                      {item}
                    </Typography>
                    {["S.no", "Student Name", "Status"].includes(item) && (
                      <IoIosArrowDown
                      data-test-id="filterData"
                        className={`filter_icon ${isCurrentSort(item) ? "active" : ""}`}
                        onClick={() => this.filterDataOnCol(item, "student")}
                        style={{
                          transform: isCurrentSort(item)
                            ? "none"
                            : "rotate(180deg)",
                        }}
                      />
                    )}
                  </div>
                </Box>
              </th>
            ))}
        </tr>
      </thead>
    );
  }

  tableBody(row: any, index: any) {
    return (
      <>
        <tr key={row.id}>
          <IdFullnamePhoneEmailCols row={row} />
          <td style={{display:'flex', alignSelf:'center'}}>
            <div>
              <div>
                {this.setGender(row.attributes?.gender)}
              </div>
              <div>
                {moment(row.attributes?.date_of_birth).format("MMM D, YYYY")}
              </div>
            </div>
          </td>
          <td>
            <div>
              {moment(row.attributes?.created_at).format("DD MMM YYYY")}
            </div>
            <div>{moment(row.attributes?.created_at).format("HH:mm:ss")}</div>
          </td>
          <td>
            <div>{row.attributes?.no_of_purchased_course}</div>
          </td>
          <td>
            <div>
              <div>
                {row.attributes.district_name?.name || row.attributes.district}
              </div>
              <div>
                {row.attributes.state_name?.name || row.attributes.state}
              </div>
            </div>
          </td>
          <CheckBtnCol
            row={row}
            handleToggleStatus={(e: any) => this.toggleStatus(row, index)}
          />
          <td style={{ borderRadius: "00px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleEditStudentData(row)}
              />
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <FaRegTrashAlt
                  data-test-id="deleteIconTestId"
                  className="desc_edit_icon"
                  color={"black"}
                  size={17}
                  onClick={() => this.deleteStudent(row.id)}
                />            
                   
                {this.state.isDeleteModalOpen &&
                  this.state.studentId == row.id &&
                  this.deleteModal(row.id,row.attributes.no_of_purchased_course)}
              </div>        
            </Box>
          </td>
        </tr>
        <Box
          className="table_divider"
          style={userProfileWebStyles.tableDivider}
        ></Box>
      </>
    );
  }

  studentList() {
    return (
      <Box>
        <TableStyle>
          {this.tableHeader()}
          <span className="dividerLine"></span>
          <TableBody>
            {this.state.studentData?.length > 0 &&
              this.state.studentData?.map((row: any, index: number) =>
                this.tableBody(row, index)
              )}
          </TableBody>
        </TableStyle>
        <Box style={userProfileWebStyles.paginationViewAll}>
          <StyledPagination
            count={this.state.studentPageCount}
            page={this.state.studentPageNo}
            onChange={this.handlePageChange}
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
            classes={{ ul: "custom-pagination" }}
          />
        </Box>
      </Box>
    );
  }
  // Customizable Area End
}
