// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import moment from "moment";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import { parseErrors } from "./FlatDiscountController.web";
import { uncheck, check, unselect, select, viewExpand, viewCollapse } from "./assets";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface DeleteApiResponse { "meta": { "error": string } }

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ApiResponse {
  "data": DataApiResponse
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface DataApiResponse {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  "id": number,
  "coupon_code": string,
  "coupon_name": string,
  "discount_type": string,
  "discount": string,
  "applicable_on": number,
  "start_date": string,
  "expiry_date": string,
  "status": boolean,
  "is_public_coupon": boolean,
  "max_discount_upto": number,
  "percentage": number,
  "is_unlimited": boolean,
  "no_of_times_coupon_applicable": number,
  "usage_per_user": number,
  "reedem_count": number,
  "terms_n_condition": string,
  "coupon_code_course": CouponCodeCourse[],
  "created_at": string,
  "updated_at": string,
  "Applicable Purchase": string,
}

export interface CouponCodeCourse {
  id: number;
  coupon_code_id: number;
  course_id: number;
  courseable_type: string;
  created_at: Date;
  updated_at: Date;
}

interface CourseAndBundleCourseType {
  "id": number,
  "type": string,
  "name": string,
  "isSelected": boolean,
}

interface CourseType {
  "id": number,
  "course_name": string,
  "class_name": string,
  "isSelected": boolean,
}

interface ChildCategoryType {
  "id": number,
  "child_category_name": string,
  "class_name": string,
  "isExpanded": boolean,
  "courses": CourseType[]
}

interface SubCategoryType {
  "id": number,
  "class_name": string,
  "sub_category_name": string,
  "isExpanded": boolean,
  "child_categories": ChildCategoryType[]
}

interface CategoryType {
  "id": string,
  "type": string,
  "attributes": {
    "name": string,
    "isExpanded": boolean,
    "class_content": SubCategoryType[]
  }
}

export interface AssociatedCoursesType {
  "courses_data": CourseAndBundleCourseType[],
  "bundle_courses_data": CourseAndBundleCourseType[],
  "category_data": {
    "data": CategoryType[]
  }
}

interface S {
  // Customizable Area Start
  userToken: any;
  pageNo: number;
  perPage: number;
  totalPages: number;
  isLoading: boolean;
  openDeleteModal: boolean;
  deleteId: string | number;
  searchText: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isModalOpen: boolean;
  couponFormData: CouponFormData;
  couponFormDataInitial?: CouponFormData;
  isUpdate: boolean;
  discountTypeList: any[];
  couponsList: any[];
  couponDataId: any;
  associatedCourses: AssociatedCoursesType;
  currentSortState: { order: string; field: string };
  columnSort: string;
  couponeType: {id:number, title: string}[],
  isUnlimitedTimeUse: boolean;
  selectedCourseID: string,
  selectedChapterID: string,
  selectedClassID: string,
  isDiscountFocus: boolean,
  isMiniOrderFocus: boolean,
  allCourseExpand: boolean,
  bundleCourseExpand: boolean,
  isMaxDiscountUpFocus: boolean
  dataCouponCodeCourse: CouponCodeCourse[]
  // Customizable Area End
}

interface CouponFormData {
  selectedCouponeType: number;
  coupon_name: string;
  coupon_code: string;
  discount_type: string;
  discount: string | number;
  applicable_on: number | string;
  max_discount_upto: string | number;
  start_date: string
  expiry_date: string;
  no_of_times_coupon_applicable: string | number;
  usage_per_user: string | number;
  terms_n_condition: string,
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export const initialValues: any = {
  isLoading: false,
  userToken: "",
  pageNo: 1,
  perPage: elementsPerPage,
  totalPages: 1,
  openDeleteModal: false,
  deleteId: "",
  searchText: "",
  isUpdate: false,
  isAlert: false,
  alertMsg: "",
  alertType: "success",
  isModalOpen: false,
};

export default class CouponcodegeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCouponsApiCallId: string = "";
  getAssociatedCoursesApiCallId: string = "";
  deleteCouponAPICallId: string = "";
  addCouponApiCallId: string = "";
  showCouponApiCallId: string = "";
  toggleCouponStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.state = {
      ...initialValues,
      couponFormData: {
        selectedCouponeType: 0,
        coupon_name: "",
        coupon_code: "",
        discount_type: "amount",
        discount: "",
        applicable_on: "",
        max_discount_upto:"",
        start_date: "",
        expiry_date: "",
        no_of_times_coupon_applicable: "",
        usage_per_user: '',
        terms_n_condition: '',
      },
      couponFormDataInitial: undefined,
      couponsList: [],
      discountTypeList: [
        { id: "amount", name: "Amount" },
        { id: "percentage", name: "Percentage" },
      ],
      associatedCourses: {},
      couponDataId: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: "",
      couponeType:[{id:0, title:'Create Public Coupon'}, {id:1, title:'Create Private Coupon'}],
      isUnlimitedTimeUse:false,
      selectedCourseID: "",
      selectedChapterID: "",
      selectedClassID: "",
      isDiscountFocus: false,
      isMiniOrderFocus:false,
      allCourseExpand: false,
      bundleCourseExpand: false,
      isMaxDiscountUpFocus:false,
      dataCouponCodeCourse: []
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getCouponsDataList(this.state.columnSort);
    this.handleGetAssociatedCourses();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCouponsApiCallId:
            this.handleGetCouponsResponse(responseJson);
            break;
          case this.deleteCouponAPICallId:
            this.deleteCouponApiResponse(responseJson);
            break;
          case this.addCouponApiCallId:
            this.handleAddCouponApiResponse(responseJson);
            break;
          case this.showCouponApiCallId:
            this.handleGetCouponListResponse(responseJson);
            break;
          case this.toggleCouponStatusApiCallId:
            this.handleToggleOptionsResponse(responseJson);
            break;
          case this.getAssociatedCoursesApiCallId:
            this.handleGetAssociatedCoursesResponse(responseJson);
            break;
        }
      } else {
        parseErrors(responseJson, this.parseApiErrorResponse, this.send, this.props);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let couponData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (couponData) {
        this.handleShowCoupon(couponData.ScreenTest);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleShowCoupon = (id: string | number) => {
    this.setState({ isLoading: true });
    this.showCouponApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeCouponGet,
      endPoint: configJSON.couponWebApiEndpoint + `/${id}`,
    });
  };

  handleGetCouponListResponse(resJson: ApiResponse) {
    const { data } = resJson;
    const { attributes } = data;

    const values: CouponFormData = {
      selectedCouponeType: attributes.is_public_coupon ? 0 : 1,
      coupon_name: attributes.coupon_name,
      coupon_code: attributes.coupon_code,
      discount_type: attributes.discount_type,
      discount: attributes.discount,
      applicable_on: attributes.applicable_on,
      max_discount_upto: attributes.max_discount_upto,
      start_date: moment(attributes.start_date).format("YYYY-MM-DD"),
      expiry_date: moment(attributes.expiry_date).format("YYYY-MM-DD"),
      no_of_times_coupon_applicable: attributes.no_of_times_coupon_applicable || "",
      usage_per_user: attributes.usage_per_user,
      terms_n_condition: attributes.terms_n_condition
    };

    this.setState({
      couponDataId: data.id,
      isUpdate: true,
      couponFormData: values,
      couponFormDataInitial: values,
      isLoading: false,
      isUnlimitedTimeUse: attributes.is_unlimited,
      dataCouponCodeCourse: attributes.coupon_code_course,
    }, () => {
      if (this.state.associatedCourses && this.state.associatedCourses.courses_data) {
        this.setState({
          associatedCourses: this.getDefaultAssociatedCourseList(this.state.associatedCourses)
        })
      }
    });
  }

  handleSort(field: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      Coupons: "name_sort",
      Status: "status_sort",
      "Expiry Date": "expiry_date_sort"
    };
    let columnSort = "";
    const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      columnSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, columnSort);
    this.getCouponsDataList(columnSort);
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
      }
    });
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false,
    });
  };

  handleGetCouponsResponse(resJson: any) {
    if (resJson.meta?.coupons === "Record not found") {
      this.setState({
        isAlert: true,
        alertMsg: resJson.meta.coupons,
        alertType: "info",
        isLoading: false,
        totalPages: 0,
        couponsList: [],
      });
    } else if (
      resJson?.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0]?.token == configJSON.tokenExpiredMessage
    ) {
      this.couponNavigate();
    } else {
      let count = resJson.meta.total_coupons / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        couponsList: resJson.data,
        totalPages: count,
        isLoading: false,
      });
    }
  }

  getDefaultAssociatedCourseList = (resJson: AssociatedCoursesType) => {
    const getCourseIdList = this.state.dataCouponCodeCourse.filter((couponCourse => couponCourse.courseable_type === "BxBlockProfile::Course")).map(couponCourse => couponCourse.course_id)
    const getBundleCourseIdList = this.state.dataCouponCodeCourse.filter((couponCourse => couponCourse.courseable_type === "BxBlockProfile::BundleCourse")).map(couponCourse => couponCourse.course_id)
    return { 
      ...resJson,
      courses_data: resJson.courses_data.map((data) => {
        if (getCourseIdList.includes(data.id)) {
          return ({ ...data, isSelected: true })
        }
        return ({ ...data, isSelected: false })
      }),
      bundle_courses_data: resJson.bundle_courses_data.map((data) => {
        if (getBundleCourseIdList.includes(data.id)) {
          return ({ ...data, isSelected: true })
        }
        return ({ ...data, isSelected: false })
      }),
      category_data: {
        data: resJson.category_data.data.map((data) => ({
          ...data,
          attributes: {
            ...data.attributes,
            isExpanded: false,
            class_content: data.attributes.class_content.map(classData => ({
              ...classData,
              isExpanded: false,
              child_categories: classData.child_categories.map(childData => ({
                ...childData,
                isExpanded: false,
                courses: childData.courses.map(courseData => {
                  if (getCourseIdList.includes(courseData.id)) {
                    return ({
                      ...courseData,
                      isSelected: true
                    })  
                  }
                  return ({
                    ...courseData,
                    isSelected: false
                  })
                })
              }))
            }))
          }
        }))
      }
    } 
  }

  handleGetAssociatedCoursesResponse(resJson: AssociatedCoursesType) {
    this.setState({ isLoading: false })
    if (resJson && !!resJson.courses_data) {
      this.setState({ 
        associatedCourses: {
          ...resJson,
          category_data: {
            data: resJson.category_data.data.filter(categoryData => 
              !!categoryData.attributes.class_content.filter(classData => 
                !!classData.child_categories.filter(childData => 
                  !!childData.courses.length
                ).length
              ).length
            ).map(categoryData => ({
              ...categoryData,
              attributes: {
                ...categoryData.attributes,
                class_content: categoryData.attributes.class_content.filter(classData => 
                  !!classData.child_categories.filter(childData => 
                    !!childData.courses.length
                  ).length
                ).map(classData => ({
                  ...classData,
                  child_categories: classData.child_categories.filter(childData => 
                    !!childData.courses.length
                  )
                }))
              }
            }))
          }
        }
      }, () => {
        this.setState({
          associatedCourses: this.getDefaultAssociatedCourseList(this.state.associatedCourses)
        })
      });
    }
  }

  handleAddCouponApiResponse(responseJson: any) {
    if (
      responseJson &&
      responseJson.data &&
      responseJson.data.type === "error"
    ) {
      const object = Object.keys(
        responseJson.data.attributes.errors as any[]
      )[0];
      const objectMsg = Object.values(
        responseJson.data.attributes.errors as any[]
      )[0][0];
      const stateFinal: any =
        objectMsg === "has already been taken"
          ? { isModalOpen: true, isLoading: false, alertMsg: object.replace(/_/g, " ") }
          : {
              isAlert: true,
              alertMsg: object + " " + objectMsg || "Error during petition",
              alertType: "error",
              isLoading: false,
            };
      this.setState(stateFinal);
    } else {
      this.couponNavigate();
    }
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleGoBack() {
    this.couponNavigate();
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getCouponsDataList(this.state.columnSort);
    });
  };
  handleOpenModal = (id: string) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: "",
    });
  };
  handleEditPropsCoupon = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddCouponcodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };
  deleteCouponApiResponse(responseJson: DeleteApiResponse) {
    if (responseJson.meta.error) {
      this.setState({
        isAlert: true,
        alertMsg: responseJson.meta.error,
        alertType: "error",
        isLoading: false,
        openDeleteModal: false
      });
    } else {
      this.setState({ openDeleteModal: false, isLoading: false, isAlert: true,
        alertMsg: "Coupon deleted successfully.",
        alertType: "success", }, () =>
        this.getCouponsDataList(this.state.columnSort)
      );
    }
  }

  handleDeleteCoupon(id: string | number) {
    this.setState({ isLoading: true });
    this.deleteCouponAPICallId = this.apiCall({
      method: configJSON.deleteCouponAPiMethod,
      endPoint: configJSON.couponWebApiEndpoint + `/${id}`,
    });
  }
  handleCouponsSearch(e: any) {
    let searchVal = e.target.value;
    this.setState({ searchText: searchVal, pageNo: 1 }, () => {
      this.getCouponsDataList(this.state.columnSort);
    });
  }
  getCouponsDataList(sorting?:string) {
    const searchParamsString = this.state.searchText
      ? `&search_params=${this.state.searchText}&${sorting}`
      : `${sorting}`;
    this.setState({ isLoading: true });
    this.getCouponsApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeCouponGet,
      contentType: configJSON.validationApiContentType,
      endPoint:
        configJSON.couponWebSearchApiEndpoint +
        `?page=${this.state.pageNo}&per_page=${this.state.perPage}${searchParamsString}`,
    });
  }
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token: this.state.userToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleCouponInputChange(e: any, type?: "number" | "date") {
    let { name, value } = e.target;
    if (type === "number") {
      if (!/^(?=.{0,10}$)[0-9]*\.?[0-9]*$/.test(value)) return;
    }
    if (name === "coupon_name" && value.length > 60) {
      return;
    }
    if (name === "coupon_code" && value.length > 10) {
      return;
    }
    if (name === "discount_type") {
        this.setState({
          couponFormData: {
            ...this.state.couponFormData,
            [name]: value,
            discount: "",
            applicable_on: "",
            max_discount_upto: ""
          },
        });
        return;
    }
    if (name === "discount" && this.state.couponFormData.discount_type === "percentage" && Number(value) > 100) {
      return
    } 
    this.setState((prevState) => ({
      couponFormData: {
        ...prevState.couponFormData,
        [name]: value,
      },
    }));
  }

  onCourseInfoClick() {
    this.setState({
      alertMsg: 
        "If any associated course is not selected, this coupon will be applicable for all the existing courses.",
      isAlert: true,
      alertType: "info"
    })
  }

  checkValidation = () => {
    const {
      coupon_name,
      coupon_code,
      discount_type,
      discount,
      start_date,
      expiry_date,
      no_of_times_coupon_applicable,
      usage_per_user,
    } = this.state.couponFormData;

    if (
      !coupon_name ||
      !coupon_code ||
      !discount_type ||
      !Number(discount) ||
      !start_date ||
      !expiry_date ||
      (!this.state.isUnlimitedTimeUse && !Number(no_of_times_coupon_applicable)) ||
      !Number(usage_per_user)
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Please fill all mandatory fields.",
        alertType: "error",
        isLoading: false,
      });
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (new Date(start_date) < today && !this.state.isUpdate) {
      this.setState({
        isAlert: true,
        alertMsg: "Start date cannot be in the past.",
        alertType: "error",
        isLoading: false,
      });
      return false;
    }

    if (new Date(expiry_date) < new Date(start_date)) {
      this.setState({
        isAlert: true,
        alertMsg: "Expiry date cannot be before start date.",
        alertType: "error",
        isLoading: false,
      });
      return false;
    }

    if (new Date(expiry_date) < today) {
      this.setState({
        isAlert: true,
        alertMsg: "Expiry date cannot be in the past.",
        alertType: "error",
        isLoading: false,
      });
      return false;
    }
    return true
  }

  handleAddCoupon() {
    this.setState({ isLoading: true });
    if (!this.checkValidation()) {
      return;
    }
    const {
      selectedCouponeType,
      coupon_name,
      coupon_code,
      discount_type,
      discount,
      applicable_on,
      max_discount_upto,
      start_date,
      expiry_date,
      no_of_times_coupon_applicable,
      usage_per_user,
      terms_n_condition
    } = this.state.couponFormData;

    const getCourseIdList = [ 
      ...this.state.associatedCourses.courses_data.filter(course => course.isSelected).map(course => ([course.id, course.type])),
      ...this.state.associatedCourses.bundle_courses_data.filter(bundleCourse => bundleCourse.isSelected).map(bundleCourse => ([bundleCourse.id, bundleCourse.type])),
    ]

    this.state.associatedCourses.category_data.data.forEach(category => 
      category.attributes.class_content.forEach(classes => 
        classes.child_categories.forEach(child => 
          child.courses.forEach(course => {
            if (course.isSelected) {
              getCourseIdList.push([course.id, course.class_name])
            }
          })
        )
      )
    )

    if (!getCourseIdList.length) {
      this.setState({
        isAlert: true,
        alertMsg: "Please fill all mandatory fields.",
        alertType: "error",
        isLoading: false,
      });
      return false;
    }

    const body = {
      data: {
        is_public_coupon: !Number(selectedCouponeType),
        coupon_name,
        coupon_code,
        discount_type,
        discount,
        percentage: discount_type === "amount" ? null : discount,
        applicable_on,
        max_discount_upto,
        start_date: moment(start_date).format("DD-MM-YYYY"),
        expiry_date: moment(expiry_date).format("DD-MM-YYYY"),
        no_of_times_coupon_applicable,
        is_unlimited: this.state.isUnlimitedTimeUse,
        usage_per_user,
        terms_n_condition,
        course_id: getCourseIdList
      },
    };

    this.addCouponApiCallId = this.apiCall({
      method: this.state.isUpdate ? configJSON.putCouponApiMethod : configJSON.createCouponAPiMethod,
      endPoint: this.state.isUpdate ? configJSON.couponWebApiEndpoint + `/${this.state.couponDataId}?is_status_update=false` : configJSON.couponWebApiEndpoint,
      body,
      contentType: configJSON.validationApiContentType,
    });
  }

  addCouponNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddCouponcodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  couponNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationCouponcodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleToggleOptionsResponse = (responseJson: any) => {
    this.getCouponsDataList(this.state.columnSort);
    if (responseJson.meta && responseJson.meta.message) {
      this.setState({
        isAlert: true,
        alertMsg:
          responseJson.meta.message,
        alertType: "success",
        isLoading: false,
      });
    } else {
      this.setState({
        isAlert: true,
        alertMsg: responseJson.meta &&
          responseJson.meta.error || "Something want wrong.",
        alertType: "error",
        isLoading: false,
      });
    }
  };

  handleToggleStatus = (id: string, status: boolean, index: number) => {
    let clone = [...this.state.couponsList];
    clone[index].attributes.status = !status;

    this.setState({
      couponsList: clone,
    });

    this.setState({ isLoading: true });
    this.toggleCouponStatusApiCallId = this.apiCall({
      body: { data: { status: !status } },
      contentType: configJSON.validationApiContentType,
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.couponWebApiEndpoint + `/${id}?is_status_update=true`,
    });
  };

  handleGetAssociatedCourses = () => {
    this.setState({ isLoading: true });
    this.getAssociatedCoursesApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeCouponGet,
      endPoint: configJSON.associatedCoursesApiEndpoint,
    });
  };

  handleUnlimitedTimeUseChange() {
    this.setState({
      isUnlimitedTimeUse: !this.state.isUnlimitedTimeUse
    }, () => {
      if (this.state.isUnlimitedTimeUse) {
        this.setState({ couponFormData: { ...this.state.couponFormData, no_of_times_coupon_applicable: "" }})
      }
    })
  }

  handleDiscountFocus = (value: boolean) => {
    this.setState({
      isDiscountFocus: value
    });
  };

  handleMiniOrderFocus = (value: boolean) => {
    this.setState({
      isMiniOrderFocus: value
    });
  };

  discountTypePlaceholder() {
    let discountSymbol = null;

    if (this.state.couponFormData.discount_type === "amount") {
      discountSymbol = '(₹)';
    } else if (this.state.couponFormData.discount_type === 'percentage') {
      discountSymbol = '%';
    }

    return discountSymbol;
  }

  getCheckUncheckStatus(value: boolean) {
    if (value) {
      return check
    }
    return uncheck
  }

  getSelectUnSelectStatus(value: boolean) {
    if (value) {
      return select
    }
    return unselect
  }

  getExpandCollapseStatus(value: boolean) {
    if (value) {
      return viewExpand
    }
    return viewCollapse
  }

  handleMaxDiscountFocus = (value: boolean) => {
    this.setState({
      isMaxDiscountUpFocus: value
    });
  };

  handleAllCourseChange = () => {
    this.setState({
      allCourseExpand: !this.state.allCourseExpand
    })
  }

  getIsAllCourseSelected = () => {
    return this.state.associatedCourses && this.state.associatedCourses.courses_data && this.state.associatedCourses.courses_data.every(data => data.isSelected)
  }

  handleAllCourseCheck = () => {
    const setSelectedValue = !this.getIsAllCourseSelected()
    const newCourseData = [...this.state.associatedCourses.courses_data].map(data => ({ ...data, isSelected: setSelectedValue }))
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        courses_data: newCourseData
      }
    })
  }

  handleCourseCheck = (courseId: number) => {
    const newCourseData = [...this.state.associatedCourses.courses_data].map(data => {
      if (data.id === courseId) {
        return ({ ...data, isSelected: !data.isSelected })
      }
      return data
    })
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        courses_data: newCourseData
      }
    })
  }

  handleBundleCourseChange = () => {
    this.setState({
      bundleCourseExpand: !this.state.bundleCourseExpand
    })
  }

  getIsBundleCourseSelected = () => {
    return this.state.associatedCourses && this.state.associatedCourses.bundle_courses_data && this.state.associatedCourses.bundle_courses_data.every(data => data.isSelected)
  }

  handleBundleCourseCheck = () => {
    const setSelectedValue = !this.getIsBundleCourseSelected()
    const newBundleCourseData = [...this.state.associatedCourses.bundle_courses_data].map(data => ({ ...data, isSelected: setSelectedValue }))
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        bundle_courses_data: newBundleCourseData
      }
    })
  }

  handleBCourseCheck = (courseId: number) => {
    const newBundleCourseData = [...this.state.associatedCourses.bundle_courses_data].map(data => {
      if (data.id === courseId) {
        return ({ ...data, isSelected: !data.isSelected })
      }
      return data
    })
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        bundle_courses_data: newBundleCourseData
      }
    })
  }

  handleMainCourseChange = (mainCategoryId: string) => {
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: this.state.associatedCourses.category_data.data.map(data => {
            if (data.id === mainCategoryId) {
              return {
                ...data,
                attributes: {
                  ...data.attributes,
                  isExpanded: !data.attributes.isExpanded,
                }
              }
            }
            return data
          })
        }
      }
    })
  }

  getIsMainCourseSelected = (mainCategoryId: string) => {
    if (this.state.associatedCourses && 
      this.state.associatedCourses.category_data && 
      this.state.associatedCourses.category_data.data) {
        const categoryData =  this.state.associatedCourses.category_data.data.find(category => category.id === mainCategoryId)
        return !!categoryData && categoryData.attributes.class_content.every(classes => (
          classes.child_categories.every(child => (
            child.courses.every(course => course.isSelected)
          ))
        ))
      }
      return false;
  }

  handleMainCourseCheck = (mainCategoryId: string) => {
    const setSelectedValue = !this.getIsMainCourseSelected(mainCategoryId)
    const newMainCourseData = [...this.state.associatedCourses.category_data.data].map(data => {
      if (data.id === mainCategoryId) {
        return ({ 
          ...data,
          attributes: {
            ...data.attributes,
            class_content: data.attributes.class_content.map(classData => ({
              ...classData,
              child_categories: classData.child_categories.map(childData => ({
                ...childData,
                courses: childData.courses.map(courseData => ({
                  ...courseData,
                  isSelected: setSelectedValue
                }))
              }))
            }))
          }
        })
      }
      return data
    })

    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: newMainCourseData
        }
      }
    })
  }

  handleClassCourseChange = (mainCategoryId: string, classSubCategoryId: number) => {
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: this.state.associatedCourses.category_data.data.map(data => {
            if (data.id === mainCategoryId) {
              return {
                ...data,
                attributes: {
                  ...data.attributes,
                  class_content: data.attributes.class_content.map(classData => {
                    if (classData.id === classSubCategoryId) {
                      return {
                        ...classData,
                        isExpanded: !classData.isExpanded
                      }
                    }
                    return classData
                  })
                }
              }
            }
            return data
          })
        }
      }
    })
  }

  getIsClassSubCategorySelected = (mainCategoryId: string, classSubCategoryId: number) => {
    if (this.state.associatedCourses && 
      this.state.associatedCourses.category_data && 
      this.state.associatedCourses.category_data.data) {
        const categoryData =  this.state.associatedCourses.category_data.data.find(category => category.id === mainCategoryId)
        const subCategoryData = !!categoryData && categoryData.attributes.class_content.find(subCategory => subCategory.id === classSubCategoryId)
        return !!subCategoryData && subCategoryData.child_categories.every(child => (
          child.courses.every(course => course.isSelected)
        ))
      }
      return false;
  }

  handleClassSubCategoryCheck = (mainCategoryId: string, classSubCategoryId: number) => {
    const setSelectedValue = !this.getIsClassSubCategorySelected(mainCategoryId, classSubCategoryId)
    const newMainCourseData = [...this.state.associatedCourses.category_data.data].map(data => {
      if (data.id === mainCategoryId) {
        return ({ 
          ...data,
          attributes: {
            ...data.attributes,
            class_content: data.attributes.class_content.map(classData => {
              if (classData.id === classSubCategoryId) {
                return ({
                  ...classData,
                  child_categories: classData.child_categories.map(childData => ({
                    ...childData,
                    courses: childData.courses.map(courseData => ({
                      ...courseData,
                      isSelected: setSelectedValue
                    }))
                  }))
                })
              }
              return classData
            })
          }
        })
      }
      return data
    })

    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: newMainCourseData
        }
      }
    })
  }

  handleChildCourseChange = (mainCategoryId: string, classSubCategoryId: number, childCategoryId: number) => {
    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: this.state.associatedCourses.category_data.data.map(data => {
            if (data.id === mainCategoryId) {
              return {
                ...data,
                attributes: {
                  ...data.attributes,
                  class_content: data.attributes.class_content.map(classData => {
                    if (classData.id === classSubCategoryId) {
                      return {
                        ...classData,
                        child_categories: classData.child_categories.map(childData => {
                          if (childData.id === childCategoryId) {
                            return {
                              ...childData,
                              isExpanded: !childData.isExpanded
                            }
                          }
                          return childData
                        })
                      }
                    }
                    return classData
                  })
                }
              }
            }
            return data
          })
        }
      }
    })
  }

  getIsChildCategorySelected = (mainCategoryId: string, classSubCategoryId: number, childCategoryId: number) => {
    if (this.state.associatedCourses && 
      this.state.associatedCourses.category_data && 
      this.state.associatedCourses.category_data.data) {
        const categoryData =  this.state.associatedCourses.category_data.data.find(category => category.id === mainCategoryId)
        const subCategoryData = !!categoryData && categoryData.attributes.class_content.find(subCategory => subCategory.id === classSubCategoryId)
        const childCategoryData = !!subCategoryData && subCategoryData.child_categories.find(childCategory => childCategory.id === childCategoryId)
        return !!childCategoryData && childCategoryData.courses.every(course => course.isSelected)
      }
      return false;
  }

  handleChildCategoryCheck = (mainCategoryId: string, classSubCategoryId: number, childCategoryId: number) => {
    const setSelectedValue = !this.getIsChildCategorySelected(mainCategoryId, classSubCategoryId, childCategoryId)
    const newMainCourseData = [...this.state.associatedCourses.category_data.data].map(data => {
      if (data.id === mainCategoryId) {
        return ({ 
          ...data,
          attributes: {
            ...data.attributes,
            class_content: data.attributes.class_content.map(classData => {
              if (classData.id === classSubCategoryId) {
                return ({
                  ...classData,
                  child_categories: classData.child_categories.map(childData => {
                    if (childData.id === childCategoryId) {
                      return({
                        ...childData,
                        courses: childData.courses.map(courseData => ({
                          ...courseData,
                          isSelected: setSelectedValue
                        }))
                      })
                    }
                    return childData;
                  })
                })
              }
              return classData
            })
          }
        })
      }
      return data
    })

    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: newMainCourseData
        }
      }
    })
  }

  getCourseDataCheck = (courses: CourseType[], courseId: number) => {
    return courses.map(courseData => {
      if (courseData.id === courseId) {
        return ({
          ...courseData,
          isSelected: !courseData.isSelected
        })
      }
      return courseData
    })
  }

  getChildCategoryDataCheck = (child_categories: ChildCategoryType[], childCategoryId: number, courseId: number) => {
    return child_categories.map(childData => {
      if (childData.id === childCategoryId) {
        return({
          ...childData,
          courses: this.getCourseDataCheck(childData.courses, courseId)
        })
      }
      return childData;
    })
  }

  handleCourseCategoryCheck = (mainCategoryId: string, classSubCategoryId: number, childCategoryId: number, courseId: number) => {
    const newMainCourseData = [...this.state.associatedCourses.category_data.data].map(data => {
      if (data.id === mainCategoryId) {
        return ({ 
          ...data,
          attributes: {
            ...data.attributes,
            class_content: data.attributes.class_content.map(classData => {
              if (classData.id === classSubCategoryId) {
                return ({
                  ...classData,
                  child_categories: this.getChildCategoryDataCheck(classData.child_categories, childCategoryId, courseId)
                })
              }
              return classData
            })
          }
        })
      }
      return data
    })

    this.setState({
      associatedCourses: {
        ...this.state.associatedCourses,
        category_data: {
          data: newMainCourseData
        }
      }
    })
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
