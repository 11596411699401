import React from 'react';
// Customizable Area Start
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import FooterWeb from '../../../components/src/Footer.web';
import HeaderWeb from '../../../components/src/Header.web';
import HelpCenterHeaderWeb from '../../../components/src/HelpCenterHeader.web';
import FaqController, {configJSON, Props} from './FaqController.web';
import { faqBannerImg } from './assets';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: '0 9%',
      marginBottom: 30
    },
    heading: {
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: "40px",
      color: "#0F172A",
      '@media (min-width: 1366px) and (max-width: 1920px)': {
        fontSize: "30px",
      },
      '@media (min-width: 128px) and (max-width: 1366px)': {
        fontSize: "25px",
      },
      '@media (min-width: 600px) and (max-width: 1280px)': {
        fontSize: "25px",
      },
    },
    accordionContainer:{
      borderRadius: '25px',
      padding: "35px 28px 35px 28px",
      boxShadow: "0px 1px 8px 0px #00000025"
    },
    accordion: {
      marginBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0 2px 1px rgba(0, 0, 0, 0.1)',
      minHeight: 89,
      '& .MuiAccordion-root': {
        '&::before': {
          display: 'none'
        },
      },
    },
    '& .MuiAccordion-root': {
      '&::before': {
        display: 'none'
      },
    },
    accordionDetails: {
      backgroundColor: '#fff',
    },
    accordionDetailsText: {
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#4C8491E8",
      padding: '0px 16px',
      '@media (min-width: 1366px) and (max-width: 1920px)': {
        fontSize: "24px",
      },
      '@media (min-width: 128px) and (max-width: 1366px)': {
        fontSize: "20px",
      },
      '@media (min-width: 600px) and (max-width: 1280px)': {
        fontSize: "20px",
      },
    },
    seeMoreButton: {
      display: 'block',
      margin: '20px auto',
      color: "#4C8491E8",
      textTransform: 'none',
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: "25px"
    },
    faqTitle: {
      fontFamily: 'Inter',
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      marginTop: 12,
      fontWeight: 700,
      color: "#4C8491E8",
      '@media (min-width: 1366px) and (max-width: 1920px)': {
        fontSize: '43px',
      },
      '@media (min-width: 128px) and (max-width: 1366px)': {
        fontSize: '33px',
      },
      '@media (min-width: 600px) and (max-width: 1280px)': {
        fontSize: '33px',
      },
    },
  });
// Customizable Area End
class FaqsWeb extends FaqController{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { expanded, hasMore ,faqs} = this.state;

    return (
      <>
      <HeaderWeb navigation={navigation} id={''} />
      <div id="scrollContainer">
      <HelpCenterHeaderWeb 
      title={configJSON.faqsTitle} 
      subTitle={configJSON.faqSubTitle} 
      img={faqBannerImg}
      />
      <div className={classes.root}>
        <Typography data-test-id={"faqTitle"} className={classes.faqTitle}>{configJSON.faqScreenHeading}</Typography>
        <Box className={classes.accordionContainer}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            data-test-id="accordion"
            expanded={expanded === `panel${index}`}
            onChange={this.handleChangeAccordion(`panel${index}`)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#4C8491E8" }}/>}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography className={classes.heading}>{faq.attributes.topic_name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography className={classes.accordionDetailsText}>{faq.attributes.topic_description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        </Box>
        {hasMore && (
            <Button data-test-id="seeMoreButton" className={classes.seeMoreButton} onClick={() => this.handleSeeMore()}>
              {configJSON.seeMoreText}
            </Button>
          )}

      </div>
      </div>
      <FooterWeb navigation={navigation} id={''} />
      </>
    );
    // Customizable Area End

  }
}

// Customizable Area Start
export default withStyles(styles)(FaqsWeb);
export { FaqsWeb };
// Customizable Area End
