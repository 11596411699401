import React from 'react';
// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, Box, TableCell, TableContainer, Grid, Typography, TableHead, TableRow, Button, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from "@material-ui/lab/Skeleton";
import HeaderWeb from '../../../components/src/Header.web';
import FooterWeb from '../../../components/src/Footer.web';
import { IoIosArrowDown } from "react-icons/io";
import OrderManagementController, { configJSON } from './OrderManagementController.web';
import moment from 'moment';
import Loader from '../../../components/src/Loader.web';

const styles = (theme: any) => ({
    headingTitle: {
        fontFamily: 'Inter',
        fontWeight: 700,
        color: "#FFFFFF",
        margin: "0px",
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            fontSize: '43.63px',
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            fontSize: '33.63px',
        },
    },
    headerSubTitle: {
        fontFamily: 'Inter',
        color: "#FFFFFF",
        fontWeight: 400 as const,
        lineHeight: '31.79px',
        letterSpacing: '0.10943496972322464px',
        textAlign: 'left' as const,
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            fontSize: '26.26px',
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            fontSize: '16.26px',
        },
        marginTop:'15px'
    },
    screenHeader: {
        display: "flex",
        flexDirection: "column" as const,
        maxWidth: 1440, 
        margin: '0px auto',
    },
    searchInputMain: {
      margin: '20px 40px',
      '@media (min-width: 1441px) and (max-width: 1920px)': {
         margin: '20px 0px',
        }
    },
    searchInput: {
        marginBottom: theme.spacing(2),
        width: '40%',
        color: "#000",
        '& input::placeholder': {
            color: "#000000 !important",
            fontFamily: 'Inter',
            fontWeight: 400,
            opacity: 1,
            marginRight: 8,
            '@media (min-width: 1366px) and (max-width: 1920px)': {
                fontSize: '24.26px',
            },
            '@media (min-width: 600px) and (max-width: 1280px)': {
                fontSize: '18.26px',
            },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: 32,
            border: "0.5px solid #FFA235",
            ':hover': {
                borderRadius: 32,
                border: "0.5px solid #FFA235",
            }
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 32,
            border: "0.5px solid #FFA235"
        },
        '& .MuiOutlinedInput-adornedEnd': {
            padding: 0,
            paddingLeft: 8
        }
    },
    TableContainer: {
        maxWidth: '100%'
    },
    filterButton: {
        marginLeft: theme.spacing(1),
    },
    filterText: {
        fontSize: 25,
        fontFamily: 'Inter',
        fontWeight: 400
    },
    searchIconBtn: {
        width: 40,
        height: '40px',
        background: "linear-gradient(180deg, #FFF54B 0%, #F4C952 100%)",
        boxShadow: '0px 1px 3px 0px #0000001E inset',
        borderRadius: 27,
        color: '#212121',
    },
    searchIcon: {
        fontSize: '1rem'
    },
    tableBodyWrapper: {
        padding: "0px 16px",
        '& .MuiTableCell-body': {
            color: "#212121",
            fontFamily: 'Inter',
            fontWeight: 400,
            lineHeight: "26.63px",
            '@media (min-width: 1366px) and (max-width: 1920px)': {
                fontSize: '22px',
            },
            '@media (min-width: 600px) and (max-width: 1280px)': {
                fontSize: '20px',
            },
        }
    },
    courseTableBodyCell: {
        paddingLeft: 32,
        '@media (min-width: 1441px) and (max-width: 1920px)': {
            paddingLeft: 0
        }
    },
    courseImg:{
        marginRight: 8, 
        width: 99, 
        height: 76, 
        borderRadius: 8 
    },
    tableHeader: {
        backgroundColor: "#fff",
        '& .MuiTableCell-head': {
            color: "#212121",
            fontFamily: 'Inter',
            fontWeight: 600,
            lineHeight: "26.63px",
            borderBottom: '1px solid #747474',
            '@media (min-width: 1366px) and (max-width: 1920px)': {
                fontSize: '22px',
            },
            '@media (min-width: 600px) and (max-width: 1280px)': {
                fontSize: '20px',
            },
        }
    },
    coursesCell: {
        paddingLeft: 50,
        '@media (min-width: 1441px) and (max-width: 1920px)': {
            paddingLeft: 0
        }
    },
    statusCell: {
        paddingRight: 30
    },
    actionButton: {
        backgroundColor: '#115C71',
        color: '#FFFFFF',
        textTransform: 'none' as const,
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 600,
        padding: '6px 8px',
        '&:hover': {
            backgroundColor: '#115C71',
        },
    },
    showMoreButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: 24,
        marginTop: 32
    },
    showMoreButton: {
        boxShadow: '0px 2px 6px 0px #00000046',
        color: "#1220C1",
        borderRadius: '33px',
        height: 60,
        width: 202,
        fontSize: 21,
        fontWeight: 400,
        fontFamily: 'Inter',
        textTransform: 'none' as const
    },
    tableCellAlign: {
        textAlign: 'center' as const
    }
});

// Customizable Area End

class PurchaseHistory extends OrderManagementController {
    // Customizable Area Start


    async componentDidMount() {
        await this.getUserToken()
        await this.getOrderPurchaseHistory(1)
    }

    renderSkeletonRows = (rows = 3) => (
        [...Array(rows)].map((_, index) => (
            <TableRow key={`skeleton-${index}`}>
                <TableCell>
                    <Box display="flex" alignItems="center" className={this.props.classes.courseTableBodyCell}>
                        <Skeleton  width={50} height={50} className={this.props.classes.courseImg} />
                        <Skeleton variant="text" width="60%" height={20} style={{ marginLeft: 16 }} />
                    </Box>
                </TableCell>
                <TableCell><Skeleton variant="text" width="80%" height={20} /></TableCell>
                <TableCell><Skeleton variant="text" width="80%" height={20} /></TableCell>
                <TableCell><Skeleton variant="text" width="50%" height={20} /></TableCell>
                <TableCell><Skeleton variant="text" width="50%" height={20} /></TableCell>
            </TableRow>
        ))
    );

    // Customizable Area End 

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {isLoading, PurchaseCourses ,showMoreLoading, searchText, purchaseCoursesPage, totalPage } = this.state;

        return (
            <>
                <HeaderWeb navigation={this.props.navigation} id={''} />
                <Grid container className="header" style={{zIndex: 7, overflow: 'initial', height: 200 }}>
                    <Grid container item xs={12} md={12} className={classes.screenHeader}>
                        <h1 data-test-id="title" className={classes.headingTitle}>{configJSON.purchaseHistoryTitle}</h1>
                        <Typography className={classes.headerSubTitle}>
                            {configJSON.purchaseHistorySubTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <div style={{ maxWidth: 1440, margin: '0px auto', width: '100%'}}>
                    <Box display={'flex'} justifyContent={'space-between'} className={classes.searchInputMain}>
                        <TextField
                            variant="outlined"
                            data-test-id="searchInput"
                            placeholder="Search with keywords..."
                            className={classes.searchInput}
                            value={searchText}
                            onChange={this.handleSearchChange}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  this.searchPurchases()
                                }
                              }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton className={classes.searchIconBtn} onClick={this.searchPurchases}>
                                            <SearchIcon className={classes.searchIcon} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <TableContainer component={Box} className={classes.TableContainer}>
                        <Table>
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.coursesCell}>{configJSON.coursesText}</TableCell>
                                    <TableCell className={classes.tableCellAlign}>{configJSON.enrollNumber}</TableCell>
                                    <TableCell className={classes.tableCellAlign}>{configJSON.validateTillText}</TableCell>
                                    <TableCell className={classes.tableCellAlign}>{configJSON.totalPrice}</TableCell>
                                    <TableCell className={`${classes.statusCell} ${classes.tableCellAlign}`}>{configJSON.statusText}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBodyWrapper}>
                                {isLoading && !showMoreLoading ? (
                                    this.renderSkeletonRows(3)
                                ) :
                                PurchaseCourses && PurchaseCourses.length > 0 && PurchaseCourses.map((purchase, index) => (
                                        <TableRow key={`${purchase.id}-${index}`} data-test-id="PurchaseCourses">
                                            <TableCell>
                                                <Box display={'flex'} alignItems={'center'} className={classes.courseTableBodyCell}>
                                                    <img
                                                        src={purchase.attributes.course_order[0].course_data.data.attributes.course_thumbnail || purchase.attributes.course_order[0].course_data.data.attributes.bundle_course_thumbnail}
                                                        alt="course thumbnail"
                                                        className={classes.courseImg}
                                                    />
                                                    {purchase.attributes.course_order[0].course_data.data.attributes.course_name || purchase.attributes.course_order[0].course_data.data.attributes.bundle_name	}
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellAlign}>{moment(purchase.attributes.course_order[0].date, "MMMM D, YYYY").format("D MMM YYYY")}</TableCell>
                                            <TableCell className={classes.tableCellAlign}>{this.calculateValidityTill(purchase.attributes.course_order[0].place_order_date, purchase.attributes.course_order[0].no_of_year)}</TableCell>
                                            <TableCell className={classes.tableCellAlign}>₹{purchase.attributes.course_order[0].price}</TableCell>
                                            <TableCell className={classes.tableCellAlign} style={{ color: this.calculateStatus(this.calculateValidityTill(purchase.attributes.course_order[0].place_order_date, purchase.attributes.course_order[0].no_of_year)) === "Active" ? "green" : "red" }}>{this.calculateStatus(this.calculateValidityTill(purchase.attributes.course_order[0].place_order_date, purchase.attributes.course_order[0].no_of_year))}</TableCell>
                                        </TableRow>
                                ))}
                                 {showMoreLoading && this.renderSkeletonRows(3)}
                            </TableBody>
                            
                        </Table>
                    </TableContainer>
                    <div className={classes.showMoreButtonContainer}>
                    {!isLoading && PurchaseCourses.length === 0 && <Typography data-test-id="orderNotFound" variant='h4' style={{ textAlign: "center" }}>{configJSON.orderNotFound}</Typography>}
                    {PurchaseCourses.length !== 0 && totalPage > purchaseCoursesPage ? (
                        <Button 
                        data-test-id="showmore-btn"
                        startIcon={<IoIosArrowDown size={25} color="#000000" />} 
                        className={classes.showMoreButton}
                        onClick={this.handleShowMorePurchaseOrder}
                        >{configJSON.showMoreButton}</Button>
                    ) : null}
                    </div>
                </div>
                <FooterWeb navigation={this.props.navigation} id={''} />
            </>
        );
        // Customizable Area End

    }
}
// Customizable Area Start
export default withStyles(styles)(PurchaseHistory);
export { PurchaseHistory }
// Customizable Area End
