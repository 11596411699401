import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
const columns = [
  { label: "User ID", width: 50 },
  { label: "Full Name", width: 70 },
  { label: "Email", width: 50 },
  { label: "Phone", width: 50 },
  { label: "Transaction ID", width: 50 },
  { label: "Purchased Course", width: 200 },
  { label: "Transaction Date", width: 90 },
  { label: "Transaction Status", width: 60 },
  { label: "Order ID", width: 90 },
  { label: "Billing Address", width: 80 },
  { label: "Order Total", width: 50 },
];

import PaymentManagementController, {
  Props,
  Payment,
} from "./PaymentManagementController.web";
import { PaginationBox, webStyles } from "./RazorpayTransactions.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
// Customizable Area End

export default class PaymentManagement extends PaymentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: Payment) => {
    return (
      <>
        <tr>
          <td
            className="tr_desc"
            style={{
              textAlign: "left",
              borderRadius: "10px 0px 0px 10px",
            }}
          >
            {item.account_id}
          </td>
          <td style={{ textAlign: "left" }}>{item.full_name}</td>
          <td style={{ textAlign: "left" }}>{item.email}</td>
          <td style={{ textAlign: "left" }}>{item.full_phone_number}</td>
          <td style={{ textAlign: "left" }}>{item.transaction_id}</td>
          <td style={{ textAlign: "left", padding: "10px 10px" }}>
            {item.course_name}
          </td>
          <td style={{ textAlign: "left" }}>{item.created_at}</td>
          <td style={{ textAlign: "left" }}>{item.transation_status}</td>
          <td style={{ textAlign: "left" }}>{item.order_id}</td>
          <td style={{ textAlign: "left" }}>{item.address}</td>
          <td style={{ textAlign: "left", borderRadius: "0px 10px 10px 0px" }}>
            {item.total_price}
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTablePagination() {
    return (
      <PaginationBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Pagination
          data-test-id="pageChangebutton"
          count={this.state.pageCount}
          shape="rounded"
          boundaryCount={1}
          page={this.state.currentPageCount}
          siblingCount={0}
          onChange={this.onPageChange}
        />
      </PaginationBox>
    );
  }

  renderTable = () => {
    return (
      <Box>
        <div style={{ overflow: "auto" }}>
          <TableStyle>
            <thead>
              <tr>
                {columns.map((header, index) => {
                  return (
                    <th key={index.toString()}>
                      <Box
                        className="th_box"
                        style={{
                          justifyContent: "left",
                          width: header.width,
                          textAlign: "left",
                        }}
                      >
                        <Typography className="title_th">
                          {header.label}
                        </Typography>
                      </Box>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <span style={webStyles.dividerLine} />
            <TableBody>
              {this.state.paymentsList.map((item) =>
                this.renderTableBody(item)
              )}
            </TableBody>
          </TableStyle>
        </div>
        {this.renderTablePagination()}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.isLoading}
        disableSearch
        isStudent
        title="Payment Management"
        data-test-id="mainLayoutEvent"
        handleFromDate={this.handleDate1Change}
        formDate={this.state.isSelectedDate1}
        toDate={this.state.isSelectedDate2}
        handleToDate={this.handleDate2Change}
      >
        <NotificationAlertMessage
          data-test-id="oncloseAlert"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.oncloseAlert}
          isOpen={this.state.isAlert}
        />
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
