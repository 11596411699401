import React from "react";

// Customizable Area Start
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import "./dashboard.web.css";
import {
  Typography,
  Select,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled
} from "@material-ui/core";
import { chargeIcon, dollarIcon, reportIcon, userScanIcon } from "./assets";
import Skeleton from "@material-ui/lab/Skeleton";

import MainLayout from "../../../components/src/MainLayout.web";
import { IoIosArrowDown, IoIosCalendar } from "react-icons/io";
import { IoEllipsisHorizontal } from "react-icons/io5";

import DashboardController, {
  OverviewResponse,
  Props,
  SearchType,
  ChartResponse,
  ChartData
} from "./DashboardController.web";
import { DateInput } from "../../../components/src/GenericInputs";
// Customizable Area End

export default class EducationalUserProfile extends DashboardController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        isLoading={false}
        title="Dashboard"
        isAdd
        newUI
      >
        <main
          style={{
            height: "88vh",
            overflowX: "scroll",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          {this.mainScreen()}
        </main>
      </MainLayout>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  getRangeFormat(state: ChartResponse | undefined) {
    if (!state) {
      return undefined;
    }
    const key = state.range.length - 1;
    let max = state.range[key];
    if (typeof max === "string") {
      const normalized = max.toLowerCase().replace(/[km]/g, "");
      let number = parseFloat(normalized);

      if (max.toLowerCase().includes("k")) {
        number *= 1000;
      }

      if (max.toLowerCase().includes("m")) {
        number *= 1000000;
      }

      max = number;
    }

    return [0, max];
  }

  getLabelFromType(isOverview: boolean, chartName?: string) {
    const usePreviousSearchType =
      (!this.state.startDate || !this.state.endDate) &&
      this.state.searchType === SearchType.custom;
    switch (
      usePreviousSearchType
        ? this.state.previousSearchType
        : this.state.searchType
    ) {
      case SearchType.monthly:
        return isOverview ? "Monthly overview" : "Last month's " + chartName;
      case SearchType.weekly:
        return isOverview ? "Weekly overview" : "Last week’s " + chartName;
      case SearchType.today:
        return isOverview ? "Today’s overview" : "Yesterday’s " + chartName;
      case SearchType.custom:
        return isOverview
          ? this.getSearchTypeForCustom().label
          : this.getSearchTypeForCustom().subLabel + chartName;
      default:
        return "";
    }
  }

  compareDates(secondDate: string) {
    const date2 = new Date(secondDate);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return date2 < today
      ? date2.toJSON().slice(0, 10)
      : today.toJSON().slice(0, 10);
  }

  getSearchTypeForCustom() {
    const { startDate, endDate } = this.state;
    const usePreviousSearchType =
      (!this.state.startDate || !this.state.endDate) &&
      this.state.searchType === SearchType.custom;
    const previousOrActual = usePreviousSearchType
      ? this.state.previousSearchType
      : this.state.searchType;
    let value: {
      type: SearchType;
      label: string;
      subLabel: string;
    } = {
      type: previousOrActual,
      label: "",
      subLabel: ""
    };
    if (startDate && endDate) {
      const firstDate = new Date(startDate).getTime();
      const secondDate = new Date(endDate).getTime();
      const diffTime = Math.abs(secondDate - firstDate);
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      if (diffDays <= 1) {
        value = {
          type: SearchType.today,
          label: "Today’s overview",
          subLabel: "Yesterday’s "
        };
      } else if (diffDays <= 7) {
        value = {
          type: SearchType.weekly,
          label: "Weekly overview",
          subLabel: "Last week’s "
        };
      } else if (diffDays <= 30) {
        value = {
          type: SearchType.monthly,
          label: "Monthly overview",
          subLabel: "Last month's "
        };
      } else if (diffDays > 30) {
        value = {
          type: SearchType.monthly,
          label: "Custom overview",
          subLabel: "Last year's "
        };
      }
      this.state.previousSearchType !== value.type &&
        this.setState({ previousSearchType: value.type });
    }
    return value;
  }

  getShortXValue(array: ChartData[]) {
    if (this.getSearchTypeForCustom().subLabel !== "Last year's ") {
      return array;
    }
    const clone = [...array];
    return clone.map(e => {
      return { ...e, date: this.convertToAbbreviatedDate(e.date) };
    });
  }

  convertToAbbreviatedDate(dateString: string) {
    const monthMap: Record<string, string> = {
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dec"
    };

    const [month, year] = dateString.split(" ");

    return `${monthMap[month as keyof Record<string, string>]} ${year}`;
  }

  mainScreen() {
    return (
      <section className="main-container">
        <div>
          {this.header()}
          {this.overviewCard({
            title: "Total Overview",
            data: this.state.totalOverview
          })}
          {this.overviewCard({
            title: this.getLabelFromType(true),
            data: this.state.overview
          })}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              rowGap: "20px"
            }}
          >
            {this.charts({
              type: this.getSearchTypeForCustom().type,
              domain: this.getRangeFormat(this.state.totalOrders),
              bars: [
                {
                  color: "#059669",
                  label: "Orders",
                  dataKey: "current_record"
                },
                {
                  color: "#A2EBC6",
                  label: this.getLabelFromType(false, "orders"),
                  dataKey: "last_record"
                }
              ],
              data: this.state.totalOrders
                ? this.getShortXValue(this.state.totalOrders.data)
                : [],
              dataKey: "date",
              title: "Total Orders"
            })}
            {this.charts({
              type: this.getSearchTypeForCustom().type,
              domain: this.getRangeFormat(this.state.totalRevenues),
              bars: [
                {
                  color: "#F59E0B",
                  label: "Revenue",
                  dataKey: "current_record"
                },
                {
                  color: "#FEECA7",
                  label: this.getLabelFromType(false, "revenue"),
                  dataKey: "last_record"
                }
              ],
              data: this.state.totalRevenues
                ? this.getShortXValue(this.state.totalRevenues.data)
                : [],
              dataKey: "date",
              title: "Total Revenue"
            })}
            {this.charts({
              type: this.getSearchTypeForCustom().type,
              domain: this.getRangeFormat(this.state.totalCustomers),
              bars: [
                {
                  color: "#FD7100",
                  label: "Customers",
                  dataKey: "current_record"
                },
                {
                  color: "#FFC799",
                  label: this.getLabelFromType(false, "customers"),
                  dataKey: "last_record"
                }
              ],
              data: this.state.totalCustomers
                ? this.getShortXValue(this.state.totalCustomers.data)
                : [],
              dataKey: "date",
              title: "Total Customers"
            })}
            {this.charts({
              type: this.getSearchTypeForCustom().type,
              domain: this.getRangeFormat(this.state.totalActiveUsers),
              bars: [
                {
                  color: "#6200EA",
                  label: "Users",
                  dataKey: "current_record"
                },
                {
                  color: "#C399FF",
                  label: this.getLabelFromType(false, "users"),
                  dataKey: "last_record"
                }
              ],
              data: this.state.totalActiveUsers
                ? this.getShortXValue(this.state.totalActiveUsers.data)
                : [],
              dataKey: "date",
              title: "Active Users"
            })}
          </div>
        </div>
      </section>
    );
  }

  overviewCard({ title, data }: { title: string; data?: OverviewResponse }) {
    return (
      <div className="card-container">
        <Typography className="title">{title}</Typography>
        <div className="card-container__body">
          <div className="section-container">
            <div
              style={{
                width: 40,
                height: 40,
                padding: 13,
                background: "#D1FAE5",
                borderRadius: 12,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <div className="icon-container">
                <img src={chargeIcon} alt="" className="icon-overview" />
              </div>
            </div>
            <div
              style={{
                flex: "1 1 0",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 6,
                display: "inline-flex"
              }}
            >
              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#64748B",
                  fontSize: "0.688rem",
                  fontWeight: 400,
                  wordWrap: "break-word"
                }}
              >
                Total Orders
              </Typography>

              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#0F172A",
                  fontSize: "1rem",
                  fontWeight: 600,
                  wordWrap: "break-word"
                }}
              >
                {this.state.isLoading ? (
                  <Skeleton />
                ) : data ? (
                  formatNumber(data.total_orders)
                ) : (
                  0
                )}
              </Typography>
            </div>
          </div>
          <div className="section-container">
            <div
              style={{
                width: 40,
                height: 40,
                paddingTop: 13,
                paddingBottom: 13,
                paddingLeft: 12.75,
                paddingRight: 13.25,
                background: "rgba(253, 224, 190, 0.40)",
                borderRadius: 12,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <div className="icon-container">
                <img src={dollarIcon} alt="" className="icon-overview" />
              </div>
            </div>
            <div
              style={{
                flex: "1 1 0",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 6,
                display: "inline-flex"
              }}
            >
              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#64748B",
                  fontSize: "0.688rem",
                  fontWeight: 400,
                  wordWrap: "break-word"
                }}
              >
                Total Revenue
              </Typography>

              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#0F172A",
                  fontSize: "1rem",
                  fontWeight: 600,
                  wordWrap: "break-word"
                }}
              >
                {this.state.isLoading ? (
                  <Skeleton />
                ) : data ? (
                  formatNumber(data.total_revenue)
                ) : (
                  0
                )}
              </Typography>
            </div>
          </div>
          <div className="section-container">
            <div
              style={{
                width: 40,
                height: 40,
                paddingTop: 13,
                paddingBottom: 13,
                paddingLeft: 13.5,
                paddingRight: 12.5,
                background: "#FEE2E2",
                borderRadius: 12,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <div className="icon-container">
                <img src={userScanIcon} alt="" className="icon-overview" />
              </div>
            </div>
            <div
              style={{
                flex: "1 1 0",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 6,
                display: "inline-flex"
              }}
            >
              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#64748B",
                  fontSize: "0.688rem",
                  fontWeight: 400,
                  wordWrap: "break-word"
                }}
              >
                Total Customers
              </Typography>
              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#0F172A",
                  fontSize: "1rem",
                  fontWeight: 600,
                  wordWrap: "break-word"
                }}
              >
                {this.state.isLoading ? (
                  <Skeleton />
                ) : data ? (
                  formatNumber(data.total_account)
                ) : (
                  0
                )}
              </Typography>
            </div>
          </div>
          <div className="section-container">
            <div
              style={{
                width: 40,
                height: 40,
                paddingTop: 13,
                paddingBottom: 13,
                paddingLeft: 13.25,
                paddingRight: 12.75,
                background: "#F6F0FF",
                borderRadius: 12,
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <div className="icon-container">
                <img src={reportIcon} alt="" className="icon-overview" />
              </div>
            </div>
            <div
              style={{
                flex: "1 1 0",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 6,
                display: "inline-flex"
              }}
            >
              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#64748B",
                  fontSize: "0.688rem",
                  fontWeight: 400,
                  wordWrap: "break-word"
                }}
              >
                Active Users
              </Typography>
              <Typography
                style={{
                  alignSelf: "stretch",
                  color: "#0F172A",
                  fontSize: "1rem",
                  fontWeight: 600,
                  wordWrap: "break-word"
                }}
              >
                {this.state.isLoading ? (
                  <Skeleton />
                ) : data ? (
                  formatNumber(data.total_active_user)
                ) : (
                  0
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }

  charts({
    bars,
    title,
    type,
    dataKey,
    data,
    domain
  }: {
    type: SearchType;
    title: string;
    bars: { label: string; color: string; dataKey: string }[];
    dataKey: string;
    data: any[];
    domain?: number[];
  }) {
    return (
      <div
        className="chart-container"
        style={{
          flexBasis: type === SearchType.monthly ? "100%" : "calc(50% - 55px)"
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            justifyContent: "space-between",
            alignItems: "center",
            display: "inline-flex"
          }}
        >
          <Typography
            style={{
              color: "#0F172A",
              fontSize: "0.875rem",
              fontWeight: 600,
              wordWrap: "break-word"
            }}
          >
            {title}
          </Typography>
          <div
            style={{
              width: 24,
              height: 24,
              position: "relative"
            }}
          >
            <IoEllipsisHorizontal />
          </div>
        </div>
        <div
          className={type === SearchType.monthly ? "chart-month" : "chart-week"}
          style={
            type === SearchType.monthly
              ? { width: "110%", height: 300, marginLeft: "-85px" }
              : { width: "120%", height: 300, marginLeft: "-80px" }
          }
        >
          {this.state.isLoading ? (
            <Skeleton
              variant="rect"
              style={{ marginLeft: "80px", borderRadius: 10 }}
              height={300}
            />
          ) : (
            <ResponsiveContainer>
              {/* @ts-ignore */}
              <BarChart
                width={350}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 65,
                  bottom: 5
                }}
                barGap={type === SearchType.today ? -200 : undefined}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKey} interval={0} />
                <YAxis
                  type="number"
                  domain={domain}
                  allowDataOverflow
                  tick={props => <CustomYAxisTick {...props} />}
                />
                <Tooltip
                  content={props => <CustomTooltip {...props} bars={bars} />}
                />
                <Legend
                  payload={bars.map(bar => ({
                    value: bar.label,
                    type: "circle",
                    color: bar.color,
                    id: bar.dataKey
                  }))}
                />
                {bars.map(bar => (
                  <Bar
                    dataKey={bar.dataKey}
                    fill={bar.color}
                    radius={[10, 10, 0, 0]}
                    maxBarSize={15}
                    activeBar={
                      <Rectangle
                        radius={[10, 10, 0, 0]}
                        fill={bar.color}
                        stroke="#CBD5E1"
                      />
                    }
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    );
  }

  getMaxData(initialDate: string) {
    const todayDate = new Date();
    const initDate = new Date(initialDate);
    initDate.setFullYear(initDate.getFullYear() + 1);

    todayDate.setHours(0, 0, 0, 0);
    initDate.setHours(0, 0, 0, 0);

    return initDate < todayDate
      ? initDate.toJSON().slice(0, 10)
      : todayDate.toJSON().slice(0, 10);
  }

  header() {
    const username =
        (this.state.userInfo && this.state.userInfo.full_name) || "",
      todayDate = new Date().toJSON().slice(0, 10);
    return (
      <div className="header-container">
        <div className="title">
          <Typography
            style={{
              color: "#0F172A",
              fontSize: "1.25rem",
              fontWeight: 600,
              letterSpacing: 0.22,
              wordWrap: "break-word"
            }}
          >
            👋 Hi {username},
          </Typography>
          <Typography
            style={{
              color: "#475569",
              fontSize: "0.875rem",
              fontWeight: 400,
              letterSpacing: 0.16,
              wordWrap: "break-word"
            }}
          >
            Here’s your updates when you were away
          </Typography>
        </div>
        {this.state.searchType === SearchType.custom && (
          <div className="dates-container">
            <DateInput
              id="startDate"
              data-test-id="startDateTestId"
              name="startDate"
              style={{ height: "100%" }}
              value={this.state.startDate}
              inputProps={{
                style: {
                  borderBottom: "none",
                  height: 36
                },
                inputProps: {
                  max: this.state.endDate
                    ? this.compareDates(this.state.endDate)
                    : todayDate
                }
              }}
              onChange={e => this.handleDateChange(e)}
            />
            <Typography
              style={{ color: "black", alignSelf: "center", fontWeight: 500 }}
            >
              To
            </Typography>
            <DateInput
              id="endDate"
              data-test-id="endDateTestId"
              name="endDate"
              disabled={!this.state.startDate}
              style={{ height: "100%" }}
              value={this.state.endDate}
              inputProps={{
                style: {
                  borderBottom: "none",
                  height: 36
                },
                inputProps: {
                  min: this.state.startDate,
                  max: this.getMaxData(this.state.startDate || "")
                }
              }}
              onChange={e => this.handleDateChange(e)}
              onBlur={() => this.setDateOnBlur()}
            />
          </div>
        )}
        <div className="select-container">
          <CustomSelect
            disableUnderline
            name="date_type"
            data-test-id="dateTypeTestId"
            onChange={e => this.handleTypeChange(e)}
            IconComponent={IoIosArrowDown}
            style={{
              color: "#0F172A",
              fontWeight: 500,
              letterSpacing: 0.13,
              wordWrap: "break-word"
            }}
            value={this.state.searchType}
          >
            {["today", "weekly", "monthly", "custom"].map(option => (
              <MenuItem key={option} value={option}>
                <ListItemIcon
                  style={{
                    minWidth: "unset",
                    marginRight: "10px",
                    color: this.setListItemColor(option)
                  }}
                >
                  <IoIosCalendar />
                </ListItemIcon>
                <ListItemText
                  primary={this.handleOption(option)}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "13px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      color: this.setListItemColor(option)
                    }
                  }}
                />
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </div>
    );
  }

  // Customizable Area End
}
// Customizable Area Start
const formatNumber = (value: number) => {
  return value.toLocaleString("en-US");
};

export const CustomYAxisTick = ({ x, y, payload }: any) => {
  let formattedValue = payload.value;

  if (formattedValue >= 1000000) {
    formattedValue =
      (formattedValue / 1000000).toFixed(1).replace(/\.0$/, "") + "m";
  }
  if (formattedValue >= 1000) {
    formattedValue =
      (formattedValue / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {formattedValue}
      </text>
    </g>
  );
};

export const CustomTooltip = ({ active, payload, label, bars }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          boxSizing: "border-box",
          background: "white",
          borderRadius: 8,
          color: "rgb(15, 23, 42)",
          minWidth: 225,
          fontSize: "0.625rem",
          display: "flex",
          flexDirection: "column",
          boxShadow:
            "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
        }}
      >
        <div
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            padding: 10,
            background: "#F1F5F9",
            borderBottom: "1px solid #E2E8F0"
          }}
        >
          {label}
        </div>
        {payload.map((pl: any) => (
          <div
            style={{
              padding: 10,
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
              boxSizing: "border-box"
            }}
          >
            <div>
              {pl.name === "current_record" ? bars[0].label : bars[1].label}
            </div>
            <div>
              <b>{formatNumber(pl.value)}</b>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const CustomSelect = styled(Select)({
  "& .MuiSelect-selectMenu": {
    display: "inline-flex",
    alignItems: "center",
    gap: "0.3rem",
    width: "200px"
  },
  "& .MuiListItemIcon-root": {
    minWidth: "unset"
  }
});
// Customizable Area End
