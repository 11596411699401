import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    withStyles,
    Theme,
    IconButton,
    FormControl,
    InputAdornment,
    TextField
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { configJSON, Props } from "./ContactusController";
import HelpCenterHeaderWeb from "../../../components/src/HelpCenterHeader.web";
import HeaderWeb from "../../../components/src/Header.web";
import { blueCircle } from "../../TermsAndConditions/src/assets";
import { facebookIcon, instagramIcon, telegramIcon,  customerService, youtubeIcon } from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import { FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { InputBox, OneColBox, TwoColBox } from "../../../components/src/GenericInputs";
import { CreateButton } from "../../user-profile-basic/src/Achievements.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: '#fff',
        padding: theme.spacing(4, 0),
        textAlign: 'center' as const,
    },
    contactusContainer: {
        margin: '50px auto',
        maxWidth: 1330, 
        width: 'calc(100% - 75px)',
        position: 'relative' as const,
        marginBottom: 100,
        '@media (min-width: 1441px) and (max-width: 7100px)': {
            maxWidth: 1440, 
        }
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(3, 0),
        color: '#fff',
        marginBottom: theme.spacing(4),
    },
    icon: {
        margin: theme.spacing(1),
    },
    contactDetails: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    socialMedia: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: "column" as const
    },
    footer: {
        backgroundColor: '#f5f5f5',
        padding: theme.spacing(4),
        marginTop: theme.spacing(6),
    },
    footerText: {
        color: '#666',
    },
    iconButton: {
        color: '#000 !important',
        width: "fit-content",
        "& .MuiIconButton-label": {
            gap: "26px"
        }
    },
    contact_address: {
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#000000',
        display: "flex",
        gap: "16px",
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            fontSize: '20px',
        },
        '@media (min-width: 1281px) and (max-width: 1367px)': {
            fontSize: '18px',
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            fontSize: '18px',
        },
    },
    contact_details: {
        color: "#979797",
    },
    contact_label: {
        width: "90px",
        fontFamily: 'Inter',
        fontWeight: 500,
        display: "flex",
        gap: "16px",
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            fontSize: '20px',
        },
        '@media (min-width: 1281px) and (max-width: 1367px)': {
            fontSize: '18px',
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            fontSize: '18px',
        },
    },
    telegramIcon: {
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            heigth: '30px',
            width: '40px'
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            heigth: '30px',
            width: '35px'
        },
    },
    facebookIcon: {
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            heigth: '50px',
            width: '50px'
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            heigth: '40px',
            width: '40px'
        },
    },
    followus_option: {
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#323232',
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            fontSize: '25.57px',
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            fontSize: '20.57px',
        },
    },
    contanctus_heading: {
        fontWeight: 600,
        fontFamily: 'Inter',
        lineHeight: '44px',
        color: '#212121',
        '@media (min-width: 1366px) and (max-width: 1920px)': {
            fontSize: '28px',
        },
        '@media (min-width: 1281px) and (max-width: 1367px)': {
            fontSize: '23px',
        },
        '@media (min-width: 600px) and (max-width: 1280px)': {
            fontSize: '23px'
        },
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

class ContactusWeb extends ContactusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
    // Customizable Area Start
        const { classes } = this.props;
        const { contactUsWeb } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <NotificationAlertMessage
                    dataTestId="alertTestId"
                    alertType={this.state.alertType}
                    msg={this.state.alertMsg}
                    onClose={this.oncloseAlert}
                    isOpen={this.state.isAlert}
                />
                <HeaderWeb navigation={this.props.navigation} id={""} />
                <div id="scrollContainer">
                <HelpCenterHeaderWeb
                    title={configJSON.headingTitle}
                    subTitle={configJSON.headingSubTitle}
                />
                    <Box className={classes.contactusContainer}>
                        <Box width={'446px'} height={"446px"} style={webStyles.blueImage}>
                            <img src={blueCircle} />
                        </Box>
                        <Box width={'446px'} height={"446px"}
                            style={{
                                position: "absolute",
                                bottom: -60,
                                right: 35,
                                zIndex: -2
                            }}
                        >
                            <img src={blueCircle} />
                        </Box>
                        <Box style={webStyles.contentContainer}>
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12} sm={8} style={{ display: 'flex', alignItems: "center" }}>
                                    <Box>
                                        <Box mb={6}>
                                            <OneColBox className="align-column" style={{ borderBottom: "none" }}>
                                                <Box className="child__box align-column">
                                                    <Typography className="box__label">Full Name</Typography>
                                                    <FormControl variant="outlined" fullWidth className="box__input">
                                                        <InputBox
                                                            data-test-id="fullNameTestId"
                                                            variant="outlined"
                                                            fullWidth
                                                            name="fullName"
                                                            placeholder="Ramesh"
                                                            onChange={e => this.handleInputChange(e)}
                                                            value={this.state.fullName}
                                                        />
                                                        <span style={webStyles.errorText} data-test-id="fullNameError">
                                                            {this.state.fullNameError}
                                                        </span>
                                                    </FormControl>
                                                </Box>
                                            </OneColBox>
                                            <TwoColBox  style={{ borderBottom: "none" }}>
                                                <Box className="child__box align-column">
                                                    <Typography className="box__label">Email Address</Typography>
                                                    <FormControl variant="outlined" fullWidth className="box__input">
                                                        <InputBox
                                                            data-test-id="emailTestId"
                                                            variant="outlined"
                                                            fullWidth
                                                            name="contactEmail"
                                                            placeholder="rameshuat@yopmail.com"
                                                            onChange={e => this.handleInputChange(e)}
                                                            value={this.state.contactEmail}
                                                        />
                                                        <span style={webStyles.errorText}>{this.state.emailError}</span>
                                                    </FormControl>
                                                </Box>
                                                <Box className="child__box align-column">
                                                    <Typography className="box__label">Phone Number</Typography>
                                                    <FormControl variant="outlined" fullWidth className="box__input">
                                                        <InputAdornmentField
                                                            data-test-id="phoneNumberId"
                                                            variant="outlined"
                                                            fullWidth
                                                            name="contactPhoneNumber"
                                                            placeholder="1234567890"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">+91</InputAdornment>
                                                                )
                                                            }}
                                                            onChange={(e: { target: { value: string; name: string; }; }) => this.handleInputChange(e)}
                                                            value={this.state.contactPhoneNumber}
                                                        />
                                                        <span
                                                            style={webStyles.errorText}
                                                            data-test-id="phone_number_err"
                                                        >
                                                            {this.state.phoneNumberError}
                                                        </span>
                                                    </FormControl>
                                                </Box>
                                            </TwoColBox>
                                            <OneColBox className="align-column" style={{ borderBottom: "none" }}>
                                                <Box className="child__box align-column">
                                                    <Typography className="box__label">Query</Typography>
                                                    <FormControl variant="outlined" fullWidth className="box__input">
                                                        <MultiLineTextField
                                                            data-test-id="descriptionTestId"
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            name="description"
                                                            onChange={e => this.handleInputChange(e)}
                                                            value={this.state.description}
                                                        />
                                                        <span style={webStyles.errorText} data-test-id="fullNameError">
                                                            {this.state.descriptionError}
                                                        </span>
                                                    </FormControl>
                                                </Box>
                                            </OneColBox>
                                            <CreateButton
                                                data-test-id="submitButtonId"
                                                onClick={() => this.handleSubmitContact()}
                                            >
                                                Submit
                                            </CreateButton>
                                        </Box>
                                        <Box display={'flex'} flexDirection={'column'} style={{ gap: 24, maxWidth: "561px" }}>
                                            <Typography data-test-id="chseExpress_title" variant="h6" className={classes.contanctus_heading}>{configJSON.chseExpressText}</Typography>
                                            <Typography variant="body1" className={classes.contact_address}>
                                                <strong className={classes.contact_label}>{configJSON.addressText}:</strong> <span className={classes.contact_details}>{contactUsWeb.address}</span>
                                            </Typography>
                                            <Typography variant="body1" className={classes.contact_address}>
                                                <strong className={classes.contact_label}>{configJSON.mobileText}:</strong> <span className={classes.contact_details}>{contactUsWeb.full_phone_number}</span>
                                            </Typography>
                                            <Typography variant="body1" className={classes.contact_address}>
                                                <strong className={classes.contact_label}>{configJSON.emailText}:</strong> <span className={classes.contact_details}>{contactUsWeb.email}</span>
                                                </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4} className={classes.socialMedia}>
                                    <Box display={'flex'} flexDirection={'column'} style={{ gap: 21 }}>
                                        <IconButton className={classes.iconButton} data-test-id="Facebook" aria-label="Facebook" onClick={() => this.onSocialClick(configJSON.facebookUrl)}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={"center"} style={{ width: "50px" }}>
                                                <img src={facebookIcon} className={classes.facebookIcon} />
                                            </Box>
                                            <Typography className={classes.followus_option}>{configJSON.facebook}</Typography>
                                        </IconButton>
                                        <IconButton className={classes.iconButton} data-test-id="WhatsApp" aria-label="WhatsApp" onClick={() => this.onSocialClick(configJSON.whatsAppUrl)}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={"center"} style={{ width: "50px" }}>
                                                <FaWhatsapp style={{ width: 35, height: 35 }} />
                                            </Box>
                                            <Typography className={classes.followus_option}>{configJSON.whatsapp}</Typography>
                                        </IconButton>
                                        <IconButton className={classes.iconButton} data-test-id="Telegram" aria-label="Telegram" onClick={() => this.onSocialClick(configJSON.telegramUrl)}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={"center"} style={{ width: "50px" }}>
                                                <img src={telegramIcon} className={classes.telegramIcon} />
                                            </Box>
                                            <Typography className={classes.followus_option}>{configJSON.telegram}</Typography>
                                        </IconButton>
                                        <IconButton className={classes.iconButton} data-test-id="Twitter" aria-label="Twitter" onClick={() => this.onSocialClick(configJSON.twitterUrl)}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={"center"} style={{ width: "50px" }}>
                                                <FaXTwitter style={{ width: 35, height: 35 }} />
                                            </Box>
                                            <Typography data-test-id="followus_option" className={classes.followus_option}>{configJSON.twitter}</Typography>
                                        </IconButton>
                                        <IconButton className={classes.iconButton} data-test-id="Instagram" aria-label="Instagram" onClick={() => this.onSocialClick(configJSON.instagramUrl)}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={"center"} style={{ width: "50px" }}>
                                                <img src={instagramIcon} className={classes.facebookIcon} />
                                            </Box>
                                            <Typography className={classes.followus_option}>{configJSON.instagram}</Typography>
                                        </IconButton>
                                        <IconButton className={classes.iconButton} data-test-id="YouTube" aria-label="YouTube" onClick={() => this.onSocialClick(configJSON.youTubeUrl)}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={"center"} style={{ width: "50px" }}>
                                                <img src={youtubeIcon} className={classes.facebookIcon} />
                                            </Box>
                                            <Typography className={classes.followus_option}>{configJSON.youtube}</Typography>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                <FooterWeb navigation={this.props.navigation} id={""} />
                </div>
            </ThemeProvider>
        );
    // Customizable Area End

    }
}

// Customizable Area Start
const InputAdornmentField = styled(InputBox)({
    "& .MuiOutlinedInput-input": {
      paddingLeft: 0
    }
  });

  const MultiLineTextField = styled(TextField)({
    '& .MuiInputBase-input': { 
        height: '90px',
        boxSizing: 'border-box',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': { 
        borderColor: '#3F6474' 
    },
    '& .MuiInputBase-inputMultiline': {
        height: '60px !important',
    }
    },
  });

const webStyles = {
    contentContainer: {
        borderRadius: '23px',
        padding: '40px 80px',
        boxShadow: '0px 1px 8px 0px #00000025',
        background: "#FFFFFF",
        minHeight: 450,
        maxWidth: 1440,
        boxSizing: 'border-box' as const,
        margin: '0px auto'
    },
    blueImage: {
        position: 'absolute' as const,
        left: -25,
        top: 10,
        opacity: 0.8,
        zIndex: -2,
        '@media (min-width: 900px) and (max-width: 1400px)' :{
            left: 10,
        }
    },
    errorText: {
        color: "red",
        fontSize: "0.625rem",
    },
};
export default withStyles(styles)(ContactusWeb);
export {ContactusWeb}
// Customizable Area End
