// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "./VouchercodegeneratorController";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import {
  navigationLoginScreen,
  parseErrors
} from "./FlatDiscountController.web";

export const configJSON = require("./config");
export const images = require("./assets");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface MyCashResponse {
  data: Datum[];
  page: string;
  total_points: number;
}

export interface Datum {
  id: number;
  discount: any;
  created_at: Date;
  updated_at: Date;
  redeem_code?: string;
  coupon_name?: string;
  coupon_code?: string;
  expiry_date: Date;
  applicable_on?: number;
  status: boolean;
  discount_type: DiscountType;
  is_expire: boolean;
  get_expiry_date: string;
  is_used: boolean;
  get_created_at: string;
  discount_name?: string;
  discount_code?: string;
  minimum_purchase?: number;
  points: number;
  terms_n_condition: string;
  isCouponCopied: boolean;
}

export enum DiscountType {
  Amount = "amount",
  Percentage = "percentage"
}

interface S {
  // Customizable Area Start
  userToken: any;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isLoading: boolean;
  couponsAndDiscountsList: Datum[];
  totalAmount: number;
  page: number;
  perPage: number;
  loadMoreDisabled: boolean;
  username: string;
  profilePic: string;
  phoneNumber: string;
  currentTab: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MyCashController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCashDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      page: 1,
      perPage: elementsPerPage,
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      isLoading: false,
      phoneNumber: "",
      userToken: "",
      couponsAndDiscountsList: [],
      totalAmount: 0,
      loadMoreDisabled: false,
      username: "",
      profilePic: "",
      currentTab: 0
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        if (apiRequestCallId === this.getCashDetailsApiCallId) {
          this.handleGetCashResponse(responseJson);
        }
      } else {
        parseErrors(
          responseJson,
          this.parseApiErrorResponse,
          this.send,
          this.props
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async handleUserToken() {
    const token = await getStorageData("loginToken");
    const username: string = await getStorageData("profileUsername"),
      profilePic: string = await getStorageData("profilePic"),
      phoneNumber: string = await getStorageData("profilePhoneNumber");
    if (token) {
      this.setState({
        userToken: token,
        profilePic: profilePic,
        username: username.split(" ")[0],
        phoneNumber: phoneNumber
      });
      this.getMyCashList();
    } else {
      navigationLoginScreen(this.props, this.send);
    }
  }

  getMyCashList() {
    this.setState({ isLoading: true });
    this.getCashDetailsApiCallId = apiCall({
      endPoint:
        configJSON.myCashApiEndpoint + ["my_cashes", "my_cash_list"][this.state.currentTab] +
        `?page=${this.state.page}&per_page=${this.state.perPage}`,
      method: configJSON.apiMethodTypeCouponGet,
      token: this.state.userToken,
      contentType: configJSON.validationApiContentType
    });
  }

  oncloseAlert() {
    this.setState({
      isAlert: false
    });
  }

  loadMore() {
    this.setState({ page: this.state.page + 1 }, () => this.getMyCashList());
  }

  handleGetCashResponse(responseJson: MyCashResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        couponsAndDiscountsList: [
          ...this.state.couponsAndDiscountsList,
          ...responseJson.data.map(resData => ({
            ...resData,
            isCouponCopied: false,
          }))
        ],
        loadMoreDisabled: responseJson.data.length < elementsPerPage,
        isLoading: false,
        totalAmount: responseJson.total_points
      });
    } else {
      this.setState({ isLoading: false, loadMoreDisabled: true });
    }
  }

  conditionalCheck = (condition: boolean, ifValue: number | string, elseValue: number | string) => {
    return condition ? ifValue : elseValue;
  }

  handleTabChange = (value: number) => {
    this.setState({
      currentTab: value,
      couponsAndDiscountsList: []
    }, () => this.getMyCashList())
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
