import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { customAlert, getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import {
  defaultValues,
  apiCall,
  validationsLoop
} from "./FooterController.web";
export const configJSON = require("./config");

const numericRegex = /^\d+$/;
interface ResponseErrors {
  errors: {
    token: string;
  }[];
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface AchievementsResponse {
  data: AchievementsData;
  errors: any[];
  message: string;
}

export interface AchievementsData {
  total_followers: string;
  certified_teachers: string;
  online_courses: string;
  student_enrolled: string;
}

export interface Meta {
  message: string;
  page: string;
  total_testimonial: number;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  totalFollowers: string;
  certifiedTeachers: string;
  onlineCourses: string;
  studentEnrolled: string;
  totalFollowersError: string;
  certifiedTeachersError: string;
  onlineCoursesError: string;
  studentEnrolledError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AchievementsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAchievementsListApiCallId: string = "";
  submitAchievementApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      ...defaultValues,
      totalFollowers: "",
      certifiedTeachers: "",
      onlineCourses: "",
      studentEnrolled: "",
      totalFollowersError: "",
      certifiedTeachersError: "",
      onlineCoursesError: "",
      studentEnrolledError: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getAchievementsData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getAchievementsListApiCallId:
            this.handleGetAchievementsResponse(responseJson);
            break;
          case this.submitAchievementApiCallId:
            this.handleSubmitAchievementResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };
  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleGetAchievementsResponse(resJson: AchievementsResponse) {
    if (
      resJson.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      this.navigationLoginScreen();
    } else {
      this.setState({
        onlineCourses: resJson.data.online_courses,
        studentEnrolled: resJson.data.student_enrolled,
        certifiedTeachers: resJson.data.certified_teachers,
        totalFollowers: resJson.data.total_followers,
        isLoading: false
      });
    }
  }

  getAchievementsData() {
    this.setState({ isLoading: true });
    this.getAchievementsListApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAchievementsApiEndpoint,
      token: this.state.userToken
    });
  }

  checkFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        field: "totalFollowers",
        errorKey: "totalFollowersError",
        errorMessage: "Please enter a valid  total followers",
        regex: numericRegex
      },
      {
        field: "certifiedTeachers",
        errorKey: "certifiedTeachersError",
        errorMessage: "Please enter a valid certified teachers",
        regex: numericRegex
      },
      {
        field: "onlineCourses",
        errorKey: "onlineCoursesError",
        errorMessage: "Please enter a valid online courses",
        regex: numericRegex
      },
      {
        field: "studentEnrolled",
        errorKey: "studentEnrolledError",
        errorMessage: "Please enter a valid student enrolled",
        regex: numericRegex
      }
    ];

    return validationsLoop(validationFields, updatedValues, value =>
      this.setState(value)
    );
  }

  handleSubmitAchievement() {
    const {
      certifiedTeachers,
      onlineCourses,
      totalFollowers,
      studentEnrolled
    } = this.state;
    const formValues = {
      certifiedTeachers,
      onlineCourses,
      totalFollowers,
      studentEnrolled
    };
    if (!this.checkFormValidations(formValues)) {
      this.setState({ isLoading: true });
      this.submitAchievementApiCallId = apiCall({
        method: configJSON.apiUpdateUserType,
        endPoint: configJSON.updateAchievementsApiEndpoint,
        token: this.state.userToken,
        contentType: configJSON.validationApiContentType,
        body: {
          data: {
            certified_teachers: certifiedTeachers,
            total_followers: totalFollowers,
            online_courses: onlineCourses,
            student_enrolled: studentEnrolled
          }
        }
      });
    }
  }

  handleSubmitAchievementResponse(response: AchievementsResponse) {
    if (response.message && response.data) {
      const {
        certified_teachers,
        online_courses,
        student_enrolled,
        total_followers
      } = response.data;
      this.setState({
        alertMsg: response.message,
        alertType: "success",
        isAlert: true,
        isLoading: false,
        certifiedTeachers: certified_teachers,
        onlineCourses: online_courses,
        studentEnrolled: student_enrolled,
        totalFollowers: total_followers
      });
    } else if (
      response.errors &&
      response.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      this.navigationLoginScreen();
    }
  }

  handleInputChange(
    { target }: { target: { value: string; name: string } },
    title: string
  ) {
    const { name, value } = target;

    this.setState({
      [name]: value,
      [`${name}Error`]: !numericRegex.test(value)
        ? "Please enter a valid " + title
        : ""
    } as any);
  }

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  handleResetAchievement() {
    this.setState({
      totalFollowers: "",
      certifiedTeachers: "",
      onlineCourses: "",
      studentEnrolled: "",
      totalFollowersError: "",
      certifiedTeachersError: "",
      onlineCoursesError: "",
      studentEnrolledError: ""
    });
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
