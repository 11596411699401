import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Box,
  Chip,
  Button,
  styled,
  Fade,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  withStyles,
  Tabs,
  Tab,
  Theme,
  createStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FaRegCheckCircle, FaRupeeSign } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyCashController, {
  Datum,
  DiscountType,
  images,
  Props
} from "./MyCashController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import UserSidebarMenu from "../../../components/src/UserSideBarMenu";
import { userProfileStyles } from "../../user-profile-basic/src/UserProfile.web";
import Footer from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

// Customizable Area End

export default class MyCash extends MyCashController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  async copyTextToClipboard(id: number, code: string | undefined) {
    try {
      await navigator.clipboard.writeText(code || "");
      this.setState({
        isAlert: true,
        alertMsg: `${id}`,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard: " + code,
        alertType: "error"
      });
    }
  }

  async handleRedeemClick(item: Datum) {
    this.setState({
      couponsAndDiscountsList: this.state.couponsAndDiscountsList.map(couponList => ({
        ...couponList,
        isCouponCopied: couponList.id === item.id
      }))
    })
    const code = item.coupon_code || item.redeem_code;
    await this.copyTextToClipboard(item.id, code);
  }

  getButtonName = (itemData: Datum) => {
    if (itemData.is_used) {
      return "Redeemed"
    }
    if (itemData.is_expire) {
      return "Expired"
    }
    if (itemData.isCouponCopied) {
      return "Code Copied"
    }
    return "Redeem Now"
  }

  renderCouponTable() {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        {this.state.isLoading ? (
            <CircularProgress
              style={{ margin: "auto", color: "#2a9e6d" }}
            />
          ) : (
            <>
              <Table aria-label="simple table">
                <TableBody>
                  {this.state.couponsAndDiscountsList.length 
                    ? this.state.couponsAndDiscountsList.map((item, index) => {
                        return (
                          <>
                            <TableRow data-test-id="tableRow" key={index} style={{ borderBottom: "none" }}>
                              <TableCell style={{ width: "200px", borderBottom: "none" }}>
                                <Chip
                                  label={item.coupon_code}
                                  style={{
                                    textTransform: "capitalize",
                                    background: this.conditionalCheck(item.discount_type === DiscountType.Amount, "#09A06D", "#0E599D"),
                                    opacity: this.conditionalCheck(item.is_expire, 0.5, 1),
                                    borderRadius: 6,
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    padding: "13px",
                                    minWidth: "170px",
                                    height: "50px"
                                  }}
                                  variant="default"
                                />
                              </TableCell>
                              <TableCell style={{ width: "250px", borderBottom: "none" }}>
                                <Typography className="offText">
                                  {`
                                    ${this.conditionalCheck(item.discount_type === DiscountType.Amount, "₹", "")}
                                    ${this.conditionalCheck(item.discount_type === DiscountType.Percentage,
                                      Number(item.discount),
                                      Number(item.discount).toFixed(2)
                                    )}
                                    ${this.conditionalCheck(item.discount_type === DiscountType.Percentage, "%", "")}
                                    OFF
                                  `}
                                </Typography>
                                <Typography className="expiringOnText">Expiring on {item.get_expiry_date}</Typography>
                              </TableCell>
                              <TableCell style={{ borderBottom: "none" }}>
                                <div className="htmlDivText" dangerouslySetInnerHTML={{ __html: item.terms_n_condition || "-" }} />
                              </TableCell>
                              <TableCell style={{ borderBottom: "none", textAlign: 'end' }}>
                                <RedeemButton
                                  variant="outlined"
                                  data-test-id="redeemButtonTestId"
                                  disabled={item.is_expire || item.is_used}
                                  onClick={async () =>
                                    await this.handleRedeemClick(item)
                                  }
                                >
                                  {this.getButtonName(item)}
                                </RedeemButton>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ padding: 0, margin: 0 }}>
                              <TableCell
                                style={{ padding: 0, margin: 0 }}
                                colSpan={5}
                              >
                                <Fade
                                  data-test-id="fadeAlertTestId"
                                  in={
                                    this.state.isAlert &&
                                    this.state.alertMsg === `${item.id}`
                                  }
                                  mountOnEnter
                                  unmountOnExit
                                  style={{ marginBottom: "10px", padding: "0px" }}
                                >
                                  <Alert
                                    variant="filled"
                                    iconMapping={{
                                      success: <FaRegCheckCircle fontSize="inherit" />
                                    }}
                                    severity="success"
                                    style={{
                                      backgroundColor: "#EDFEDA",
                                      color: "#09A06D",
                                      alignItems: "center",
                                      paddingLeft: "10px"
                                    }}
                                  >
                                    Awesome! Your Coupon Code has been copied! Paste it in the “Apply coupon code” field at checkout.
                                  </Alert>
                                </Fade>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    : <div className="noDataScreenDiv">
                        <img src={images.noDataImage} />
                        <Typography className="noDataScreenText">No Coupons yet</Typography>
                      </div>
                  }
                </TableBody>
              </Table>
              <div style={{ display: "flex", padding: "1rem" }}>
                <Button
                  data-test-id="loadMoreButtonTestId"
                  variant="outlined"
                  style={{
                    margin: "auto",
                    color: "#09A06D",
                    textTransform: "none",
                    border: "1px solid #09A06D",
                    display: this.conditionalCheck(this.state.loadMoreDisabled, "none", "unset") as "none" | "unset"
                  }}
                  disabled={this.state.loadMoreDisabled || this.state.isLoading}
                  onClick={() => this.loadMore()}
                >
                  Load More
                </Button>
              </div>
            </>
        )}
      </div>
    )
  }

  renderRewardCashTable() {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "30px" }}>
        {this.state.isLoading ? (
            <CircularProgress
              style={{ margin: "auto", color: "#2a9e6d" }}
            />
          ) : (
            <>
              {this.state.couponsAndDiscountsList.length 
                ? <>
                    <div style={webStylesLocal.cashContainer}>
                      <div style={webStylesLocal.cashDisplay}>
                        <div>
                          <FaRupeeSign style={{ color: "black" }} />
                        </div>
                        <span>
                          <strong>{this.state.totalAmount.toFixed(2)}</strong>
                        </span>
                      </div>
                      <div>
                        <p style={webStylesLocal.cashText}>
                          Your total available My Cash units
                        </p>
                      </div>
                    </div>
                    <Table aria-label="simple table">
                      <TableBody>
                        {
                          this.state.couponsAndDiscountsList.map((item, index) => {
                            return (
                              <>
                                <TableRow data-test-id="tableRow" key={index} style={{ borderBottom: "none" }}>
                                  <TableCell style={{ width: "170px", borderBottom: "none" }}>
                                    <Chip
                                      label="Referral"
                                      style={{
                                        background: "#F59E0B",
                                        borderRadius: 6,
                                        color: "white",
                                        fontSize: "20px",
                                        fontWeight: 700,
                                        padding: "13px",
                                        minWidth: "170px",
                                        height: "50px"
                                      }}
                                      variant="default"
                                    />
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "none" }}>
                                    <Typography className="offText">
                                      You’ve successfully earned ₹{item.points}
                                    </Typography>
                                    <Typography className="expiringOnText">Expiring on {item.get_expiry_date}</Typography>
                                  </TableCell>
                                  <TableCell style={{ width: "100px", borderBottom: "none" }}>
                                    <Typography className="createdOnText">Date</Typography>
                                    <Typography className="createdOnText"><b>{item.get_created_at}</b></Typography>
                                  </TableCell>
                                  <TableCell style={{ width: "120px", borderBottom: "none", textAlign: 'end' }}>
                                    <RedeemButton
                                      variant="outlined"
                                      data-test-id="redeemButtonTestId"
                                      onClick={async () =>
                                        await this.handleRedeemClick(item)
                                      }
                                      disabled={item.is_expire || item.is_used}
                                    >
                                      {this.getButtonName(item)}
                                    </RedeemButton>
                                  </TableCell>
                                </TableRow>
                                <TableRow style={{ padding: 0, margin: 0 }}>
                                  <TableCell
                                    colSpan={5}
                                    style={{ padding: 0, margin: 0 }}
                                  >
                                    <Fade
                                      in={
                                        this.state.isAlert &&
                                        this.state.alertMsg === `${item.id}`
                                      }
                                      data-test-id="fadeAlertTestId"
                                      unmountOnExit
                                      mountOnEnter
                                      style={{ marginBottom: "10px", padding: "0px" }}
                                    >
                                      <Alert
                                        variant="filled"
                                        severity="success"
                                        iconMapping={{
                                          success: <FaRegCheckCircle fontSize="inherit" />
                                        }}
                                        style={{
                                          backgroundColor: "#EDFEDA",
                                          color: "#09A06D",
                                          alignItems: "center",
                                          paddingLeft: "10px"
                                        }}
                                      >
                                        Awesome! Your Redeem Code has been copied! Paste it in the “Apply reward code” field at checkout.
                                      </Alert>
                                    </Fade>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        }
                      </TableBody>
                    </Table>
                  </> 
                : <div className="noDataScreenDiv">
                    <img src={images.rewardNoDataImage} />
                    <Typography className="noDataScreenText">No Reward cash yet</Typography>
                  </div>
              }
              <div style={{ display: "flex", padding: "1rem" }}>
                <Button
                  variant="outlined"
                  data-test-id="loadMoreButtonTestId"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                    color: "#09A06D",
                    display: this.conditionalCheck(this.state.loadMoreDisabled, "none", "unset") as "none" | "unset",
                    border: "1px solid #09A06D",
                  }}
                  onClick={() => this.loadMore()}
                  disabled={this.state.loadMoreDisabled || this.state.isLoading}
                >
                  Load More
                </Button>
              </div>
            </>
          )}
      </div>
    )
  }

  renderList() {
    return (
      <StyledBox style={userProfileStyles.mainLayout}>
        <SideBox>
          <UserSidebarMenu
            userPic={this.state.profilePic}
            fullName={this.state.username}
            pathname={window.location.pathname}
            phoneNumber={"+" + this.state.phoneNumber}
          />
        </SideBox>
        <Box sx={{ ...userProfileStyles.columnLayout, width: "80%" }} gridGap={20}>
          <h1 style={{ color: "white" }}>My Cash & Coupons</h1>
          
          <StyledTabs
            value={this.state.currentTab}
            onChange={(e, value) =>
              this.handleTabChange(value)
            }
            data-test-id="tabsTestId"
            aria-label="styled tabs example"
          >
            <StyledTab data-test-id="labelNameTestId" label="My Coupons" />
            <StyledTab label="My Reward Cash" />
          </StyledTabs>
          {this.state.currentTab 
          ? this.renderRewardCashTable()
          : this.renderCouponTable()}
        </Box>
      </StyledBox>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          invisible
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <main
          style={{
            overflowX: "scroll",
            maxWidth: "1920px",
            width: "100%"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 100px, rgba(255,255,255,1) 100px)",
              padding: "25px 10px"
            }}
          >
            {this.renderList()}
          </div>
          <Footer navigation={this.props.navigation} id={""} />
        </main>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RedeemButton = styled(Button)({
  border: "1px solid #FA7952",
  borderRadius: "7px",
  color: "#FA7952",
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16.94px",
  textTransform: "none",
  whiteSpace: "nowrap",
  height: "40px",
  width: "120px",
  "&:disabled": {
    border: "1px solid #959595",
    color: "#959595"
  },
  "&.Mui-disabled": {
    border: "1px solid #959595",
    color: "#959595"
  }
});
const StyledBox = styled(Box)({
  maxWidth: 1440,
  gap: "20px",
  width: 'calc(100% - 55px)',
  margin: '0 auto',
  display: 'flex',
  '@media (min-width: 600px) and (max-width: 1299px)' :{
    margin: '0px 32px'
  },
  '@media (min-width: 1300px) and (max-width: 1440px)' :{
    margin: '0px 55px'
  },
  "& .offText": {
    color: "#212121",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "21.78px",
    marginBottom: "6px"
  },
  "& .expiringOnText": {
    color: "#212121",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14.52px"
  },
  "& .createdOnText": {
    color: "#212121",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    marginBottom: "6px",
  },
  "& .htmlDivText": {
    color: "#939393",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "14px"
  },
  "& .noDataScreenDiv": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "64px"
  },
  "& .noDataScreenText": {
    color: "#475569",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "36px",
  }
})

const SideBox=styled(Box)({
  width:'15%',
  "@media only screen and (max-width: 1598px)": {
    width:'18%'
  },
  "@media only screen and (max-width: 1525px)": {
    width:'20%'
  },
  "@media only screen and (max-width: 1366px)": {
    width:'22%'
  },
  "@media only screen and (max-width: 1175px)": {
    width:'25%'
  },
  "@media only screen and (max-width: 1070px)": {
    width:'27%'
  },
  "@media only screen and (max-width: 1000px)": {
    width:'30%'
  },
  "@media only screen and (max-width: 760px)": {
    width:'35%'
  },
  "@media only screen and (max-width: 677px)": {
    width:'37%'
  },
  "@media only screen and (max-width: 652px)": {
    width:'38%'
  },
})
const webStylesLocal: Record<string, CSSProperties> = {
  bundleCourseMainLayout: {
    width: "97%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  cashContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: "10px",
    padding: "20px 24px",
    background: "#F4F4F4"
  },
  cashDisplay: {
    display: "inline-flex",
    color: "#0AA06E",
    fontSize: "30px",
    fontWeight: 700,
    gap: "0.2rem",
    alignItems: "center"
  },
  cashText: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    margin: "0px"
  },
  tableHeader: {
    color: "#939393",
    fontWeight: "bold",
    textAlign: "center"
  },
  tableCell: {
    textAlign: "center"
  },
  disabled: {
    color: "#999",
    borderColor: "#ccc"
  }
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #C2C2C2",
  },
  fixed: {
    height: "48px",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#366A76"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      background: "transparent",
      color: "#366A76",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      minWidth: 115,
      padding: 0,
      width: "180px",
      "&.Mui-selected": {
        color: "#366A76"
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
// Customizable Area End
