import React from "react";
// Customizable Area Start
import {
    Typography,
    styled,
    Button,
    TextField,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    createTheme,
    ThemeProvider,
    Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FaChevronDown } from "react-icons/fa";
import { webStyles } from "./AdminCourses.web";
import AddCourseClassesController, { Props } from "./AddCourseClassesController.web";
import { 
    CourseClassesFieldBox, 
    CourseClassesFieldControlBox, 
    SelectBox 
} from "./AdminCourseClasses.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

const theme = createTheme({
    typography: {
      h1: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        marginBottom: "16px",
      },
      body1: {
        fontFamily: "Inter",
        paddingLeft: "8px",
      }
    },
    overrides: {
        MuiTextField: {
            root: {
              "& [class*=MuiInputBase-input]::placeholder": {
                color: "#A0A0A0 !important", // Placeholder color
                opacity: 1, // Ensure it is visible
              },
            },
        },
        MuiRadio: {
            root: {
              color: "#1F6575",
              width: "24px",
              height: "24px",
              boxSizing: "border-box"
            },
            colorSecondary: {
                "&$checked": {
                  color: "#1F6575", // Override checked color
                },
              },
          }
    },
  });

// Customizable Area End

export default class AddCourseClasses extends AddCourseClassesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderChapterNameSelect = () => {
        return (
            <>
            <CourseClassesFieldControlBox>
                <Typography className="input_txt">Chapter Name</Typography>
                <div
                    style={{
                        display: "flex",
                        width: "calc(100% - 160px)",
                        flexWrap: "wrap",
                        border: "1px solid gray",
                        borderRadius: "4px",
                    }}
                >
                    <SelectBox
                        id="demo-customized-select-native"
                        disableUnderline
                        displayEmpty
                        IconComponent={FaChevronDown}
                        data-test-id="chapter"
                        value={this.state.chapter} 
                        onChange={this.handleChapterChange}
                        MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                            },
                            style: {
                                width: "450px",
                                height: "350px",
                                top: "8px",
                            },
                            keepMounted: true,
                            getContentAnchorEl: null,
                          }}
                          onScroll={this.props.onScrollHandle} 
                    >
                        <option aria-label="None" value="" />
                        {this.props.chaptersList.map((course: {
                            attributes: { name: string }; id: string,
                        }) => {
                            return (
                                <MenuItem
                                value={course.id}
                                key={course.id}
                              style={{ 
                                  display: "flex", 
                                  flexFlow: "wrap", 
                                  width: "490px", 
                            }}
                            >
                              <Typography style={{ 
                                whiteSpace: "pre-line" 
                                }}>
                                {course.attributes.name}
                              </Typography>
                            </MenuItem>
                            );
                        })}
                    </SelectBox>
                </div>    
            </CourseClassesFieldControlBox>
                <div style={{ marginLeft: "28%", marginTop: "-8px", marginBottom: "8px" }}>
                    {this.state.errors.chapter &&
                        <span style={webStyles.errorText} data-test-id="errorMsg">
                            {this.state.errors.chapter}
                        </span>
                    }
                </div>
            </>
        )
    }

    conditionalReturn = (condition: boolean, ifValue: JSX.Element, elseValue: JSX.Element) => {
        return condition ? ifValue : elseValue;
    }

    andCOnditionalReturn = (condition: string | File | null | undefined | JSX.Element, andValue: JSX.Element | string | undefined) => {
        return condition && andValue
    }
    // Customizable Area End


    render() {
        // Customizable Area Start
        const { videoType, fileName, errors } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <ModalWrapper>
                    <NotificationAlertMessage
                        data-test-id="snackbar-component"
                        alertType={this.state.alertType}
                        msg={this.state.alertMsg}
                        onClose={this.onCloseAlert}
                        isOpen={this.state.isAlert}
                    />
                    <div className="container">
                        <div className="modal">
                            <div>
                                <CourseClassesFieldBox>
                                    {this.renderChapterNameSelect()}
                                </CourseClassesFieldBox>
                                {this.conditionalReturn(!!this.props.isUpdate, <></>, <Button className="addMoreClassBtn" data-test-id="addMoreClassBtn" variant="outlined" onClick={this.addClass}>Add more course class</Button>)}
                                <div style={{ paddingTop: "20px" }}>
                                    <div className="grid-header">
                                        <div>Class Title Name</div>
                                        <div>Sequence Number</div>
                                        <div>Duration</div>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                            <div>Type</div>
                                            <div>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        data-test-id="videoOption"
                                                        aria-label="videoOption"
                                                        name="videoOption"
                                                        value={videoType}
                                                        onChange={(e) => this.handleRadioChange(e.target.value as "URL" | "Upload")}
                                                        className="radioGroup"
                                                    >
                                                        <FormControlLabel className="radioLabel" value="URL" control={<Radio />} label="Video ID" />
                                                        <FormControlLabel className="radioLabel" value="Upload" control={<Radio />} label="Upload" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>

                                        </div>
                                        <div></div>
                                    </div>
                                    {this.state.classes.map((cls, index) => (
                                        <div key={index} className="grid">
                                            <div className="inputBoxDev">
                                            <TextField
                                                placeholder="Name"
                                                size="small"
                                                variant="outlined"
                                                value={cls.title}
                                                data-test-id="title"
                                                onChange={(e) => this.handleInputChange(index, "title", e.target.value)}
                                            />
                                            {this.andCOnditionalReturn(errors[`title${index}`], (
                                                <span style={webStyles.errorText}>
                                                    {errors[`title${index}`]}
                                                </span>
                                            ))}
                                            </div>
                                            <div className="inputBoxDev">
                                            <TextField 
                                                value={cls.sequence} 
                                                size="small" 
                                                variant="outlined"
                                                data-test-id="sequence"
                                                name="sequence"
                                                onChange={(e) => this.handleInputChange(index, "sequence", e.target.value)}
                                            />
                                            {this.andCOnditionalReturn(errors[`sequence${index}`], (
                                                <span style={webStyles.errorText}>
                                                    {errors[`sequence${index}`]}
                                                </span>
                                            ))}
                                            </div>
                                            <div className="inputBoxDev">
                                            <TextField
                                                placeholder="HH:MM"
                                                size="small"
                                                variant="outlined"
                                                data-test-id="duration"
                                                value={cls.duration}
                                                onChange={(e) => this.handleDurationChange(index, "duration", e.target.value)}
                                            />
                                            {this.andCOnditionalReturn(errors[`duration${index}`], (
                                                <span style={webStyles.errorText}>
                                                    {errors[`duration${index}`]}
                                                </span>
                                            ))}
                                            </div>
                                            {this.conditionalReturn(videoType === "URL", (
                                                <div className="inputBoxDev">
                                            <TextField
                                                placeholder="Enter URL"
                                                size="small"
                                                variant="outlined"
                                                data-test-id="url"
                                                value={cls.vdocipher_video_id}
                                                onChange={(e) => this.handleInputChange(index, "vdocipher_video_id", e.target.value)}
                                                fullWidth
                                            />
                                            {this.andCOnditionalReturn(errors[`videoId${index}`], (
                                                <span style={webStyles.errorText}>
                                                    {errors[`videoId${index}`]}
                                                </span>
                                            ))}
                                            </div>
                                        ),
                                            (
                                                <div data-test-id="videoUpload" className="inputBoxDev">
                                                <div style={{ display: "flex", justifyContent: "start" }}>
                                                        <label>
                                                            <input
                                                                type="file"
                                                                accept="video/*"
                                                                data-test-id="uploadVideo"
                                                                style={{ display: "none" }}
                                                                onChange={(event) => {
                                                                    const file = event.target.files?.[0]; // Get the first selected file
                                                                    if (file) {
                                                                      this.handleFileChange(index, file); // Use the new handler to update the file in the state
                                                                    }
                                                                  }}
                                                            />
                                                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0.5" y="0.5" width="43" height="43" rx="4.5" stroke="#1F6575" />
                                                                <rect x="0.5" y="0.5" width="43" height="43" rx="4.5" fill="white" />
                                                                <rect x="0.5" y="0.5" width="43" height="43" rx="4.5" stroke="#979797" />
                                                                <path d="M22 25.575C21.7167 25.575 21.4793 25.479 21.288 25.287C21.096 25.0957 21 24.8583 21 24.575V17.425L19.125 19.3C18.925 19.5 18.6917 19.6 18.425 19.6C18.1583 19.6 17.9167 19.4917 17.7 19.275C17.5 19.075 17.4043 18.8373 17.413 18.562C17.421 18.2873 17.5167 18.0583 17.7 17.875L21.3 14.275C21.4 14.175 21.5083 14.104 21.625 14.062C21.7417 14.0207 21.8667 14 22 14C22.1333 14 22.2583 14.0207 22.375 14.062C22.4917 14.104 22.6 14.175 22.7 14.275L26.3 17.875C26.5 18.075 26.5957 18.3123 26.587 18.587C26.579 18.8623 26.4833 19.0917 26.3 19.275C26.1 19.475 25.8627 19.579 25.588 19.587C25.3127 19.5957 25.075 19.5 24.875 19.3L23 17.425V24.575C23 24.8583 22.9043 25.0957 22.713 25.287C22.521 25.479 22.2833 25.575 22 25.575ZM16 29.575C15.45 29.575 14.9793 29.3793 14.588 28.988C14.196 28.596 14 28.125 14 27.575V25.575C14 25.2917 14.0957 25.054 14.287 24.862C14.479 24.6707 14.7167 24.575 15 24.575C15.2833 24.575 15.521 24.6707 15.713 24.862C15.9043 25.054 16 25.2917 16 25.575V27.575H28V25.575C28 25.2917 28.096 25.054 28.288 24.862C28.4793 24.6707 28.7167 24.575 29 24.575C29.2833 24.575 29.5207 24.6707 29.712 24.862C29.904 25.054 30 25.2917 30 25.575V27.575C30 28.125 29.8043 28.596 29.413 28.988C29.021 29.3793 28.55 29.575 28 29.575H16Z" fill="#1F6575" />
                                                            </svg>
                                                        </label>
                                                        {this.andCOnditionalReturn((this.andCOnditionalReturn(cls.video_upload, cls.video_upload?.name) || cls.video_name), <div style={{
                                                            maxWidth: '100px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            padding: '4px',
                                                            fontSize: '12px',
                                                            fontFamily: 'Inter',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            {cls.video_upload?.name || cls.video_name}
                                                        </div>)}
                                                </div>
                                                {this.andCOnditionalReturn(errors[`videoFile${index}`], (
                                                <span style={webStyles.errorText}>
                                                    {errors[`videoFile${index}`]}
                                                </span>
                                            ))}
                                                </div>
                                            ))}
                                            <Button variant="outlined" className="removeClassBtn" data-test-id="removeClassBtn" onClick={() => this.removeClass(index)} disabled={this.props.isUpdate}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.3">
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#F87171" />
                                                    <path d="M18.2997 5.71069C18.1129 5.52344 17.8592 5.4182 17.5947 5.4182C17.3302 5.4182 17.0766 5.52344 16.8897 5.71069L11.9997 10.5907L7.10973 5.70069C6.9229 5.51344 6.66924 5.4082 6.40473 5.4082C6.14021 5.4082 5.88656 5.51344 5.69973 5.70069C5.30973 6.09069 5.30973 6.72069 5.69973 7.11069L10.5897 12.0007L5.69973 16.8907C5.30973 17.2807 5.30973 17.9107 5.69973 18.3007C6.08973 18.6907 6.71973 18.6907 7.10973 18.3007L11.9997 13.4107L16.8897 18.3007C17.2797 18.6907 17.9097 18.6907 18.2997 18.3007C18.6897 17.9107 18.6897 17.2807 18.2997 16.8907L13.4097 12.0007L18.2997 7.11069C18.6797 6.73069 18.6797 6.09069 18.2997 5.71069Z" fill="#F87171" />
                                                </g>
                                            </svg>
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="classModalBtns">
                                <Button 
                                    variant="outlined" 
                                    className="resetBtn" 
                                    onClick={() => this.setState({ chapter: "", classes: [{ title: "", sequence: "", duration: "", vdocipher_video_id: "", video_upload: null }], errors: {} })}
                                    data-test-id="resetBtn"
                                    >
                                        Reset
                                    </Button>
                                <Button variant="contained" className="addBtn" disabled={this.state.isLoading} onClick={this.handleSubmit} data-test-id="addBtn">{this.conditionalReturn(!!this.props.isUpdate,<>Edit Class</>,<>Add Classes</>)}</Button>
                            </div>
                        </div>
                    </div>
                </ModalWrapper>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const ModalWrapper = styled("div")(() => ({
    "& .container": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    "& .modal": {
        background: "#fff",
        padding: "0 20px",
        borderRadius: "8px",
        width: "100%",
        maxWidth: "651px",
        display: "flex",
        flexDirection: "column",
        gap: "100px"
    },
    "& .form-group": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "15px",
    },
    "& .class-row": {
        display: "grid",
        gridTemplateColumns: "2fr 1fr 1fr 3fr auto",
        gap: "10px",
        alignItems: "center",
    },
    "& .actions": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    "& .grid-header": {
        display: "grid",
        gridTemplateColumns: "1fr 60px 100px 1.3fr 40px",
        alignItems: "start",
        gap: "10px",
        marginBottom: "10px",
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "inter",
        lineHeight: "18px",
        color: "#192252",
    },
    "& .grid": {
        display: "grid",
        gridTemplateColumns: "1fr 60px 100px 1.3fr 40px",
        alignItems: "start",
        gap: "10px",
        marginBottom: "10px",
    },
    "& .inputBoxDev": {
        display: "flex",
        flexDirection: "column",
        "& input": {
            paddingTop: "12px",
            paddingBottom: "12px"
        }
    },
    "& .addMoreClassBtn": {
        width: "220px",
        height: "40px",
        color: "#1F6575",
        fontSize: "14px",
        textTransform: "none",
        border: "1px solid #1F6575",
        margin: "20px 0 0",
        fontWeight: 600,
        borderRadius: "0px",
        whiteSpace: "nowrap"
    },
    "& .classModalBtns": {
        display: "flex",
        justifyContent: "center",
        gap: "15px",
    },
    "& .resetBtn": {
        textTransform: "none",
        color: "#1F6575",
        border: "1px solid #1F6575",
        fontSize: "14px",
        fontWeight: 600,
        borderRadius: "0px"
    },
    "& .addBtn": {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: "#1F6575",
        fontSize: "14px",
        fontWeight: 600,
        borderRadius: "0px"
    },
    "& .removeClassBtn": {
        width: "24px",
        height: "24px",
        border: "none",
        marginTop: "8px !important"
    },
    "& .radioGroup": {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: "10px",
        '& .MuiSvgIcon-root': {
            fontSize: 28,
        },
        '& [class*=PrivateSwitchBase-input]': {
            width: "20px",
            height: "20px"
        },
    },
    "& [class*=MuiTextField-root]": {
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            "& fieldset": {
                borderColor: "#CBD5E1",
            },
            "&:hover fieldset": {
                borderColor: "#94A3B8",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#2563EB",
            },
        },
    },
    "& .MuiInputBase-input::placeholder": {
        fontFamily: "Outfit",
        opacity: 1,
        color: "#94A3B8 !important",
        fontWeight: 400,
    },
    "& [class*=MuiDialog-paperFullWidth]": {
        width: "651px !important",
    },
    "& [class*=MuiInputBase-root]": {
        width: "100%",
        padding: "0px"
    },
    "& [class*=.MuiSelect-icon]": {
        top: "50%",
        right: "8px"
    },
    '& [class*=PrivateSwitchBase-input]': {
            width: "20px",
            height: "20px"
     },
     "& [class*=MuiFormControlLabel-label]": {
        fontSize: "12px",
    }
}));
// Customizable Area End
