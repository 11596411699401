import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiService } from "./RazorpayTransactionsController.web";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import moment from "moment";

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

export interface OrderResponseType {
  data: OrderList[];
  meta: Meta;
  errors: any[];
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface OrderList {
  order_id: number;
  order_date_and_time: string;
  payment_status: string;
  account_id: number;
  full_name: string;
  email: string;
  full_phone_number: string;
  course_id: string;
  course_name: string;
  transaction_id: string;
  address: string;
  original_price: number;
  discount_price: number;
  total_amount_paid: number;
}

export interface Meta {
  page: string;
  total_order_count: number;
}

export interface InvalidResponseType {
  errors: Array<string>;
}
interface OrderDetailResponse {
  data: OrderData[];
  meta: {
    total_pages: number;
   }
  }
interface OrderData {
    id: string;
    type: string;
    attributes: {
      course_order : PurchaseOrderData[]
    };
  }
interface PurchaseOrderData {
    id: number;
    order_id: number;
    course_type: string;
    price: number;
    no_of_year: number;
    place_order_date: string;
    course_count: number;
    date: string;
    time: string;
    invoice_url: string | null;
    course_data: {
      data: {
          id: number;
          type: string;
          attributes: {
              bundle_name: string;
              bundle_course_thumbnail: string;
              course_name: string;
              course_thumbnail: string;
          }
        }
      }
  }

export const initialValues: any = {
  currentPageCount: 1,
  pageCount: 0,
  userToken: "",
  isLoading: true,
  isAlert: false,
  alertMsg: "",
  alertType: "success",
  isUpdate: false,
  perPage: elementsPerPage,
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ordersList: OrderList[];
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  perPage: number;
  PurchaseCourses: OrderData[];
  searchText: string;
  purchaseCoursesPage: number;
  totalPage: number;
  showMoreLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderManagementListApiCallId: string = "";
  getPurchaseCoursesHistoriesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ...initialValues,
      ordersList: [],
      PurchaseCourses: [],
    searchText: '',
    purchaseCoursesPage: 1,
    totalPage: 1,
    showMoreLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    await this.getUserToken();
    await this.getOrdersList(1, this.state.perPage);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: OrderResponseType | OrderDetailResponse
  ) => {
    switch (apiRequestCallId) {
      case this.getOrderManagementListApiCallId:
        this.handleOrdersListResponse(responseJson as OrderResponseType);
        break;
      case this.getPurchaseCoursesHistoriesApiCallId:
        this.handlePurchaseHistoryResponse(responseJson as OrderDetailResponse)
        break;
    }
  };

  getOrdersList = async (pageNo: number, per_page: number) => {
    this.setState({ isLoading: true });
    this.getOrderManagementListApiCallId = apiService({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      token: this.state.userToken,
      endPoint: `${configJSON.getOrderManagementApiEndpoint}?page=${pageNo}&per_page=${per_page}`,
    });
  };

  handleOrdersListResponse = async (response: OrderResponseType) => {
    const { meta } = response;
    if (response && response?.errors) {
      if (response?.errors[0]?.token === configJSON.tokenExpiredMessage) {
        this.navigationLoginScreen();
      }
    } else {
      this.setState({
        ordersList: response.data,
        pageCount: Math.ceil(meta.total_order_count / this.state.perPage),
        isLoading: false,
      });
    }
  };

  onPageChange = (event: any, value: any) => {
    this.setState(
      {
        currentPageCount: Number(value),
      },
      () => this.getOrdersList(this.state.currentPageCount, this.state.perPage)
    );
  };

  getOrderPurchaseHistory = async (pageNo: number) => {
    if (pageNo === 1) {
      this.setState({PurchaseCourses:  []})
    }
    this.setState({ isLoading: true })
    this.getPurchaseCoursesHistoriesApiCallId = apiService({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      token: this.state.userToken,
      endPoint: `${configJSON.purchaseHistoryApiEndPoint}?course_name=${this.state.searchText}&page=${pageNo}&per_page=${10}`,
    });
  }

  handlePurchaseHistoryResponse = (response: OrderDetailResponse) => {
    if(response.data) {
    this.setState({ 
      PurchaseCourses: this.state.searchText === "" ? [...this.state.PurchaseCourses, ...response.data] : response.data,
      totalPage: response.meta.total_pages,
      isLoading: false,
      showMoreLoading: false
    })
  } else {
    this.setState({
      PurchaseCourses: [],
      isLoading: false,
      showMoreLoading: false
    })
  }
  }

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  handleShowMorePurchaseOrder = () => {
    this.setState({showMoreLoading: true, purchaseCoursesPage: this.state.purchaseCoursesPage + 1}, () => {
      this.getOrderPurchaseHistory(this.state.purchaseCoursesPage)
    })
  }

  calculateValidityTill(placeOrderDate: string, noOfYears: number): string {
    const purchaseDate = moment(placeOrderDate);
    const validityDate = purchaseDate.add(noOfYears, 'years');
    return validityDate.format('D MMM YYYY')
  }

  calculateStatus(validityTill: string): string {
    const currentDate = new Date();
    const validityDate = new Date(validityTill);
    return validityDate >= currentDate ? 'Active' : 'Expired';
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      if (this.state.searchText === "") {
        this.getOrderPurchaseHistory(1)
      }
    });
  };
  searchPurchases = () => {
    this.getOrderPurchaseHistory(1)
  };

  changeDateFormatToIST(order_date_and_time: string) {
    const parsedDate = moment(order_date_and_time, "DD MMM, YYYY | HH.mm");
    let newDate = parsedDate.format("YYYY-MM-DD HH:mm");
    const utcTime = moment.utc(newDate, "YYYY-MM-DD HH:mm");
    const istTime = utcTime.utcOffset(5 * 60 + 30);
    return istTime.format("DD MMM, YYYY | HH:mm");
  }
  // Customizable Area End
}
