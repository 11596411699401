import React from "react";
// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { Pagination } from "@material-ui/lab";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
import moment from "moment";

const drawerWidth = 250;
const closeDrawer = 0;

const tableHeaderCols = [
    "S.No",
    "Main Course Name",
    "Total Students",
    "Main Category",
    "Sub Category",
    "Child Category",
    "Price",
    "Created Date",
    "Trending",
    "Status",
    "Action",
  ],
  headerMap: { [key: string]: string } = {
    "Main Course Name": "sort_name",
    "S.No": "sort_id",
    Status: "sort_status",
  };

// Customizable Area End
import AdminCoursesController, { Props, configJSON } from "./AdminCoursesController.web";
import { lng, plus } from "./assets";
import {
  deleteIcone,
  editIcone,
  notificationIcone,
  searchIcone,
} from "../../categoriessubcategories/src/assets";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Loader from "../../../components/src/Loader.web";
import {
  CheckBtn,
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";

export default class AdminCourses extends AdminCoursesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderTableData = (item: any, index: any) => {
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                style={webStyles.input}
                data-test-id={"rowCheckbox"}
                type="checkbox"
                checked={this.state.isChecked[index]}
                onChange={() => this.handleCheckBoxes(index)}
              />
            </Box>
          </td>
          <td>{item.id}</td>
          <td>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={item.attributes.course_thumbnail}
                alt="Description of the image"
                style={{ width: 42, height: 26, borderRadius: "7px" }}
              />
              <div style={{ width: "200", textAlign: "left", marginLeft: 20 }}>
                {item.attributes.course_name}
              </div>
            </div>
          </td>
          <td>{item.attributes?.student_count || 0}</td>
          <td>{item.attributes.course_category?.name}</td>
          <td>{item.attributes?.course_sub_category?.name}</td>
          <td>{item.attributes?.course_child_category?.name}</td>
          <td>{item.attributes?.first_year_price}</td>
          <td data-test-id="create_date">{moment(item.attributes.created_at).format("DD/MM/YY")}</td>
          <td>
            <CheckBtn className="switchs">
              <input
                type="checkbox"
                checked={item.attributes.trending}
                data-test-id="trendingTestId"
                onClick={(e: any) =>
                  this.handleToggleOptionsList(
                    false,
                    item.attributes.trending,
                    item.id,
                    index
                  )
                }
              />
              <span className="slider round"></span>
            </CheckBtn>
          </td>
          <td>
            <CheckBtn className="switchs">
              <input
                type="checkbox"
                checked={item.attributes.status}
                data-test-id="statusTestId"
                onClick={(e: any) =>
                  this.handleToggleOptionsList(
                    true,
                    item.attributes.status,
                    item.id,
                    index
                  )
                }
              />
              <span className="slider round"></span>
            </CheckBtn>
          </td>
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleEditPropsCourses(item.id, item.attributes.course_name)}
              />
              <div style={{ position: "relative", display: "inline-block" }}>
                <FaRegTrashAlt
                  data-test-id="deleteIconTestId"
                  className="desc_edit_icon"
                  color={"black"}
                  size={17}
                  onClick={() => this.deleteStudent(item.id)}
                />

                {this.state.openDeleteModal &&
                  this.state.isSelectedId === item.id &&
                  this.deleteModal(item.id)}
              </div>
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider1} />
      </>
    );
  };

  getSortTestId = (header: string) => {
    return headerMap[header] || "sort";
  };

  renderTable1 = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <input
                    checked={this.state.isHeaderChecked}
                    type="checkbox"
                    style={webStyles.input}
                    onChange={this.handleCheckboxChange}
                    data-test-id="headerCheckbox"
                  />
                </Box>
              </th>
              {tableHeaderCols.map((header, index) => {
                let renderIcon = false;
                if (["S.No", "Main Course Name", "Status"].includes(header)) {
                  renderIcon = true;
                }
                return renderTableRecord(
                  () => isCurrentSort(header),
                  header,
                  renderIcon,
                  index,
                  () => this.handleSort(header),
                  this.getSortTestId(header),
                  "center"
                );
              })}
            </tr>
          </thead>
          <span style={webStyles.dividerLine}></span>
          <TableBody>
            {this.state.adminCourse?.data?.map((item: any, index: any) =>
              this.renderTableData(item, index)
            )}
          </TableBody>
        </TableStyle>

        <PaginationBoxes
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onPageChange}
          />
        </PaginationBoxes>
      </Box>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleCourseSearch(e)}
        isLoading={this.state.loader}
        title={"Main Courses"}
        actionButtons={[
          {
            icon: plus,
            iconType: "imgUrl",
            onClick: () => this.handleAddCourseNavigation(),
            text: "Add Main Courses",
            testId: "addCoursesBtn",
          },
        ]}
        isStudent={true}
        handleFromDate={(e: any) => this.handleDate1Change(e)}
        formDate={this.state.isSeletedDate1}
        toDate={this.state.isSelectedDate2}
        handleToDate={(e: any) => this.handleDate2Change(e)}
        getCategriesSteamData={this.getCategriesSteamData}
        getCategoriesSubjectData={this.getCategoriesSubjectData}
        handleOnReload={this.handleOnReload}
        showFilter
        getCategoryData={this.state.getCategoryData}
        getSubCategoryData={this.state.getSubCategoryData}
        getChildCategoryData={this.state.getChildCategoryData}
      >
        <NotificationAlertMessage
          dataTestId="oncloseAlert"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <main className="table_data">{this.renderTable1()}</main>
      </MainLayout>
      // Customizable Area End
    );
  }

  deleteModal(id: any) {
    return (
      <div
        style={{
          position: "absolute",
          right: "0",
          top: "0",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          minWidth: "120px",
          padding: "20px",
        }}
      >
        <Typography>
          {configJSON.courseValidityMsg}
        </Typography>
        <Typography style={{ fontWeight: "bold" }}>
          {configJSON.deleteConfirmationMsg}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "10px",
          }}
        >
          <button
            data-test-id="deleteAdminModal"
            style={webStyles.deleteBtn}
            onClick={() => this.handleDeleteApiCall(id)}
          >
            Delete
          </button>
          <button
            data-test-id="cancelDeleteModal"
            style={webStyles.cancelBtn}
            onClick={() => this.setState({ openDeleteModal: false })}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
// Customizable Area Start
export const webStyles = {
  mystatefuleditor: {
    width: "783px",
  },
  bundleCourseMainLayout: { width: "97%", margin: "0 auto", maxWidth: "960px" },
  adminLayout: { display: "flex", flexDirection: "column", gap: "2rem" },
  AddMoreText: {
    color: "#1F6575",
    width: "145px",
    minWidth: "140px",
    height: "40px",
    borderColor: "#1F6575",
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left" as "left",
    textTransform: "capitalize" as "capitalize",
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
  DeleteIconBtn: {
    color: "#FF5C00",
    width: "40px",
    height: "40px",
    borderColor: "#FF5C00",
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left" as "left",
    textTransform: "capitalize" as "capitalize",
    alignItems: "center",
    justifyContent: "center",
  },
  labelStyles: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em" as "0em",
    textAlign: "left" as "left",
    color: "#192252",
  },
  calendarIcon: {
    top: "50%",
    right: "10px",
    transform: " translateY(-50%)",
    cursor: "pointer",
  },
  toText: {
    color: "#000",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  dateInput2: {
    width: "190px",
    height: "55px",
  },
  customDatePicker: {
    display: "inline-block",
  },
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`,
    height: "100svh",
  },

  dividerLine: {
    height: "20px",
    display: "inline-block",
  },
  hideDrawer: {
    width: `calc(100% - ${closeDrawer}px)`,
    height: "100svh",
  },
  tableAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "90%",
    maxWidth: "700px",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  tableDivider1: {
    height: "8px",
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  currentPage: {
    backgroundColor: "#176876",
    color: "white",
    borderRadius: "5px",
    padding: "0px 5px",
  },
  pagination: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  pageWrapper: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableFooter: {
    alignSelf: "flex-end",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    marginRight: "40px",
  },
  tableBodyMsg: {
    width: "100%",
    border: "1px solid red",
    marginTop: "50px",
    justifyContent: "center",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    height: "40vh",
  },
  deleteBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    border: " none",
    color: " white",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: "#FD7101",
    marginTop: "20px",
  },
  cancelBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    color: "black",
    fontSize: "0.875rem",
    fontWeight: 600,
    background: "white",
    border: "1px solid grey",
    marginTop: "20px",
  },
  inputFieldMain: {
    marginBottom: "20px",
    borderBottom: "2px solid rgba(189, 188, 188, 0.314)",
  },
  labelInputMain: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    marginBottom: "20px",
  },
  inputLabelText: {
    color: "#192252",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    marginTop: "10px",
  },
  formControl: {
    minWidth: 120,
    maxWidth: "100%",
  },
  errorText: {
    color: "red",
    fontSize: "0.625rem",
    marginTop: "8px"
  },

  inputTwoColMain: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: " 4rem",
    borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
    marginBottom: "15px",
  },
  inputTwoColMain1: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: " 4rem",
    borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
    marginBottom: "15px",
  },
  addResetBtnMain: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
    columnGap: "10px",
  },
  adminHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "28px",
  },
  commonAdminHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profileInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90px",
    marginRight: "20px",
  },
  dateLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "250px",
    color: "#000",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 700,
  },
};
const PaginationBoxes = styled(Box)({
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#fff",
    backgroundColor: "#206575",
  },
  "& .viewall_txt": {
    fontSize: "0.75rem",
    color: "#206575",
    cursor: "pointer",
  },
});
// Customizable Area End
