Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeCouponGet = "GET";
exports.couponAPiEndPoint =
  "coupon_cg/coupon_code_generator";

exports.associatedCoursesApiEndpoint = "bx_block_coupon_cg/coupon_code_generator/get_association_details";
exports.couponWebApiEndpoint = "bx_block_coupon_cg/coupon_code_generator";
exports.couponWebSearchApiEndpoint = "bx_block_coupon_cg/coupon_code_generator/search";

exports.createCouponAPiMethod = "POST";
exports.deleteCouponAPiMethod = "DELETE";
exports.patchCouponAPiMethod = "PATCH";
exports.putCouponApiMethod = "PUT";
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.couponApiContentType = "application/json";

exports.vouchersListApiEndpoint = "bx_block_coupon_cg/voucher_codes";
exports.voucherGetApiEndpoint = "bx_block_coupon_cg/voucher_codes/";

exports.discountsListApiEndpoint = "bx_block_coupon_cg/flat_discounts";
exports.discountGetApiEndpoint = "bx_block_coupon_cg/flat_discounts/";

exports.getReferralApiEndpoint = "bx_block_referrals/referrals/get_referral";
exports.updateReferralApiEndpoint = "bx_block_referrals/referrals/update_referral";

exports.tokenExpiredMessage = "Token has Expired";
exports.deleteText = "Delete";
exports.confirmDeleteMessage = "Do you want to delete this Discount?";

exports.myCashApiEndpoint = "bx_block_referrals/";
exports.sessionExpireMsg = "Session is expired. Please login again.";

// Customizable Area End
