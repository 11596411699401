import {
  styled,
  TextField,
  NativeSelect,
  Select,
  Box,
  Button,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import React, { CSSProperties } from 'react';

const thumbnailButton: any = {
  borderRadius: '3px',
  padding: '15px 0',
  fontSize: '0.75rem',
  fontWeight: 600,
  marginTop: '20px',
  backgroundColor: '#fcfcfc',
  border: '1px solid #81999f',
  color: '#81999f',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'capitalize',
  alignItems: 'centerd'
};

const ProfilePicInput = ({
  onChange,
  onRemove,
  previewThumbnail,
  dataTestId,
  name,
}: {
  previewThumbnail?: string;
  onChange: (e: any) => void;
  onRemove: (e: any) => void;
  name?: string;
  dataTestId?: string;
}) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        columnGap: '10px',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          width: '48px',
          height: '48px',
          borderRadius: '5px',
          border: '1px solid #9F9F9F',
          background: '#FFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={previewThumbnail} width={40} height={40} />
      </div>
      <label
        style={{
          ...thumbnailButton,
          color: '#1F6575',
          border: '1px solid #1F6575',
          padding: '12px 0',
          alignItems: 'center',
          marginTop: 0,
          minWidth: '140px',
          height: '25px'
        }}
      >
        <span>Replace this Image</span>
        <input
          type="file"
          id="fileInput"
          data-test-id={dataTestId}
          name={name}
          style={{ display: 'none' }}
          onChange={onChange}
        />
      </label>
      <Button
        variant="text"
        style={{ color: '#DC2626', textTransform: 'none', fontSize: '0.75rem' }}
        onClick={onRemove}
      >
        Remove
      </Button>
    </div>
  );
};

const DateBox = styled(Box)({
  '& .date_boxs': {
    display: 'flex',
    justifyContent: 'flex-start',

    '& .MuiSvgIcon-root': {
      height: '1.1em !important',
      width: '1.1em !important'
    },
    '& .date_input': {
      '& .MuiInputBase-root': {
        fontSize: '0.75rem',
        fontFamily: 'Inter'
      },
      '& input': {
        textTransform: 'uppercase',
        minWidth: '144.53px'
      },
      '& input::-moz-calendar-picker-indicator': {
        display: 'none',
        WebkitAppearance: 'none'
      },
    },
  },
});

const DateInput = ({
  dataTestId,
  onChange,
  defaultValue,
  id,
  inputProps,
  style,
  value,
  name,
  disabled,
  onBlur
}: {
  onChange: (e: { target: { value: string; name: string } }) => void;
  onBlur?: (e: { target: { value: string; name: string } }) => void;
  dataTestId?: string;
  defaultValue?: unknown;
  id?: string;
  value?: unknown;
  style?: CSSProperties;
  inputProps?: any;
  name?: string;
  disabled?: boolean;
}) => (
  <DateBox className="date_boxs" style={{ position: 'relative' }}>
    <InputBox
      variant="outlined"
      id={id}
      className="date_input"
      data-test-id={dataTestId}
      type="date"
      name={name}
      value={value}
      disabled={disabled}
      style={{ ...style, width: '100%', fontFamily: 'Inter' }}
      defaultValue={defaultValue}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        inputProps
          ? inputProps
          : {
              style: {
                borderBottom: 'none',
                height: 48,
              },
            }
      }
      onChange={onChange}
      onBlur={onBlur}
    />
    <DateRangeIcon
      fontSize={'inherit'}
      style={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 10px)',
        color: 'black',
        pointerEvents: 'none'
      }}
    />
  </DateBox>
);

const InputBox = styled(TextField)({
  '& .MuiOutlinedInput-input': { padding: '0px 14px' },
  '& .MuiInputBase-input': { height: '40px' },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': { borderColor: '#3F6474' },
  },
});
const StyledSelect = styled(NativeSelect)({
  '&.MuiInputBase-root': {
    fontFamily: "'Inter'",
    border: '1px solid lightgray',
    borderRadius: '4px',
    height: '40px'
  },
  '& .MuiNativeSelect-icon': {
    top: 'calc(50% - 7px);',
    right: '6px'
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
    fontFamily: "'Inter'"
  },
});

const MultiSelect = styled(Select)({
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px'
  },
});

const MultiLineTextField = styled(TextField)({
  '& .MuiInputBase-input': { height: '86px' },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': { borderColor: '#3F6474' },
  },
});

const TwoColBox = styled(Box)({
  display: 'inline-flex',
  columnGap: '1rem',
  borderBottom: '1px solid rgba(189, 188, 188, 0.314)',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
  '& .input_txt': {
    textTransform: 'capitalize',
    width: '160px',
    color: '#192252',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px'
  },
  '& .file_upload_box': {
    height: '40px',
    color: '#fff',
    border: '1px solid black',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: '#D4D4D4',
    '& .app_icon_txt': {
      fontSize: '13px',
      color: '#A9A9A9',
      marginLeft: '10px',
      wordSpacing: '-2px'
    },
    '& .lable_txt': {
      backgroundColor: '#3F6474',
      height: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '2px',
      fontSize: '12px',
      borderRadius: '4px',
      padding: '0px 30px'
    },
  },
  '& .child__box': {
    display: 'flex',
    paddingBottom: 20,
    flex: '0 0 47%',
    columnGap: '1rem',
    justifyContent: 'space-between',
    "&.align-center": {
      alignItems: 'center',
    },
    '&.align-column': {
      flexDirection: 'column',
      justifyContent: 'start'
    },
    '& .box__label': {
      minWidth: 150,
      maxWidth: '30%',
      color: '#192252',
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiInputBase-root': {
      background: 'white'
    },
  },
  '@media (max-width:803px)': {
      '& .child__box': {
        paddingBottom:'unset'
      },
    },
  // MEDIA QUERYS FOR INPUT MULTISELECT
  '& .child__box.multiselect': {
    '& .box__input': {
      width: '70%',
      maxWidth: 299,
      '& > div': {
        borderRadius: '4px',
        background: 'white'
      },
    },
    '@media (max-width:1310px)': {
      '& .box__input': {
        maxWidth: '298px !important'
      },
    },
    '@media (max-width:1279px)': {
      '& .box__input': {
        maxWidth: '288px !important'
      },
    },
    '@media (max-width:1255px)': {
      '& .box__input': {
        maxWidth: '277px !important'
      },
    },
    '@media (max-width:1210px)': {
      '& .box__input': {
        maxWidth: '252px !important'
      },
    },
    '@media (max-width:1159px)': {
      '& .box__input': {
        maxWidth: '230px !important'
      },
    },
    '@media (max-width:1117px)': {
      '& .box__input': {
        maxWidth: '213px !important'
      },
    },
    '@media (max-width:1024px)': {
      '& .box__input': {
        maxWidth: '72vw !important'
      },
    },
  },
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .child__box': {
      flexDirection: 'column',
      '& .box__input': {
        width: '100% !important'
      },
    },
  },
});
const OneColBox = styled(Box)({
  borderBottom: '1px solid rgba(189, 188, 188, 0.314)',
  columnGap: '1rem',
  display: 'inline-flex',
  width: '100%',
  paddingBottom: 20,
  justifyContent: 'space-between',
  '&.align-column': {
    flexDirection: 'column',
    justifyContent: 'start'
  },
  '& .box__label': {
    minWidth: 150,
    maxWidth: '30%',
    color: '#192252',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center'
  },
  '& .box__input': {
    // width: '70%',
    '& > div': {
      borderRadius: '4px',
      background: 'white'
    },
  },
  '& .box__input.rs__inputs': {
    width: '20%'
  },
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .box__input': {
      width: '100% !important'
    },
  },
});

export {
  InputBox,
  StyledSelect,
  MultiLineTextField,
  MultiSelect,
  TwoColBox,
  OneColBox,
  DateInput,
  ProfilePicInput,
};
