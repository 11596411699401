import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  InputAdornment,
  styled,
  TextField
} from "@material-ui/core";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import { TwoColBox, InputBox } from "../../../components/src/GenericInputs";
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

// Customizable Area End

import FooterController, { Props } from "./FooterController.web";
import { CreateButton, ButtonContainer, ResetButton } from "./Achievements.web";

export default class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }
  renderInputView = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Address</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <MultiLineTextField
                  data-test-id="addressId"
                  variant="outlined"
                  fullWidth
                  name="address"
                  multiline
                  maxRows={3}
                  minRows={3}
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.address}
                />
                <span style={webStyles.errorText}>
                  {this.state.addressError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Mobile</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputAdornmentField
                  data-test-id="mobileId"
                  variant="outlined"
                  fullWidth
                  name="mobile"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    )
                  }}
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.mobile}
                />
                <span style={webStyles.errorText}>
                  {this.state.mobileError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Email</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="emailId"
                  variant="outlined"
                  fullWidth
                  name="email"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.email}
                />
                <span style={webStyles.errorText}>{this.state.emailError}</span>
              </FormControl>
            </Box>
          </TwoColBox>
          <ButtonContainer>
            <ResetButton
              data-test-id="cancelFootButtonId"
              onClick={() => this.handleResetFooter()}
            >
              Reset
            </ResetButton>
            <CreateButton
              data-test-id="submitButtonId"
              onClick={() => this.handleSubmitFooter()}
            >
              Update
            </CreateButton>
          </ButtonContainer>
        </Box>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.isLoading}
        data-test-id="mainLayoutEvent"
        title={"Footer"}
        isEdit
        isAdd
      >
        <NotificationAlertMessage
          dataTestId="alertTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <main
          className="table_content"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderInputView()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MultiLineTextField = styled(TextField)({
  "& .MuiInputBase-input": { height: "86px" },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": { borderColor: "#3F6474" }
  }
});
const InputAdornmentField = styled(InputBox)({
  "& .MuiOutlinedInput-input": {
    paddingLeft: 0
  }
});
// Customizable Area End
