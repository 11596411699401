import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

const bundleNameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/,
  numberRegex = /^(?=.{0,10}$)[0-9]*\.?[0-9]*$/;

interface ResponseErrors {
  errors: {
    token: string;
    preview_video_url:string
  }[];
}

const initialErrorValues = {
  categoryError: "",
  bundleNameError: "",
  descriptionError: "",
  overviewError: "",
  whatWillLearnError: "",
  tagError: "",
  ratingError: "",
  addCourseDataError: "",
  thumbnailImgError: "",
  firstYrPrizeError: "",
  secondYrPrizeError: "",
  discountPrizeError: "",
  additionalFieldsError: "",
  additionalCoursesError: "",
  courseError: "",
  selectedCourseIdError: "",
  fileErr: ""
};

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface Response {
  meta: { message: string };
  error: string;
}

export interface ListResponse extends Response {
  data: { id: string; type: string; attributes: Attributes };
}

export interface CoursesResponse extends Response {
  data: CoursesResponseData[];
}

export interface CoursesResponseData {
  id: string;
  type: string;
  attributes: CourseAttributes;
}

export interface CourseAttributes {
  course_name: string;
  short_description: string;
  full_description: string;
  account_id: number;
  first_year_price: number;
  second_year_price: number | null;
  discounted_price: number | null;
  preview_video_url: string;
  status: boolean;
  trending: boolean;
  what_learn: string[];
  course_sub_category: CourseSubCategory;
  course_child_category: CourseChildCategory;
  student_count: number;
  course_category: CourseCategory;
  instructor_name: string;
  language_name: string;
  total_content: number;
  average_rating: any;
  specfifc_content_duration: null;
  course_thumbnail: string;
  all_course_class_duration: number;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
}

export interface CourseChildCategory {
  id: number;
  course_category_id: number;
  course_sub_category_id: number;
  name: string;
  total_courses: null;
  total_students: null;
  status: boolean;
  color: string;
  created_at: string;
  updated_at: string;
}

export interface CourseSubCategory {
  id: number;
  name: string;
  course_category_id: number;
  created_at: string;
  updated_at: string;
  status: boolean;
  total_courses: null;
  total_subjects: null;
}

export interface Attributes {
  bundle_name: string;
  description: string;
  overview: string;
  tag: string;
  first_year_price: string;
  second_year_price: string;
  status: boolean;
  discounted_price: string;
  rating: string;
  what_will_learn: any[];
  course_category: CourseCategory;
  total_student: number;
  rating_data: any;
  course_count: number;
  created_on: string;
  add_course: AddCourse[];
  bundle_course_thumbnail: string;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  preview_video_url:string;
}

export interface CourseCategory {
  id: string;
  name: string;
}

interface FormDataValues {
  category: string;
  bundleName: string;
  description: string;
  overview: string;
  whatWillLearn: any;
  tag: string;
  rating: string;
  addCourseData: any[];
  thumbnailImg: string;
  firstYrPrize: string;
  secondYrPrize: string;
  discountPrize: string;
  additionalFields: any;
  additionalFieldErrors:any;
  selectedCourseId: number[];
  selectedFile: any;
  selectedFileName: string;
  previewThumbnail: string;
  videoPreview:string;
}

type OmitFormDataValues = Omit<
  FormDataValues,
  | "addCourseData"
  | "thumbnailImg"
  | "previewThumbnail"
  | "previewVideoURL"
>;

interface FormDataValuesWithErrors extends FormDataValues {
  categoryError: string;
  bundleNameError: string;
  descriptionError: string;
  overviewError: string;
  whatWillLearnError: string;
  tagError: string;
  ratingError: string;
  addCourseDataError: string;
  thumbnailImgError: string;
  firstYrPrizeError: string;
  secondYrPrizeError: string;
  discountPrizeError: string;
  additionalFieldsError: string;
  additionalCoursesError: string;
  courseError: string;
  fileErr: string;
  selectedCourseIdError: string;
  videoPreviewErr:string;
}

export interface AddCourse {
  id: number;
  course_name: string;
  duration: null;
  year: null;
  created_at: Date;
  updated_at: Date;
  short_description: string;
  current_price: null;
  price: null;
  video_url: null;
  full_description: string;
  multiple_videos: null;
  course_category_id: number;
  valid_for_discount: null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: number;
  discounted_price: number;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number | null;
  language_options_language_id: number | null;
  course_thumbnail: string;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  rating: number;
  student_count: number | null;
}

interface S {
  // Customizable Area Start
  isChecked: boolean[];
  isHeaderChecked: boolean;
  bundleCourseRow: string[];
  bundleCourseData: any;
  userToken: any;
  pageNo: number;
  perPage: number;
  totalPages: number;
  openDeleteModal: boolean;
  deleteId: string | number;
  searchText: string;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  bundleCourseFormData: FormDataValuesWithErrors;
  bundleId: string;
  isUpdate: boolean;
  isAddCourseModalOpen: boolean;
  categoryList: any;
  courseList: CoursesResponseData[];
  courseData: any;
  courseDataId: any;
  categoryDataDone: boolean;
  currentSortState: { order: string; field: string };
  columnSort: string;
  categoryChange: boolean;
  choosTagList: string[];
  videoPreview:string;
  videoPreviewErr:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBundleCourseApiCallId: any;
  deleteBundleCourseAPICallId: any;
  getAllCategoryApiCallId: any;
  getCourseByCategoryApiCallId: any;
  addBundleCourseApiCallId: any;
  editBUndleCourseApiCallId: string = "";
  showBundleCourseApiCallId: string = "";
  toggleBundleStatusApiCallId: string = "";
  getTagListApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: true,
      bundleCourseRow: [
        "S.No",
        "Categories",
        "Sub Categories",
        "Bundle Course Name",
        "Price",
        "Created On",
        "Total Students",
        "Status",
        "Action"
      ],
      categoryChange: false,
      isChecked: [],
      isHeaderChecked: false,
      bundleCourseData: [],
      userToken: "",
      pageNo: 1,
      perPage: elementsPerPage,
      totalPages: 1,
      openDeleteModal: false,
      deleteId: "",
      searchText: "",
      isUpdate: false,
      bundleId: "",
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      bundleCourseFormData: {
        category: "",
        bundleName: "",
        description: "",
        overview: "",
        whatWillLearn: "",
        tag: "Best Seller",
        rating: "",
        addCourseData: [],
        thumbnailImg: "",
        firstYrPrize: "",
        secondYrPrize: "",
        discountPrize: "",
        additionalFields: [],
        additionalFieldErrors:[],
        previewThumbnail: "",
        selectedCourseId: [],
        selectedFile: "",
        selectedFileName: "",
        videoPreview: '',
        ...initialErrorValues,
        videoPreviewErr: ""
      },
      isAddCourseModalOpen: false,
      categoryList: [],
      categoryDataDone: false,
      courseList: [],
      courseData: [],
      courseDataId: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: "",
      choosTagList: [],
      videoPreview:'',
      videoPreviewErr:''
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    getStorageData("loginToken").then(value => {
      this.setState({ userToken: value });
      this.getCoursesDataList(this.state.columnSort);
      this.getTagList()
    });
    const editId = await getStorageData("bundleEditId");
    if(editId) {
      this.handleShowBundleCourse(editId);
      this.getCategoryList();
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getBundleCourseApiCallId:
            this.handleGetBundleCourseResponse(responseJson);
            break;
          case this.deleteBundleCourseAPICallId:
            this.deleteBundleCourseApiResponse(responseJson);
            break;
          case this.getAllCategoryApiCallId:
            this.setState({
              categoryDataDone: true,
              categoryList: responseJson.data ? responseJson.data : [],
              isLoading: false
            });
            break;
          case this.getCourseByCategoryApiCallId:
            this.handleGetCourseByCategoryApiResponse(responseJson);
            break;
          case this.editBUndleCourseApiCallId:
            this.handleAddCourseApiResponse(responseJson);
            break;
          case this.addBundleCourseApiCallId:
            this.handleAddCourseApiResponse(responseJson);
            break;
          case this.showBundleCourseApiCallId:
            this.handleGetBundleCourseListResponse(responseJson);
            break;
          case this.toggleBundleStatusApiCallId:
            this.handleToggleStatusResponse(responseJson);
            break;
          case this.getTagListApiCallId:this.handleTagListResponse(responseJson)
        }
      } else {
        this.parseErrors(responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let bundleCourseData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (bundleCourseData) {
        this.handleShowBundleCourse(bundleCourseData.ScreenTest);
        this.getCategoryList();
        this.getTagList()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleShowBundleCourse = async(id: string | number) => {
    await setStorageData("bundleEditId", id)
    this.setState({ isLoading: true });
    this.showBundleCourseApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.addBundleCourseApiEndPoint + id
    });
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
 
  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      if(responseJson.errors[0].preview_video_url){
        this.setState({isLoading:false,videoPreviewErr:responseJson.errors[0].preview_video_url})
      }
      this.parseApiErrorResponse(responseJson);
    }
  };

  getCategoryList() {
    this.setState({ isLoading: true });
    this.getAllCategoryApiCallId = this.apiCall({
      method: configJSON.httpGetType,
      contentType: configJSON.productApiContentType,
      endPoint: configJSON.bundleCategoryAPIEndPoint
    });
  }
  getTagList() {
    this.setState({ isLoading: true });
    this.getTagListApiCallId = this.apiCall({
      method: configJSON.httpGetType,
      contentType: configJSON.categoryApiContentType,
      endPoint: `bx_block_profile/bundle_courses/tag_types`
    });
  }

  handleTagListResponse = (resJson: any) => {
    const tagValues = Object.values(resJson.tag_types) as any;
    this.setState({
      isLoading: false,
      choosTagList: tagValues
    });
  };
  

  handleGetBundleCourseListResponse(resJson: ListResponse) {
    const { data } = resJson;
    const { attributes } = data;
    let learn = "",
      additionalFields: any[] = []
    if (attributes.what_will_learn.length > 0) {
      const [learnResponse, ...additionalResponse] = attributes.what_will_learn;
      learn = learnResponse;
      if (additionalResponse.length > 0) {
        additionalFields = additionalResponse;
      }
    }

    this.setState(
      {
        bundleId: data.id,
        isUpdate: true,
        courseData: attributes.add_course,
        categoryList: [
          {
            ...attributes.course_category,
            attributes: { name: attributes.course_category.name }
          }
        ],
        bundleCourseFormData: {
          ...initialErrorValues,
          selectedFile: "",
          selectedCourseId: attributes.add_course.map(val => val.id),
          selectedFileName: attributes.bundle_course_thumbnail,
          previewThumbnail: attributes.bundle_course_thumbnail,
          tag: attributes.tag,
          category: attributes.course_category.id,
          bundleName: attributes.bundle_name,
          thumbnailImg: attributes.bundle_course_thumbnail,
          description: attributes.description,
          discountPrize: attributes.discounted_price,
          firstYrPrize: attributes.first_year_price,
          secondYrPrize: attributes.second_year_price,
          rating: attributes.rating,
          addCourseData: attributes.add_course,
          whatWillLearn: learn,
          overview: attributes.overview,
          additionalFields,
          videoPreviewErr: "",
          videoPreview: "",
          additionalFieldErrors: []
        },
        videoPreview:attributes.preview_video_url
      },
      () => {
        this.setState({ isLoading: true });
        this.getCourseByCategoryApiCallId = this.apiCall({
          contentType: configJSON.productApiContentType,
          method: configJSON.httpGetType,
          endPoint:
            configJSON.courseBySubcategoryApiEndPoint +
            `${attributes.course_category.id}`
        });
      }
    );
  }

  handleResetData() {
    this.setState(
      {
        courseData: [],
        bundleCourseFormData: {
          tag: "Best Seller",
          category: "",
          bundleName: "",
          thumbnailImg: "",
          description: "",
          discountPrize: "",
          firstYrPrize: "",
          secondYrPrize: "",
          rating: "",
          addCourseData: [],
          whatWillLearn: "",
          overview: "",
          additionalFields: [],
          selectedCourseId: [],
          selectedFile: "",
          selectedFileName: "",
          previewThumbnail: "",
          ...initialErrorValues,
          videoPreviewErr: "",
          videoPreview: "",
          additionalFieldErrors:[]
        },
        videoPreviewErr: "",
        videoPreview: ""
      },
      () => {
        this.setState({ isLoading: true });
        this.getCourseByCategoryApiCallId = this.apiCall({
          contentType: configJSON.productApiContentType,
          method: configJSON.httpGetType,
          endPoint: configJSON.courseBySubcategoryApiEndPoint + `${0}`
        });
      }
    );
  }

  handleGetBundleCourseResponse(resJson: any) {
    if (
      resJson.meta &&
      resJson.meta.course === configJSON.bundleCourseRecordNotFoundMessage
    ) {
      this.setState({
        isAlert: true,
        alertMsg: resJson.meta.course,
        alertType: "info",
        totalPages: 0,
        bundleCourseData: [],
        isChecked: [],
        isLoading: false
      });
    } else if (
      resJson.errors &&
      Object.keys(resJson.errors[0])[0] === "token"
    ) {
      this.handleGoBack(true);
    } else {
      let count = resJson.meta.total_bundle_courses / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        bundleCourseData: resJson.data,
        isChecked: Array(
          this.state.bundleCourseData ? this.state.bundleCourseData.length : 0
        ).fill(false),
        totalPages: count,
        isLoading: false
      });
    }
  }

  handleAddCourseApiResponse(responseJson: any) {
    this.setState({ isLoading: false });
    if (responseJson.errors) {
      this.setState({
        isAlert: true,
        alertMsg: "Thumbnail should be less than 150kb",
        alertType: "error"
      });
    } else {
      this.handleGoBack(false);
    }
  }

  async handleGoBack(showAlert: boolean) {
    await removeStorageData("bundleEditId")
    showAlert && alert(configJSON.sessionExpireMsg);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationBundleCourseMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getCoursesDataList(this.state.columnSort);
    });
  };
  handleOpenModal = (id: number) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: ""
    });
  };
  handleEditPropsBundleCourse = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddBundleCourseMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };
  deleteBundleCourseApiResponse(response: Response) {
    if (response.error) {
      this.setState({
        openDeleteModal: false,
        isLoading: false,
        isAlert: true,
        alertMsg: response.error,
        alertType: "error"
      });
    } else {
      this.setState(
        {
          openDeleteModal: false,
          isLoading: false,
          isAlert: true,
          alertMsg: "Bundle Course Deleted Successfully",
          alertType: "success"
        },
        () => this.getCoursesDataList(this.state.columnSort)
      );
    }
  }
  handleHeaderCheckboxChange = () => {
    this.setState(prevState => ({
      isHeaderChecked: !prevState.isHeaderChecked,
      isChecked: Array(this.state.bundleCourseData.length).fill(
        !prevState.isHeaderChecked
      )
    }));
  };
  handleRowCheckboxChange = (index: number) => {
    this.setState(prevState => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      return {
        isChecked,
        isHeaderChecked: isChecked.every(value => value)
      };
    });
  };

  handleDeleteBundleCourse(id: any) {
    this.setState({ isLoading: true });
    this.deleteBundleCourseAPICallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpDeleteType,
      endPoint:
        configJSON.getAllBundleCourseApiEndPoint + `/${id}?role_name=Instructor`
    });
  }
  handleBundleCourseSearch(e: any) {
    let searchVal = e.target.value;
    this.setState({ searchText: searchVal, pageNo: 1 }, () => {
      this.getCoursesDataList(this.state.columnSort);
    });
  }
  getCoursesDataList(sorting: string) {
    this.setState({ isLoading: true });
    this.getBundleCourseApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint:
        configJSON.filterBundleCourseApiEndPoint +
        `?page=${this.state.pageNo}&per_page=${
          this.state.perPage
        }&search_params=${this.state.searchText}${sorting || ""}`
    });
  }

  handleSort(field: string) {
    const sortParams: Record<string, string> = {
      ["S.No"]: "id_sort",
      ["Bundle Course Name"]: "bundle_name_sort",
      Status: "status_sort"
    };
    let fieldSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      fieldSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, fieldSort);
    this.getCoursesDataList(fieldSort);
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
      }
    });
  };

  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token: this.state.userToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  deleteAddedCourse(id: any) {
    this.setState(prevState => ({
      courseData: prevState.courseData.filter((course: any) => course.id !== id),
      bundleCourseFormData: {
        ...prevState.bundleCourseFormData,
        selectedCourseId: prevState.bundleCourseFormData.selectedCourseId.filter(selected => selected.toString() !== id.toString()),
      }
    }));
  }

  handleAddCourse() {
    const { courseList, bundleCourseFormData } = this.state,
      { selectedCourseId } = bundleCourseFormData;
    const selectedCourses = courseList.filter(course =>
      selectedCourseId.includes(parseInt(course.id, 10))
    );
    const uniqueArray = selectedCourses.filter(
      (obj, index, self) => index === self.findIndex(t => t.id === obj.id)
    );
    const updatedCourseData = uniqueArray.map(val => ({
      ...val.attributes,
      id: val.id
    }));

    this.setState({
      courseData: updatedCourseData,
      isAddCourseModalOpen: false
    });
  }

  handleEditFileChange = (event: any) => {
    const files = event.target && event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState(prevState => ({
          ...prevState,
          bundleCourseFormData: {
            ...prevState.bundleCourseFormData,
            previewThumbnail: "",
            fileErr:
              "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
            selectedFile: null,
            selectedFileName: ""
          }
        }));
      } else if (fileSize > configJSON.maxFileSize) {
        this.setState(prevState => ({
          ...prevState,
          bundleCourseFormData: {
            ...prevState.bundleCourseFormData,
            fileErr: "File size exceeds 2MB. Please choose a smaller file.",
            selectedFile: null,
            selectedFileName: "",
            previewThumbnail: ""
          }
        }));
      } else {
        const imageUrl = global.URL.createObjectURL(file);
        this.setState(prevState => ({
          ...prevState,
          bundleCourseFormData: {
            ...prevState.bundleCourseFormData,
            selectedFile: file,
            selectedFileName: fileName,
            fileErr: "",
            previewThumbnail: imageUrl
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        bundleCourseFormData: {
          ...prevState.bundleCourseFormData,
          selectedFile: null,
          selectedFileName: "",
          fileErr: "No file is selected",
          previewThumbnail: ""
        }
      }));
    }
  };

  handleDeleteFile = () => {
    this.setState(prevState => ({
      ...prevState,
      bundleCourseFormData: {
        ...prevState.bundleCourseFormData,
        selectedFile: null,
        selectedFileName: "",
        fileErr: "",
        previewThumbnail: ""
      }
    }));
  };

  validateInputChange(
    name: string,
    value: any,
    isNumber = false,
    options?: {
      max?: number;
      min?: number;
    }
  ) {
    let errorMessage = "";
    if (name === "bundleName" && !bundleNameRegex.test(value.trim())) {
      if (value.length > 30) {
        errorMessage = "Bundle name cannot be more than 30 characters" 
      }else{
        errorMessage = "Please enter a valid Bundle name";
      }
    }
    if (isNumber && !numberRegex.test(value)) {
      errorMessage = "Please enter a valid " + name;
    }
    if (options) {
      if ((options.min || options.min === 0) && value < options.min) {
        value = options.min;
      }
      if (options.max && value > options.max) {
        value = options.max;
      }
    }

    return {
      errorMessage,
      value
    };
  }

  handleAddCoursePopup = () => {
      if(this.state.bundleCourseFormData.category === "" ) {
        this.setState({ bundleCourseFormData : {
          ...this.state.bundleCourseFormData ,
          selectedCourseIdError: "Please select main categories first"}})
     } else {
       this.setState({ isAddCourseModalOpen: true });
     }
  }

  handleBundleCoursePrice = (e: any) => {
    const { name, value } = e.target;
    let firstYrPrizeError = "";
  let secondYrPrizeError = "";
  let discountPrizeError = "";
 
    if (value.startsWith("0") && value.length > 0) {
      return;
    }
    if (value !== "" && !numberRegex.test(value)) {
      return;
    }
    this.setState(prevState => ({
      bundleCourseFormData: {
        ...prevState.bundleCourseFormData,
        [name]: value,
        firstYrPrizeError,
        secondYrPrizeError,
        discountPrizeError
      },
    }));
  };
  handlePreviewChange=(event:any)=> {
    const value=event.target.value
    this.setState({
      videoPreview: value,
      videoPreviewErr: ''
    });
  }
  
  
  handleCourseInputChange(
    e: any,
    isNumber = false,
    options?: {
      max?: number;
      min?: number;
    }
  ) {
    let { name, value } = e.target,
      { errorField } = e;

    const { errorMessage, value: finalValue } = this.validateInputChange(
      name,
      value,
      isNumber,
      options
    );
    if (name === "bundleName" && !bundleNameRegex.test(value.trim())) {
      if (value.length > 30) {
        this.setState({  bundleCourseFormData: {
            ...this.state.bundleCourseFormData,
            bundleNameError: "Bundle name cannot be more than 30 characters"
          }
        });
        return;
      }
    }
    if (name === "whatWillLearn") {
      if (value.length > 50) {
        this.setState({
          bundleCourseFormData: {
            ...this.state.bundleCourseFormData,
            whatWillLearnError: "What Will You Learn cannot be more than 50 characters"
          }
        });
        return;
      }
    }
    if ((name === "description" || "overview") && value.length > 500) {
      return;
    }
    this.setState(
      {
        bundleCourseFormData: {
          ...this.state.bundleCourseFormData,
          [name]: finalValue,
          [errorField]: errorMessage
        }
      },
      () => {
        if (name === "category") {
          this.setState({ isLoading: true, categoryChange: true, courseList: [], 
            bundleCourseFormData : {
              ...this.state.bundleCourseFormData ,
              selectedCourseIdError: "",
              selectedCourseId: []
            },
            courseData: []
           });
          this.getCourseByCategoryApiCallId = this.apiCall({
            contentType: configJSON.productApiContentType,
            method: configJSON.httpGetType,
            endPoint:
              configJSON.courseBySubcategoryApiEndPoint + `${finalValue}`
          });
        }
      }
    );
  }

  handleSelectClick() {
    if (!this.state.categoryDataDone) {
      this.getCategoryList();
    }
  }

  checkBundleCourseFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        required: true,
        field: "category",
        errorKey: "categoryError",
        errorMessage: "Main category is required"
      },
      {
        required: true,
        field: "bundleName",
        errorKey: "bundleNameError",
        errorMessage: "Bundle course Name is required",
        regex: bundleNameRegex
      },
      {
        required: true,
        field: "description",
        errorKey: "descriptionError",
        errorMessage: "Description is required"
      },
      {
        required: true,
        field: "overview",
        errorKey: "overviewError",
        errorMessage: "Overview is required"
      },
      {
        required: true,
        field: "tag",
        errorKey: "tagError",
        errorMessage: "Tag is required"
      },
      {
        required: true,
        field: "rating",
        errorKey: "ratingError",
        errorMessage: "Rating is required",
        regex: numberRegex
      },
      {
        required: true,
        field: "firstYrPrize",
        errorKey: "firstYrPrizeError",
        errorMessage: "First year price is required",
        regex: numberRegex
      },
      {
        field: "secondYrPrize",
        errorKey: "secondYrPrizeError",
        errorMessage: "Second year price is not correct format",
        regex: numberRegex
      },
      {
        required: true,
        field: "discountPrize",
        errorKey: "discountPrizeError",
        errorMessage: "Discount price is required",
        regex: numberRegex
      },
      {
        field: "whatWillLearn",
        errorKey: "whatWillLearnError",
        errorMessage: "What learn is required"
      },
      {
        required: true,
        field: "selectedCourseId",
        errorKey: "selectedCourseIdError",
        errorMessage: "Course is required"
      },
      {
        required: true,
        field: "selectedFileName",
        errorKey: "fileErr",
        errorMessage: "File is required"
      },
      {
        required: true,
        field: "videoPreview",
        errorKey: "videoPreviewErr",
        errorMessage: "Preview Video is required"
      }
    ];

    let hasError = false;

    validationFields.forEach(
      ({ field, errorKey, errorMessage, regex, required }) => {
        const isEmpty =
            !updatedValues[field] || updatedValues[field].length === 0,
          isError =
            (required && isEmpty) ||
            (regex && !regex.test(`${updatedValues[field]}`.trim()));
        if (!isError || (!required && regex && isEmpty)) {
          this.setState(
            prevState =>
              ({
                ...prevState,
                bundleCourseFormData: {
                  ...prevState.bundleCourseFormData,
                  [errorKey]: ""
                }
              } as any)
          );
        } else {
          this.setState(
            prevState =>
              ({
                ...prevState,
                bundleCourseFormData: {
                  ...prevState.bundleCourseFormData,
                  [errorKey]: errorMessage
                }
              } as any)
          );
          hasError = true;
        }
      }
    );
    if (!this.state.videoPreview) {
      this.setState({
        videoPreviewErr: "Preview Video is required"
      });
      hasError = true;
    } else {
      this.setState({
        videoPreviewErr: ""
      });
    }
    const firstYrPrize = Number(updatedValues.firstYrPrize);
    const secondYrPrize = Number(updatedValues.secondYrPrize);
  
    if (firstYrPrize && secondYrPrize && firstYrPrize >= secondYrPrize) {
      this.setState(prevState => ({
        bundleCourseFormData: {
          ...prevState.bundleCourseFormData,
          firstYrPrizeError: configJSON.firstYearPriceError,
          secondYrPrizeError: configJSON.secondYearPriceError
        }
      }));
      hasError = true;
    }
  
    const discountPrize = Number(updatedValues.discountPrize);
    if (discountPrize && firstYrPrize && discountPrize >= firstYrPrize) {
      this.setState(prevState => ({
        bundleCourseFormData: {
          ...prevState.bundleCourseFormData,
          discountPrizeError: configJSON.discountedPriceError
        }
      }));
      hasError = true;
    }
  
    return hasError;
  }

  handleAddBundleCourse(isEditMode: boolean) {
    this.setState({ isLoading: true });
    const {
      category,
      bundleName,
      description,
      overview,
      tag,
      rating,
      firstYrPrize,
      secondYrPrize,
      discountPrize,
      whatWillLearn,
      additionalFields,
      selectedCourseId,
      selectedFile,
      selectedFileName,
      additionalFieldErrors
    } = this.state.bundleCourseFormData;
    const {videoPreview}=this.state
    const formValues: OmitFormDataValues = {
      category,
      bundleName,
      description,
      overview,
      tag,
      rating,
      firstYrPrize,
      secondYrPrize,
      discountPrize,
      whatWillLearn,
      selectedCourseId: selectedCourseId.filter(selected => !!selected),
      selectedFileName,
      selectedFile,
      additionalFields,
      videoPreview,
      additionalFieldErrors
    };

    if (!this.checkBundleCourseFormValidations(formValues)) {
      const formData = this.handleFormData(formValues);

      if (isEditMode) {
        this.editBUndleCourseApiCallId = this.apiCall({
          type: "formData",
          body: formData,
          method: configJSON.httpPutType,
          endPoint: configJSON.addBundleCourseApiEndPoint + this.state.bundleId
        });
      } else {
        this.addBundleCourseApiCallId = this.apiCall({
          type: "formData",
          method: configJSON.httpPostType,
          endPoint: configJSON.addBundleCourseApiEndPoint,
          body: formData
        });
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleFormData({
    additionalFields,
    bundleName,
    category,
    description,
    discountPrize,
    firstYrPrize,
    overview,
    rating,
    secondYrPrize,
    selectedCourseId,
    selectedFile,
    selectedFileName,
    tag,
    whatWillLearn
  }: OmitFormDataValues) {
    const header = new global.Headers();
    header.append("token", this.state.userToken);

    const formData = new FormData();
    formData.append("bundle_courses[course_category_id]", category);
    formData.append("bundle_courses[bundle_name]", bundleName);
    formData.append("bundle_courses[description]", description);
    formData.append("bundle_courses[overview]", overview);
    formData.append("bundle_courses[tag]", tag);
    formData.append("bundle_courses[rating]", rating);
    formData.append("bundle_courses[first_year_price]", firstYrPrize);
    formData.append("bundle_courses[second_year_price]", secondYrPrize || "");
    formData.append("bundle_courses[discounted_price]", discountPrize || "");
    formData.append("bundle_courses[status]", "true");
    formData.append("bundle_courses[preview_video_url]", this.state.videoPreview);
    formData.append("bundle_courses[course_id]", [
      ...new Set(selectedCourseId.filter(selected => !!selected))
    ] as any);
    formData.append(
      "bundle_courses[what_will_learn]",
      `,${[
        `"${whatWillLearn}"`,
        ...additionalFields.map((field: any) => `"${field}"`)
      ].toString()},`
    );

    if (selectedFile) {
      formData.append(
        "bundle_courses[bundle_course_thumbnail]",
        selectedFile,
        selectedFileName
      );
    }
    return formData;
  }

  handleCloseAddCourseModal = () => {
    this.setState({
      isAddCourseModalOpen: false
    });
  };
  handleAddWhoYoullLearn = () => {
    if (this.state.bundleCourseFormData.additionalFields.length < 7) {
      this.setState({
        bundleCourseFormData: {
          ...this.state.bundleCourseFormData,
          additionalFields: [
            ...this.state.bundleCourseFormData.additionalFields,
            ""
          ]
        }
      });
    }
  };

  handleGetCourseByCategoryApiResponse(responseJson: CoursesResponse) {
    if (responseJson.data) {
      const dataToSet: unknown = {
        courseList: responseJson.data,
        ...(this.state.categoryChange
          ? {
              courseData: [],
              bundleCourseFormData: {
                ...this.state.bundleCourseFormData,
                selectedCourseId: []
              }
            }
          : {})
      };
      this.setState(dataToSet as Pick<S, keyof S>);
    }
    this.setState({ isLoading: false, categoryChange: false });
  }
  
  handleAdditionalFieldChange = (e: any, index: number) => {
    const { value } = e.target;
  
    this.setState(prevState => {
      const updatedFields = [...prevState.bundleCourseFormData.additionalFields];
      const updatedErrors = [...prevState.bundleCourseFormData.additionalFieldErrors || []];
  
      if (value.length > 50) {
        updatedErrors[index] = "What You'll Learn cannot be more than 50 characters";
        
      } else {
        updatedFields[index] = value;
        updatedErrors[index] = "";
      }
  
  
      return {
        bundleCourseFormData: {
          ...prevState.bundleCourseFormData,
          additionalFields: updatedFields,
          additionalFieldErrors: updatedErrors
        }
      };
    });
  };
  
  async addBundleCourseNavigate() {
    await removeStorageData("bundleEditId")
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddBundleCourseMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleDeleteAdditionalField = (index: number) => {
    const updatedFields = [...this.state.bundleCourseFormData.additionalFields];
    updatedFields.splice(index, 1);
    this.setState({
      bundleCourseFormData: {
        ...this.state.bundleCourseFormData,
        additionalFields: updatedFields
      }
    });
  };
  handleAddMoreCourse = () => {
    this.setState({
      bundleCourseFormData: {
        ...this.state.bundleCourseFormData,
        selectedCourseId: [
          ...this.state.bundleCourseFormData.selectedCourseId,
          0
        ]
      }
    });
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  handleToggleStatusResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.getCoursesDataList(this.state.columnSort);
      this.setState({
        isAlert: true,
        isLoading: false,
        alertMsg:
          (responseJson && responseJson.meta && responseJson.meta.message) ||
          "Course status/trending change successfully",
        alertType: "success"
      });
    } else {
      this.parseErrors(responseJson);
    }
  };

  handleToggleStatus = (id: string, status: boolean, index: number) => {
    if (!this.state.bundleCourseData || !this.state.bundleCourseData.length) {
      return;
    }
    let clone = [...this.state.bundleCourseData];
    clone[index].attributes.status = !status;

    this.setState({
      bundleCourseData: clone,
      isLoading: true
    });
    this.toggleBundleStatusApiCallId = this.apiCall({
      method: configJSON.httpPutType,
      body: { change_status: !status },
      contentType: configJSON.categoryApiContentType,
      endPoint: `bx_block_profile/bundle_courses/update_status/${id}`
    });
  };

  handleAdditionalCourseChange = (e: any, index: number) => {
    const { value } = e.target;
    const updatedSelectedCourseId = [
      ...this.state.bundleCourseFormData.selectedCourseId
    ];
    updatedSelectedCourseId[index] = parseInt(value, 10);
    this.setState({
      ...this.state,
      bundleCourseFormData: {
        ...this.state.bundleCourseFormData,
        selectedCourseId: updatedSelectedCourseId
      }
    });
  };

  handleResetAdditionalCourses = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        courseData: [],
        bundleCourseFormData: {
          ...prevState.bundleCourseFormData,
          selectedCourseId: [],
        }
      };
    });
  };
  
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
