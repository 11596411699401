import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { Parser } from "json2csv";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface BaseResponseType {
  meta: Meta;
  errors: ErrorType[];
  error: string;
}

export interface ErrorType {
  token: string;
}

export interface ReportsListResponseType extends BaseResponseType {
  data: ReportsListData[];
}

export interface ReportsResponseType extends BaseResponseType {
  message: string[][];
}

export interface ReportsListData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  banner_title: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  banner_image: string;
}

export interface Meta {
  message: string | string[][];
  total_report: number;
  page: string;
}

export interface InvalidResponseType {
  errors: Array<string>;
}
export const configJSON = require("./config");

export interface IReportsTypeList {
  label: ReportTypeEnum;
  value: string;
}

enum ReportTypeEnum {
  USER_REPORTS = "User Reports",
  COURSE_REPORTS = "Course Reports",
  PAYMENT_REPORTS = "Payment Reports",
  ORDER_REPORTS = "Order Reports",
  TOP_COURSE_USERS_REPORTS = "Top Course Users Reports",
  TRAFFIC_SOURCES_REPORTS = "Traffic Sources Reports"
}

const reportsTypeList: IReportsTypeList[] = [
  { label: ReportTypeEnum.USER_REPORTS, value: configJSON.getUserReports },
  { label: ReportTypeEnum.COURSE_REPORTS, value: configJSON.getCourseReports },
  {
    label: ReportTypeEnum.PAYMENT_REPORTS,
    value: configJSON.getPaymentReports
  },
  { label: ReportTypeEnum.ORDER_REPORTS, value: configJSON.getOrderReports },
  {
    label: ReportTypeEnum.TOP_COURSE_USERS_REPORTS,
    value: configJSON.getTopCourseUserReports
  },
  {
    label: ReportTypeEnum.TRAFFIC_SOURCES_REPORTS,
    value: configJSON.getTrafficSourcesReports
  }
];

// Customizable Area End

export interface Props {
  navigation: unknown;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportsList: ReportsListData[];
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  perPage: number;
  reportTypeList: IReportsTypeList[];
  reportType: string;
  reportTypeError: string;
  startDate: string;
  startDateError: string;
  endDate: string;
  endDateError: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDailyReportListApiCallId: string = "";
  submitReportApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reportsList: [],
      currentPageCount: 1,
      pageCount: 0,
      reportType: "",
      reportTypeError: "",
      reportTypeList: reportsTypeList,
      startDate: "",
      startDateError: "",
      endDate: "",
      endDateError: "",
      userToken: "",
      isLoading: false,
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      perPage: elementsPerPage
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  reportsApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: ReportsListResponseType | ReportsResponseType
  ) => {
    switch (apiRequestCallId) {
      case this.submitReportApiCallId:
        this.handleSubmitReportResponse(responseJson as ReportsResponseType);
        break;
    }
  };

  checkFormValidations(updatedValues: Record<string, unknown>): boolean {
    const validationFields = [
      {
        field: "reportType",
        errorKey: "reportTypeError",
        errorMessage: "Report type is Required"
      },
      {
        field: "startDate",
        errorKey: "startDateError",
        errorMessage: "Start date is Required"
      },
      {
        field: "endDate",
        errorKey: "endDateError",
        errorMessage: "End date is Required"
      }
    ];

    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage }) => {
      const currentField = updatedValues[field] as string;
      if (!currentField || (currentField && currentField.length === 0)) {
        this.setState(prevState => ({
          ...prevState,
          [errorKey]: errorMessage
        }));
        hasError = true;
      } else {
        this.setState(prevState => ({
          ...prevState,
          [errorKey]: ""
        }));
      }
    });

    return hasError;
  }

  handleSubmitReport = async () => {
    const { reportType, startDate, endDate } = this.state;
    const formValues = { reportType, startDate, endDate };
    if (!this.checkFormValidations(formValues)) {
      const endPoint = `${reportType}?start_date=${startDate}&end_date=${endDate}`;
      this.submitReportApiCallId = this.reportsApiCall({
        method: configJSON.validationApiMethodType,
        endPoint: endPoint,
        type: configJSON.exampleApiContentType
      });
    }
  };

  getCSVName = () => {
    switch (this.state.reportType) {
      case configJSON.getCourseReports:
        return "course_report.csv";
      case configJSON.getOrderReports:
        return "order_report.csv";
      case configJSON.getPaymentReports:
        return "payment_report.csv";
      case configJSON.getTopCourseUserReports:
        return "top_course_user_metric_report.csv";
      case configJSON.getTrafficSourcesReports:
        return "traffic_source_metric_referral_report.csv";
      case configJSON.getUserReports:
        return "user_report.csv";
      default:
        return "data_report.csv";
    }
  };

  downloadReportCSV = (data: string[][]) => {
    const headers = data[0];
    const rows = data.slice(1);

    const combinedData = rows.map(row => {
      return headers.reduce((acc: Record<string, string>, header, index) => {
        acc[header] = row[index];
        return acc;
      }, {});
    });

    const json2csvParser = new Parser({ fields: headers });
    const csvData = json2csvParser.parse(combinedData);
    const blob = new global.Blob([csvData], { type: "text/csv" });
    const url = global.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = this.getCSVName();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    global.URL.revokeObjectURL(url);
  };

  handleSubmitReportResponse = (response: ReportsResponseType) => {
    if (response.errors && Object.keys(response.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else if (response.message && typeof response.message === "object") {
      this.downloadReportCSV(response.message);
    } else if (response.error) {
      this.setState({
        alertMsg: response.error,
        alertType: "error",
        isAlert: true
      });
    } else if (response.message != "") {
      this.setState({
        alertMsg: response.message,
        alertType: "error",
        isAlert: true
      });
    }
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  handleInputChange = (event: { target: { value: string; name: string } }) => {
    const { value, name } = event.target;
    const errorName = name + "Error";
    const values: unknown = {
      [name]: value,
      [errorName]: ""
    };
    this.setState(values as Pick<S, keyof S>);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}
